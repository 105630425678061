@import "../../styles/helpers";

.header {
    z-index: 20;
    padding: 13px 16px;
    background-color: #101010;
    height: auto;
    position: fixed;
    width: 100%;
    @include m {
        border: none;
        // background-color: transparent
        // background-image: linear-gradient(to right ,#0d2236e0 89%,#28485a 11%)
        height: 64px; }
    @include a {
        // background-image: linear-gradient(to right ,#0d2236e0 85%,#28485a 15%)
 } }        // background-color: transparent

.bankmodal {
    width: 50% !important;
    @include t {
        width: 80% !important; } }
.sticky {
    z-index: 20;
    padding: 10px 60px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to right, #0d2236e0 , #28485a);
    height: 68px; }
.logo {
    width: 150px;
    @include m {
        width: 100px; } }
.sideNav {
    display: none;
    margin-bottom: 20px;
    @include m {
        display: block; }
    img {
        width: 100%; } }

.sideNavHead {
    display: flex;
    img {
        width: 50%;
        height: fit-content; } }
.container {
    display: flex;
    width: 100%;
    max-width: 1550px;
    margin: 0 auto;
    align-items: center; }
.container1 {
    display: flex;
    align-items: center;
    margin-left: auto !important;
    @include d {
        margin-left: auto !important; }
    & > .button {
        margin-right: 12px;
        // +m
 } }        //     display: none

.wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    @include d {
        flex-grow: 0; }
    @include m {
        position: fixed;
        top: 48px;
        right: 0;
        flex-direction: column;
        align-items: stretch;
        // box-shadow: 0px 64px 64px rgb(31 47 70 , 0.2)
        visibility: hidden;
        opacity: 0;
        z-index: 5;
        transition: all .2s;
        width: 200px;
        &.active {
            visibility: visible;
            opacity: 1; } }
    .button {
        display: none;
        @include m {
            display: inline-flex;
            margin-top: 16px; } } }

.nav {
    display: flex;
    margin-left: auto;
    @include d {
        display: flex; }
    @include m {
        display: flex;
        flex-direction: column;
        margin: 0 0 40px;
        border: none;
        background-color: #0d2236e0;
        border: 1px solid #095575; } }
.body {
    position: absolute;
    z-index: 1000;
    // top: calc(100% + -10px)
    left: -28%;
    // margin-left: -101px
    background-color: #101010;
    opacity: 0;
    transform: scale(.9);
    transform-origin: 50% 50px;
    will-change: transform;
    color: $white;
    animation: showUser .4s forwards;
    // background-color: #11242e
    // +w
    //     left: 66%
    // +x
    //     left: 61%
    // +d
    //     left: 54%
    // +t
    //     left: 67%
    //     width: 186px;
    @include m {
        // top: 517%;
        // left: 0
        border: 1px solid #095575; }

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 32px;
        height: 13px;
        transform: translateX(-50%);
        @include m {
            display: none; } } }

@keyframes showUser {
    0% {
        opacity: 0;
        transform: scale(.9); }
    100% {
        opacity: 1;
        transform: scale(1); } }
.link {
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    font-family: AnitaSemiSquare;
    letter-spacing: 2px;
    padding: 0 16px;
    color: #9698A1;
    transition: color .5s;
    border-bottom: 3.5px solid transparent;
    display: flex;
    align-items: center;
    // background-color: #04e0d13f
    white-space: nowrap;
    // border: 1px solid $neutrals4
    @include x {
        font-size: 9px;
        padding: 0 14px; }
    @include t {
        letter-spacing: 1px;
        font-size: 12px;
 }        // background-color: #04dbe04f;
    @include m {
        margin-left: 0;
        font-size: 14px;
        line-height: 64px; }

    &:hover,
    &.active {
        color: $white;
        // text-shadow: 0 0 1em #00d8ff, 0 0 2em #00d8ff, 0 0 2em #00d8ff
        // background-image: url('../../images/Active.png')
        // background-repeat: no-repeat
        // background-size: 101% 101%
        background-color: transparent;
        // border: 1px solid#00d8ff
        // box-shadow: #00d8ff -1px 1px 8px;
        // &::after
        //     content: " "
        //     display: flex
        //     width: 100%
        //     height: 21px
        //     margin: 0 auto
        //     justify-content: center
        //     background-image: url('../../images/border.png')
        //     position: absolute
        //     bottom: 1px
 }        //     background-repeat: no-repeat
    img {
        height: 20px;
        margin: 7px 5px;
        @include m {
            height: 23px;
            margin-top: 18px; } } }

.Selectedlink {
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    font-family: AnitaSemiSquare;
    letter-spacing: 2px;
    padding: 0 16px;
    color: $white;
    transition: color .2s;
    display: flex;
    align-items: center;
    position: relative;
    // border-bottom: 5px solid rgb(254, 194, 46)
    // border-bottom-right-radius: 50px 10px
    // text-shadow: 0 0 1em #00d8ff, 0 0 2em #00d8ff, 0 0 2em #00d8ff
    color: white;
    // background-image: url('../../images/Active.png')
    // background-repeat: no-repeat
    // background-size: 101% 100%
    // box-shadow: #00d8ff -1px 1px 8px;
    white-space: nowrap;
    // background-color: transparent
    @include x {
        font-size: 9px;
        padding: 0 14px; }
    @include t {
        margin-left: 0;
        letter-spacing: 1px;
        font-size: 12px; }
    @include m {
        margin-left: 0;
        font-size: 17px;
        line-height: 64px;
        // background-color: #04dbe04f;
        font-size: 14px; }
    &:hover,
    &.active {
        color: white;
        // background-image: url('../../images/Active.png')
        // background-repeat: no-repeat
 }        // background-size: 101% 100%
    img {
        height: 20px;
        margin-top: 5px;
        @include m {
            height: 23px;
            margin-top: 18px; } } }
.Selectedlink {
    &::after {
        content: " ";
        display: flex;
        width: 100%;
        height: 21px;
        margin: 0 auto;
        justify-content: center;
        background-image: url('../../images/border.png');
        position: absolute;
        bottom: 1px;
        bottom: -16px;
        background-repeat: no-repeat; } }
.subhead_body {
    position: relative;
    display: inline-block; }
.search {
    position: relative;
    flex-shrink: 0;
    width: 256px;
    margin-right: 24px;
    @include t {
        display: none; }
    @include m {
        display: block;
        width: 100%;
        margin: auto 0 0; } }

.input {
    width: 100%;
    height: 40px;
    padding: 0 42px 0 16px;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 8px;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
        color: $neutrals4; }
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 42px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $blue; } } }

.notification {
    margin-right: 24px;
    @include m {
        margin-right: 20px; } }

.burger {
    display: none;
    @include m {
        display: block;
        position: relative;
        margin-left: auto;
        width: 32px;
        background: none; } }

.login {
    background-image: url('../../images/login.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 10px 20px;
    text-align: center;
    margin-left: 13px;
    margin-right: 13px;
    width: max-content;
    font-family: Heebo;
    color: $white;
    font-size: 16px;
    transition: color .2s;
    white-space: nowrap;
    @include m {
        line-height: normal;
        font-size: 9px;
        margin-left: 0; } }

.login a {
    font-size: 16px;
    // line-height: 40px
    font-family: Heebo;
    color: $white;
    transition: color .2s;
    @include m {
        margin-left: 0;
        font-size: 9px;
        line-height: 0; }
    &:hover,
    &.active {
        color: white; } }
.heading {
    cursor: pointer;
    h3 {
        color: $white;
        font-family: RocketRinder;
        letter-spacing: 2mm;
        margin-bottom: 0; } }
.icons {
    display: flex;
    margin: 0 auto;
    a img {
        height: 30px;
        margin: 0 16px; }
    @include d {
        position: absolute;
        left: 0;
        top: 250px;
        display: inline;
        background-color: #0d22367a;
        border-left: none;
        border-radius: 15px;
        padding: 5px;
        a img {
            display: flex;
            height: 30px;
            margin: 20px 5px; } } }
.close {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 0;
    img {
        height: 37px;
        width: 37px;
        transition: transform .2s;
        img {
            fill: $neutrals8; } }
    &:hover {
        img {
            transform: rotate(90deg); } } }
.modalContainer {
    margin-top: 0 !important; }
.icon {
    color: $neutrals4;
    font-size: 24px; }
.menu {
    background-color: $neutrals3;
    padding: 7px 10px;
    border-radius: 7px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    height: fit-content;
    cursor: pointer; }
.drawerwrapper {
    background-color: $neutrals3; }
.closeIcon {
    color: $white;
    font-size: 24px;
    cursor: pointer; }
.profileFlex {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
        height: 32px; }
    svg {
        color: #9698A1; } }
.drawerHeader {
    color: $white;
    font-family: 'Poppins'; }
.dropIcon {
    font-size: 18px;
    color: inherit; }

@import "../../../styles/helpers";

.LotteryContainer {
    position: relative;
    color: $white;
    font-family: Poppins;
    min-height: 750px;
    @include d {
        min-height: 350px; }
    @include t {
        min-height: 400px; }
    @include m {
        min-height: 326px; } }

.bg {
    width: 100%;
    margin-top: -90px;
    @include m {
        display: none; } }
.mobBg {
    display: none;
    @include m {
        margin-top: 0;
        display: block;
        width: 100%; } }
.fire {
    width: 100%;
    margin-top: -90px;
    @include m {
        margin-top: 0; } }
.content {
    position: absolute;
    top: 28%;
    width: 100%;
    text-align: center;
    @include m {
        top: 30%; } }
.welcome {
    font-size: 20px;
    @include m {
        font-size: 18px;
        margin-bottom: 10px; } }
.message {
    font-size: 32px;
    // font-weight: 600
    font-family: ChakraSemiBold;
    letter-spacing: 1px;
    @include m {
        font-size: 18px;
        margin-bottom: 5px; } }
.label {
    font-size: 16px;
    font-weight: 400;
    @include m {
        font-size: 14px; } }

.address {
    height: 40px;
    width: 449px;
    border-radius: 56px;
    padding: 12px 24px 14px 24px;
    background: #FFFFFF;
    border-radius: 1px 1px 1px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    color: black;
    @include m {
        width: 240px;
        height: 30px;
        padding: 7px 15px 8px 15px;
        font-size: 12px; } }

.lottery {
    margin: 15px auto 0 auto;
    width: fit-content;
    @include m {
        margin: 20px auto 0 auto; } }

.access1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px 8px 16px;
    width: 240px;
    height: 30px;
    background: #00A2FD;
    border-radius: 53px;
    margin: 0 auto;
    margin-top: 13px; }
.access2 {
    width: fit-content;
    font-size: 16px;
    font-weight: 400;
    font-family: AnitaSemiSquare;
    letter-spacing: 2px;
    padding: 10px 18px;
    color: $white;
    transition: color .2s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 17px;
    color: white;
    background-image: url('../../../images/Active.png');
    background-repeat: no-repeat;
    background-size: 101% 100%;
    box-shadow: #00d8ff -1px 1px 8px;
    white-space: nowrap;
    background-color: transparent;
    @include m {
        background-color: #04dbe04f;
        font-size: 12px;
        padding: 5px 14px; } }
.button {
    margin-top: 17px;
    display: flex;
    justify-content: center; }
.access {
    width: 100%; }



.smokeWrap {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 120px;
    width: 100%;
    @include m {
        bottom: 1px; } }
.smoke,
.smoke2,
.smoke3 {
    filter: blur(5px);
    transform-origin: 50% 50%;
    width: 100%;
    height: 50%; }
.smoke {
    animation: smoke1 4s linear infinite;
    animation-delay: 1.5s;
    &2 {
        animation: smoke2 5s linear infinite;
        animation-delay: 2.5s; }
    &3 {
        width: 500px;
        animation: smoke3 6s linear infinite;
        animation-delay: 3.5s; } }

@keyframes smoke1 {
    0% {
        filter: blur(0px);
        transform: translateY(0px) scale(-1, 1);
        opacity: 0; }

    25% {
        filter: blur(3px);
        transform: translateY(-10px) scale(-1, 1.05);
        opacity: 0.3; }

    50% {
        filter: blur(5px);
        transform: translateY(-20px) scale(-1, 1.1);
        opacity: 0.5; }

    75% {
        filter: blur(5px);
        transform: translateY(-30px) scale(-1, 1.15);
        opacity: 0.3; }

    100% {
        filter: blur(7px);
        transform: translateY(-40px) scale(-1, 1.2);
        opacity: 0; } }

@keyframes smoke2 {
    0% {
        filter: blur(0px);
        transform: translateY(0px) scale(1);
        opacity: 0; }

    25% {
        filter: blur(3px);
        transform: translateY(-10px) scale(1.05);
        opacity: 0.3; }

    50% {
        filter: blur(5px);
        transform: translateY(-20px) scale(1.1);
        opacity: 0.5; }

    75% {
        filter: blur(5px);
        transform: translateY(-30px) scale(1.15);
        opacity: 0.3; }

    100% {
        filter: blur(7px);
        transform: translateY(-40px) scale(1.2);
        opacity: 0; } }

@keyframes smoke3 {
    0% {
        filter: blur(0px);
        transform: translateY(0px) scale(1);
        opacity: 0; }

    25% {
        filter: blur(3px);
        transform: translateY(-20px) scale(1.05);
        opacity: 0.3; }

    50% {
        filter: blur(5px);
        transform: translateY(-40px) scale(1.1);
        opacity: 0.5; }

    75% {
        filter: blur(5px);
        transform: translateY(-60px) scale(1.15);
        opacity: 0.3; }

    100% {
        filter: blur(7px);
        transform: translateY(-80px) scale(1.2);
        opacity: 0; } }

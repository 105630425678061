.flagsSelect button {
  background: url("../../images/inputBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 40px;
  padding: 0 48px 0 16px;
  border-radius: 1px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
  cursor: pointer;
  color: #9698a1;
  border: none;
}
.flagsSelect ul {
  background-color: #101010;
}
.flagsSelect ul li {
  color: #9698a1;
}
.flagsSelect ul li:hover {
  background: none;
  color: white;
}
.flagsSelect ul::-webkit-scrollbar {
  display: none;
}
.flagsSelect button[aria-expanded="true"]:after {
  border-bottom: 5px solid #9698a1;
}

.flagsSelect button:after {
  border-top: 5px solid #9698a1;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 0;
}

.sample,
.sample:before,
.sample:after,
.sample-text,
.sample-text:before,
.sample-text:after {
  transition: border-color 0.3s ease, color 0.1s ease;
}

.sample {
  color: #9698A1;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-family: 'Poppins';
  /* text-transform: uppercase; */
  position: relative;
  overflow: hidden;
  padding: 0 14px;
  width: 100%;
  cursor: pointer;
}

.sample:before,
.sample:after {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  /* z-inxex: 3; */
}

.sample:before {
  border-top: solid 1px #9698A1;
  top: 3px;
  right: 7px;
}

.sample:after {
  border-bottom: solid 1px #9698A1;
  bottom: 3px;
  left: 7px;
}

.sample .sample-bg {
  background: white;
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: -1;
  clip-path: polygon(0% 0%,
      /* top left */
      0% 0%,
      /* top left */
      calc(100% - 14px) 0%,
      /* top right */
      100% 14px,
      /* top right */
      100% 100%,
      /* bottom right */
      100% 100%,
      /* bottom right */
      14px 100%,
      /* bottom left */
      0 40px
      /* bottom left */
    );
}

.sample .sample-text {
  padding: 9px 0px;
  display: block;
  position: relative;
  border-top: solid 1px #9698A1;
  border-bottom: solid 1px #9698A1;
  z-index: 3;
}

.sample:before,
.sample:after,
.sample-text:before,
.sample-text:after {
  content: "";
  width: 20px;
  height: calc(100% - 13px);
  display: block;
  position: absolute;
  z-index: 3;
}

.sample-text:before {
  top: -1px;
  left: -14px;
  border-top: solid 1px #9698A1;
  border-left: solid 1px #9698A1;
}

.sample-text:after {
  bottom: -1px;
  right: -14px;
  border-bottom: solid 1px #9698A1;
  border-right: solid 1px #9698A1;
}
@media only screen and (max-width: 767px) {
  .sample-text:before {
    width: 14px;
  }
  .sample-text:after {
    width: 14px;
  }
}
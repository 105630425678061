@import "../../../styles/helpers";

.menu {
    color: $neutrals5;
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
    &:hover {
        color: $neutrals8;
        font-weight: 500;
        .icon {
            color: $neutrals8; } } }
.icon {
    font-size: 18px;
    color: inherit; }
.divider {
    color: $neutrals5 !important;
    width: 80% !important;
    margin: 30px auto !important;
    border-top: 1px solid $neutrals5 !important; }
.activeMenu {
    color: $white;
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
    &:hover {
        color: $neutrals8;
        font-weight: 500;
        .icon {
            color: $neutrals8; } } }
.body {
    margin-left: 28px;
    margin-bottom: 10px; }
.subMenu {
    color: $neutrals5;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 15px;
    &:hover {
        color: $neutrals8;
        font-weight: 500;
        .icon {
            color: $neutrals8; } } }
.activeSubMenu {
    color: $neutrals5;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 15px;
    &:hover {
        color: $neutrals8;
        font-weight: 500;
        .icon {
            color: $neutrals8; } } }
.menu1 {
    color: $neutrals5;
    display: flex;
    align-items: baseline;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 90%;
    justify-content: space-between;
    &:hover {
        color: $neutrals8;
        font-weight: 500;
        .icon {
            color: $neutrals8; } } }

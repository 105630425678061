@import "../../styles/helpers";

.whiteText {
    font-family: 'Poppins';
    font-size: 18px;
    color: $white;
    font-weight: 500;
    margin-right: 10px;

    // text-shadow: 2px 2px 25px $white
    @include m {
        // margin-bottom: 10px
        font-size: 16px; } }

.whiteHead {
    font-family: AnitaSemiSquare;
    font-size: 24px;
    color: $white;
    text-shadow: 2px 2px 25px $white;
    @include m {
        font-size: 20px; } }
.orangeText {
    font-family: AnitaSemiSquare;
    font-size: 24px;
    color: $primary-orange;
    text-shadow: 2px 2px 25px $primary-orange;
    margin-bottom: 10px;
    @include m {
        font-size: 20px; } }
.blueText {
    font-family: AnitaSemiSquare;
    font-size: 14px;
    color: $secondary;
    text-shadow: 2px 2px 25px $secondary;
    text-align: center; }
.body {
    width: 80%;
    margin-left: auto;
    padding: 20px;
    @include m {
        width: auto;
        margin-left: 0;
        text-align: center;
        padding: 10px; } }
.timer {
    display: flex;
    justify-content: center; }
.nextRace {
    // margin-left: auto
    // width: 50%
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    @include m {
        top: 70px; } }
.nextDatRace {
    width: fit-content;
    text-align: center;
    margin-left: 40px;
    @include m {
        margin-left: 10px; } }
.minsTimer {
    border: 2px solid $dark-yellow;
    color: $white;
    // text-shadow: 2px 2px 25px $secondary
    width: fit-content;
    padding: 5px 20px;
    // margin: 20px auto
    // box-shadow: #58d0d4bf 0px 0px 11px 0px
    border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1;
    width: 85px;
    text-align: center;
    div {
        color: $white;
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 500;
        @include m {
            font-size: 12px; } }
    @include m {
        padding: 5px;
        width: 100px; } }

.dayTime {
    border: 2px solid $primary-orange;
    font-family: AnitaSemiSquare;
    font-size: 14px;
    color: $white;
    text-shadow: 2px 2px 25px $primary-orange;
    width: fit-content;
    padding: 5px 20px;
    margin: 20px auto;
    box-shadow: #f79953d4 0px 0px 11px 0px;
    @include m {
        font-size: 12px;
        padding: 5px; } }
.scroll {
    text-align: center;
    margin: 20px; }
.live {
    width: 420px;
    margin: 20px auto;
    @include m {
        width: 100%; } }
.liveBody {
    display: flex; }
.liveRace {
    font-size: 12px;
    color: $white; }

.liveText {
    color: #32DA01;
    margin-left: auto;
    font-size: 18px; }
.text {
    color: $white;
    font-family: Heebo;
    font-size: 14px;
    margin-top: 10px;
    @include m {
        font-size: 12px; } }
.viewButtonContainer {
    display: flex;
    height: fit-content;
    align-items: center;
    margin-left: auto; }
.field {
    font-family: AnitaSemiSquare;
    font-size: 15px;
    color: $white;
    text-shadow: 2px 2px 25px $white; }
.viewButton {
    flex-shrink: 0;
    margin: 0 6px;
    padding: 7px 15px;
    border-radius: 1px;
    font-size: 14px;
    font-family: AnitaSemiSquare;
    background-color: transparent;
    white-space: nowrap;
    box-shadow: #00d8ff -1px 1px 8px;
    // +button-2
    color: $neutrals8;
    transition: all .2s;
    background-image: url('../../images/Active.png');
    background-repeat: no-repeat;
    background-size: 101% 101%;
    a {
        color: $white; }
    &:hover {
        color: $white; } }
.border {
    // border-right: 1px solid $white
    text-align: center; }
.liveContainer {
    // box-shadow: -10px 0px 3px 0px #32DA00
    // border-left: 10px solid #32DA00
    background-image: url('../../images/modalscreenbg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 12px 17px;
    font-family: AnitaSemiSquare;
    width: -webkit-fill-available; }

.liveBorder {
    height: auto;
    background-color: #32DA00;
    box-shadow: 0px 0px 10px 2px #32DA00;
    width: 7px;
    z-index: 1; }

.orangeShade {
    color: $white;
    font-family: AnitaSemiSquare;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
    font-size: 18px; }
.head {
    text-align: center;
    font-family: AnitaSemiSquare;
    font-size: 32px;
    color: $white;
    margin-bottom: 15px;
    @include m {
        font-size: 24px; } }
.liveHead {
    text-align: center;
    font-family: AnitaSemiSquare;
    font-size: 22px;
    color: $white;
    margin-bottom: 15px;
    @include m {
        font-size: 18px; } }
.subhead {
    font-family: AnitaSemiSquare;
    font-size: 24px;
    color: $white;
    width: fit-content;
    margin: 5px 0;
    @include t {
        font-size: 16px; } }
.balance {
    text-align: center;
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 16px;
    @include m {
        font-size: 10px; } }
.shade {
    width: 60%;
    display: flex;
    margin: 10px auto; }
.previewImg {
    width: 100%;
    height: 235px;
    float: right;
    border: 2px solid #20668b;
    border-radius: 3px;
    padding: 5px;
    border-image: linear-gradient(to right, $primary-blue, $secondary) 1;
    &:hover {
        // box-shadow: $secondary 0px 0px 0.25em, $secondary 0px 0.25em 1em;
        box-shadow: #0cc4c9b3 0px 0px 16px;
        border-image: linear-gradient(to right, $secondary, $primary-blue) 1;
        transition-duration: 1s; } }

.border {
    float: left;
    height: 100%;
    width: 16px; }
.title {
    background-image: url('../../images/base.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: $white;
    font-family: AnitaSemiSquare;
    padding: 10px 4px;
    margin-left: 6px;
    font-size: 10px;
    position: absolute;
    bottom: 12%; }
.yield {
    color: $white;
    font-family: AnitaSemiSquare;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange; }

.button {
    margin-top: 32px;
    @include t {
        margin: 24px auto 0 auto;
        width: fit-content; } }

.container {
    width: 85%;
    margin: 0 auto;
    // background-image: url('../../images/modalscreenbg.png')
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: fit-content;
    padding: 32px;
    @include t {
        width: 100%;
        padding: 0 16px; } }
.raceImg {
    width: 100%; }
.card {
    width: 100%;
    max-height: 250px;
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    @include m {
        height: auto; } }

.modal {
    width: 50%;
    @include t {
        width: 80%; }
    @include m {
        width: 90%; } }
.empty {
    margin: 70px auto;
    color: $white;
    font-family: AnitaSemiSquare;
    text-align: center;
    font-size: 18px; }
.loader {
    margin: 25px auto; }
.gamewindow {
    width: 100%;
    height: 650px;
    @include t {
        width: 100%;
        height: 500px; } }
.icon {
    height: 20px;
    margin-right: 5px; }
.liveHeader {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @include t {
        width: 90%; }
    @include m {
        width: 100%; } }
.raceBg {
    position: relative; }
.raceBg1 {
    width: 100%;
    height: 450px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 30px;
    @include d {
        height: 300px; }
    @include m {
        height: 150px;
        margin-bottom: 0; } }
.raceMask {
    position: absolute;
    top: 38%;
    left: 7%;
    @include m {
        width: 90%;
        margin: 0 20px;
        top: 32%;
        left: 0; }
    h2 {
        font-family: 'Chakra';
        color: $white;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 42px;
        @include d {
            font-size: 24px; }
        @include m {
            font-size: 14px; } }
    p {
        font-size: 16px;
        font-family: 'Poppins';
        color: $white;
        @include d {
            font-size: 14px; }
        @include m {
            font-size: 12px; } } }
.raceKibbles {
    // display: flex
    // justify-content: center
    // align-items: center
    position: relative;
    height: 150px;
    font-family: 'Poppins';
    color: #9698A1;
    text-align: center;
    margin: 80px 0 40px;
    @include m {
        margin: 40px 0; }
    h3 {
        color: $white;
        font-family: 'ChakraSemiBold';
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 32px;
        position: absolute;
        left: 0;
        right: 0;
        @include m {
            font-size: 18px; } }
    p {
        position: absolute;
        left: 0;
        right: 0;
        top: 42px;
        @include m {
            top: 36px; } } }
.dot {
    float: left;
    height: 100px;
    @include m {
        width: 40%; } }
.dot1 {
    float: right;
    height: 100px;
    @include m {
        width: 40%; } }
.background {
    background-image: url('../../images/SpaceshooterBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; }

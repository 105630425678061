@import "../../../styles/helpers";

.content {
    background: #2F2F2F;
    padding: 20px 30px;
    color: #9698A1;
    border-bottom: 1px solid #9698A1;
    text-align: center;
    font-family: 'Poppins';
    span {
        font-size: 22px;
        color: $white;
        font-family: 'Chakra' !important;
        font-weight: 600;
        margin-bottom: 15px; }
    p {
        margin-bottom: 15px; } }
.address {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        margin-left: 10px;
        font-size: 18px;
        cursor: pointer; } }
.body {
    padding: 20px 30px;
    font-family: 'Poppins'; }
.menuItem {
    color: #9698A1;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
        color: $white; }
    svg {
        font-size: 20px;
        margin-right: 10px; } }
.menuItem1 {
    color: #9698A1;
    font-size: 16px;
    margin-left: 34px;
    &:hover {
        color: $white;
        cursor: pointer; } }
.item {
    margin-bottom: 15px; }
.modal {
    width: 80% !important;
    max-width: 80%; }

@import "../../../styles/helpers";

.land {
    padding: 20px 30px; }
.container {
    width: 70%;
    height: 100%;
    margin: 0 auto;
    @include m {
       width: 100%;
       margin-bottom: 30px; } }
.heading {
    color: $white;
    font-family: ChakraSemiBold;
    font-size: 32px;
    margin-bottom: 10px;
    @include m {
        font-size: 18px; } }
.text {
    font-weight: 500;
    font-family: 'Poppins';
    font-style: normal;
    color: #9698A1;
    margin-bottom: 30px;
    @include m {
        font-size: 12px; } }
.btnflex {
    display: flex; }
.landImg {
    width: 100%; }
.landBtn,.mapBtn {
    width: 100%;
    margin-right: 40px;
    @include m {
        margin-right: 20px; } }
.landBtn {
    padding-right: 30px; }
.landFlex {
    display: flex;
    align-items: center; }

@import "../../../styles/helpers";

.stock {
    padding: 20px 30px;
    color: $white;
    font-family: ChakraSemiBold; }
.card {
    background-image: url('../../../images/stockborder.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 80%;
    margin: 10px auto;
    position: relative; }
.stockbg {
    position: absolute;
    z-index: 1;
    bottom: 0;
    height: 34%; }

.previewImage {
    // width: 100%
    height: 75px;
    padding: 2px;
    border-radius: 2px;
    clip-path: polygon(-90% 0%, 0% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 100%, 0% 100%, 58% 101%);
    @include m {
        width: 16%;
        height: auto; } }
.heading {
    color: $white;
    font-family: ChakraSemiBold;
    font-size: 32px;
    margin-bottom: 10px;
    @include m {
        font-size: 18px; } }
.text {
    color: inherit;
    font-weight: 500;
    font-family: 'Poppins';
    font-style: normal;
    color: #9698A1;
    display: flex !important;
    align-items: center;
    margin-left: 10px;
    width: 30%;
    @include t {
        font-size: 12px; }
    @include m {
        width: 100%;
        font-size: 12px; }
    @include a {
        font-size: 10px; } }

.stakeImg {
    width: 100%; }
.container {
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    text-align: end; }
.view {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    @include m {
        margin-left: 0; } }
.price {
    color: $white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    margin-left: 10px;
    display: flex !important;
    align-items: center;
    width: 30%;
    @include t {
        font-size: 12px; }
    @include m {
        width: 100%;
        font-size: 12px; }
    @include a {
        font-size: 10px;
        width: 80%;
        white-space: break-spaces;
        line-height: 1.3; } }
.stockFlex,.nameFlex {
    display: flex; }
.nameFlex {
    width: 60%;
    justify-content: space-around;
    @include t {
        width: 54%; }
    @include m {
        flex-direction: column;
        width: 50%;
        padding: 5px 0; }
    @include s {
 } }        // width: 35%
.viewMarketPlace {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto 20px;
    @include m {
        width: 100%; } }

.loader {
    margin: 0 auto;
    display: block; }

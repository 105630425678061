@import "../../../styles/helpers";

.gamewindow {
    width: 98%;
    margin-right: 20px;
    display: flex;
    height: 640px;
    position: absolute;
    z-index: 4;
    @include m {
        display: none; } }
.raceImg {
    display: none;
    @include m {
        display: flex;
        position: absolue;
        width: 113%;
        margin-left: -25px; } }
.game {
    position: relative;
    height: auto;
    margin-bottom: 20px; }
.liveRaceDetails {
    position: absolute;
    width: 50%;
    top: 40px;
    left: 60px;
    z-index: 6;
    @include m {
        display: none; } }
// .shadow
//     height: 640px
//     width: 98%
//     z-index: 2
//     position: absolute
//     right: 20px
//     background-color: #00000063
.eventDetails {}
.raceContainer {
    position: relative;
    margin: 0 30px;
    padding-top: 30px;
    @include t {
        margin: 0 24px;
        margin-bottom: 20px;
        padding-top: 0; } }

.live {
    margin: 10px 13px 10px 0;
    @include m {
        margin: 10px 0; } }
.liveBody {
    display: flex; }
.liveRace {
    font-size: 12px;
    color: $white; }

.liveText {
    color: #32DA01;
    margin-left: auto;
    font-size: 18px; }
.text {
    color: $white;
    font-family: Heebo;
    font-size: 14px;
    margin-top: 10px;
    @include m {
        font-size: 12px; } }
.viewButtonContainer {
    display: flex;
    height: fit-content;
    align-items: center;
    margin-left: auto; }
.field {
    font-family: AnitaSemiSquare;
    font-size: 15px;
    color: $white;
    text-shadow: 2px 2px 25px $white; }
.viewButton {
    flex-shrink: 0;
    margin: 0 6px;
    padding: 7px 15px;
    border-radius: 1px;
    font-size: 14px;
    font-family: AnitaSemiSquare;
    background-color: transparent;
    white-space: nowrap;
    box-shadow: #00d8ff -1px 1px 8px;
    // +button-2
    color: $neutrals8;
    transition: all .2s;
    background-image: url('../../../images/Active.png');
    background-repeat: no-repeat;
    background-size: 101% 101%;
    a {
        color: $white; }
    &:hover {
        color: $white; } }
.border {
    // border-right: 1px solid $white
    text-align: center; }
.liveContainer {
    // box-shadow: -10px 0px 3px 0px #32DA00
    // border-left: 10px solid #32DA00
    background-image: url('../../../images/modalscreenbg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 12px 17px;
    font-family: AnitaSemiSquare;
    width: -webkit-fill-available; }

.liveBorder {
    height: auto;
    background-color: #32DA00;
    box-shadow: 0px 0px 10px 2px #32DA00;
    width: 7px;
    z-index: 1; }
.blueText {
    font-family: AnitaSemiSquare;
    font-size: 14px;
    color: #04DFE5;
    text-shadow: 2px 2px 25px #04dfe5; }
.orangeShade {
    color: $white;
    font-family: AnitaSemiSquare;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
    font-size: 18px; }
.head {
    text-align: center;
    font-family: AnitaSemiSquare;
    font-size: 32px;
    color: $white;
    margin-bottom: 15px;
    @include m {
        font-size: 24px; } }
.liveHead {
    font-family: AnitaSemiSquare;
    font-size: 24px;
    color: $white;
    margin-bottom: 3px;
    @include m {
        font-size: 18px;
        margin-bottom: 5px; } }
.subhead {
    font-family: AnitaSemiSquare;
    font-size: 24px;
    color: $white;
    width: fit-content;
    margin: 5px 0;
    @include t {
        font-size: 16px; } }
.balance {
    text-align: center;
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 16px;
    @include m {
        font-size: 10px; } }
.shade {
    width: 80%;
    display: flex;
    margin: 20px auto; }
.title {
    background-image: url('../../../images/base.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: $white;
    font-family: AnitaSemiSquare;
    padding: 10px 4px;
    margin-left: 6px;
    font-size: 10px;
    position: absolute;
    bottom: 12%; }
.yield {
    color: $white;
    font-family: AnitaSemiSquare;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange; }
.link {
    flex-shrink: 0;
    padding: 6px 20px;
    border-radius: 1px;
    font-family: AnitaSemiSquare;
    // background-color: #04e0d13f
    white-space: nowrap;
    border: 1px solid $neutrals4;
    // text-shadow: 0 0 1em #00d8ff, 0 0 2em #00d8ff, 0 0 2em #00d8ff
    // +button-2
    color: $neutrals8;
    transition: all .2s;
    width: fit-content !important;
    background-image: url('../../../images/Active.png');
    background-repeat: no-repeat;
    background-size: 101% 101%;
    font-size: 13px;
    box-shadow: #00d8ff -1px 1px 8px;
    display: flex;
    margin: 0 auto;
    @include m {
        margin: 0; }
    &:hover, &.active {
        color: white;
        background-image: url('../../../images/Active.png');
        background-repeat: no-repeat;
        background-size: 101% 101%;
        text-shadow: none;
        background-color: transparent;
        // border: 1px solid#00d8ff
        box-shadow: #00d8ff -1px 1px 8px; }
    &:not(:last-child) {
        @include m {
            margin-right: 12px; } } }
.nav {
    display: flex;
    margin: 0 -32px 0px;
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
        &:before,
        &:after {
            content: "";
            flex-shrink: 0;
            width: 32px;
            height: 1px; } } }
.arrow {
    color: $secondary;
    font-size: 28px;
    fill: $white !important;
    &:hover {
        fill: $secondary !important; } }
.loader {
    margin: 60px auto; }
.whiteText {
    font-family: AnitaSemiSquare;
    font-size: 12px;
    color: $white;
    // text-shadow: 2px 2px 25px $white
    margin-bottom: 10px;
    @include x {
        font-size: 10px; }
    @include m {
        margin-bottom: 10px;
        font-size: 12px; } }
.viewAll {
    display: flex;
    justify-content: center;
    a {
        font-size: 20px !important; } }
.Spaceship {
    position: relative;
    height: 290px;
    margin-bottom: 10px;
    @include d {
        height: 180px; }
    @include t {
        height: 290px; }
    @include m {
        height: 290px; } }
.spaceshipBg {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    opacity: 0.5; }
.winners {
    position: relative; }
.blueShade {
    color: $white;
    font-family: AnitaSemiSquare;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary;
    font-size: 18px;
    @include m {
        font-size: 16px; } }
.spaceshipContent {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%; }
.volume {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 100; }
.mute {
    cursor: pointer;
    font-size: 24px;
    color: $white; }
.bigad {
    width: 100%;
    height: 250px;
    border-radius: 20px; }

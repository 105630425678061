@import "../../styles/helpers";

.item {
    // background: $neutrals2
    display: flex;
    @include m {
        display: block; } }
.head {
    color: $white;
    font-size: 24px;
    font-weight: 400;
    font-family: AnitaSemiSquare;
    text-align: center;
    margin-right: 20px; }
.register {
    margin-top : 32px;
    margin-bottom : 16px; }
.heading {
    color: #FFCD58;
    font-family: HeeboBold;
    text-shadow: 2px 2px 25px #FF0000; }
.title {
    color: #EE9200;
    font-size: 16px;
    font-weight: 400;
    font-family: AnitaSemiSquare;
    margin-right: 50px; }

.image {
    width: 35%;
    border-radius: 16px;
    margin-right: 20px;
    @include m {
        width: 100%;
        margin: 10px auto; }
    svg {
        fill: $neutrals8; } }

.info {
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
    @include body-bold-2; }
.row {
    margin: 10px 0; }
.button {
    background-image: url('../../images/login.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border:none {}
    font-family: AnitaSemiSquare;
    &:hover {
        border: none;
        background-image: url('../../images/cancel.png');
        background-repeat: no-repeat;
        background-size: 100% 100%; }
    &:not(:last-child) {
        background-image: url('../../images/cancel.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 8px; } }

.field {
    margin: 25px 0;
    border-radius: 8px; }

.field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include m {
                margin-bottom: 20px; } } }

.note {
    margin-top: 4px;
    font-size: 14px;
    color: $neutrals4;
    margin-left: 25px; }

.alert {
  text-align: center;
  color: #f44336;
  margin-bottom: 16px;
  font-size: 14; }


.success {
  text-align: center;
  color: white;
  margin-bottom: 16px;
  font-size: 16px; }


.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s; }


.closebtn:hover {
  color: black; }

.avatar,
.icon {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    margin-right: 8px; }

.avatar {
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }
.text {
    font-weight: 500; }

.wallet {
    width: 280px;
    cursor: pointer;
    border: 2px solid $neutrals6;
    border-radius: 12px;
    height: 55px;
    @include dark {
        border-color: $neutrals3; }
    @include t {
        width: 260px; }
    @include m {
        width: 100%;
        margin-bottom: 12px; } }

.modal {
    padding: 0 42px; }

.metamask {
    height: 40px;
    width: 40px;
    margin: 5px 25px; }

.iconimg {
    float: right;
    margin-top: 14px; }

.delevery {
    display: flex;
    margin-right: 38px; }

.buttons {
    // display: flex
    background-image: url('../../images/button_2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 40%;
    hight: 100%;
    margin-left: auto;
    &:hover {
        border: none;
        background-image: url('../../images/login.png');
        background-repeat: no-repeat;
        background-size: 100% 100%; } }

.leftbtn {
    width: auto !important;
    margin-right: auto; }

.rightbtn {
    width: auto !important;
    margin-left: auto; }
.deleveryModal {
    max-width: 1000px !important;
 }    // background-color: blue


.creator {
    display: flex;
    padding: 16px; }

.category {
    font-size: 16px; }

.description {
    margin-left: 10%; }

.btns {
    text-align: center;
    width: 100%;
    margin: 25px 0; }
.price {
    margin-left: 25px; }
.stock {
    margin-left: 100px; }

@import "../../../styles/helpers";

.head {
    text-align: center;
    font-family: 'ChakraSemiBold';
    font-size: 32px;
    color: $white;
    margin-bottom: 24px;
    font-weight: 600;
    @include t {
        font-size: 18px; } }

.wager {
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 20px;
    text-align: center;
    img {
        height: 32px;
        width: 32px;
        margin: 0 3px;
        @include t {
            height: 25px;
            width: 25px; }
        @include m {
            height: 15px;
            width: 15px; } }
    @include t {
        font-size: 16px; }
    @include m {
        font-size: 12px; } }
.value {
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 16px; }
.shade {
    width: 80%;
    display: flex;
    margin: 24px auto; }

.yield {
    color: $white;
    font-family: 'ChakraSemiBold';
    // font-weight: lighter
 }    // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange

.loader {
    margin: 25px auto; }

.button {
    margin: 10px auto;
    width: fit-content; }
.challengeText {
    color: $white;
    font-family: AnitaSemiSquare;
    font-weight: lighter;
    font-size: 24px;
    text-align: center;
    text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary;
    @include t {
        font-size: 20px; } }
.input {
    width: 200px;
    @include m {
        width: 80%;
        margin: 0 auto; } }
.card {
    width: 100%;
    // display: inline-block
    cursor: pointer;
    // padding: 10px
    border: 1px solid #2F2F2F;
    margin-bottom: 3px;
    @include m {
        height: auto; } }
.previewImg {
    width: 100%;
    display: flex;
    // border: 2px solid #20668b
    // border-radius: 3px
    padding: 5px;
 }    // border-image: linear-gradient(to right, $primary-blue, $secondary) 1
.title {
    text-align: center;
    color: #ffffff;
    font-family: 'Poppins';
    padding: 6px 4px;
    margin-left: 6px;
    font-size: 12px;
    font-weight: 500; }
.imageDiv {
    width: 100%;
    align-content: center; }
.error {
    color: red;
    font-size: 14px;
    text-align: center;
    font-family: Heebo; }
.coundown {
    font-size: 12px;
    font-family: AnitaSemiSquare;
    text-align: center; }
.empty {
    margin: 70px auto;
    color: $white;
    font-family: 'Poppins';
    text-align: center;
    font-size: 18px; }
.loader {
    margin: 25px auto; }
.modal {
    width: 30% !important;
    @include m {
        width: 90% !important; } }
.skeleton {
    margin: 10px;
    border-radius: 2px;
    border-image: linear-gradient(to right, $primary-blue, $secondary) 1;
 }    // border: 2px solid #20668b
.Card {
    display: inline-block;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 10px;
    &:hover {
        box-shadow: 0px 29px 36px -34px rgba(250, 198, 55, 0.6);
        .cardsTxt {
            border-top: solid 1px $dark-yellow;
            border-bottom: solid 1px $dark-yellow;
            border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1;
            &::before {
                border-top: solid 1px $dark-yellow;
                border-left: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; }
            &::after {
                border-bottom: solid 1px $dark-yellow;
                border-right: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; } }
        .card {
            border: 1px solid $dark-yellow;
            border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; }
        .cards {
            background: transparent;
            &::before {
                border-top: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; }
            &::after {
                border-bottom: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; } } } }
.cards, .cards-border {
    color: #9698a1;
    text-decoration: non;
    display: inline-block;
    font-size: 14px;
    font-family: "Poppins";
    position: relative;
    overflow: hidden;
    padding: 0 14px;
    width: 100%;
    cursor: pointer;
    margin-top: 3px; }
.cards {
    &::before {
        border-top: solid 1px #2F2F2F;
        top: 3px;
        right: 7px; }
    &::after {
        border-bottom: solid 1px #2F2F2F;
        bottom: 3px;
        left: 7px; } }

.cards {
    &::before,
    &::after, {
        content: "";
        width: 20px;
        height: calc(90% - 13px);
        display: block;
        position: absolute;
        z-index: 3;
        transform: rotate(45deg); } }
.cardsTxt {
    &::before,
    &::after {
        content: "";
        width: 20px;
        height: calc(103% - 13px);
        display: block;
        position: absolute;
        z-index: 3; } }
.cardsTxt {
    &::before {
        top: -1px;
        left: -14px;
        border-top: solid 1px #2F2F2F;
        border-left: solid 1px #2F2F2F; } }
.cardsTxt {
    &::after {
        bottom: -1px;
        right: -14px;
        border-bottom: solid 1px #2F2F2F;
        border-right: solid 1px #2F2F2F; } }
.cardsTxt {
    padding: 5px 10px;
    display: block;
    position: relative;
    border-top: solid 1px #2F2F2F;
    border-bottom: solid 1px #2F2F2F;
    z-index: 3; }
.lineBorder {
    margin: 0 1px;
    border-top: 1px solid #2F2F2F;
    width: 93%; }

@import "../../styles/helpers";

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 48px;
  background-image: url('../../images/modalbg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0;
  animation: showModal .4s forwards;
  overflow: auto;
  z-index: 999;
  @include m {
    padding: 30px 16px; } }

@keyframes showModal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.outer {
  position: relative;
  width: fit-content;
  max-width: 80%;
  margin: auto;
  padding: 32px;
  z-index: 2;
  background-image: url('../../images/modalscreenbg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: $white;
  width: 35%; }

.container {
  @include t {
    margin-top: 34px; } }

.close {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 0;
  img {
    height: 37px;
    width: 37px;
    transition: transform .2s;
    img {
      fill: $neutrals8; } }
  &:hover {
    img {
      transform: rotate(90deg); } } }
.gallery {
  width: 80%;
  height: 90%; }
.title {
  color: $white;
  text-align: center;
  font-family: AnitaSemiSquare;
  font-size: 32px;
  text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
  @include m {
    font-size: 24px; } }
.content {
  color: $white;
  font-family: AnitaSemiSquare;
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center; }
.cancel {
  background-color: #ffffff49;
  border: 2px solid $white;
  color: $white;
  font-family: AnitaSemiSquare;
  font-size: 16px;
  box-shadow: $white -1px 1px 8px;
  margin-right: 20px;
  padding: 5px 15px; }

.yes {
  background-color: #04dfe563;
  border: 2px solid $secondary;
  color: $white;
  font-family: AnitaSemiSquare;
  font-size: 16px;
  box-shadow: #00d8ff -1px 1px 8px;
  margin-left: 20px;
  padding: 5px 15px; }

.buttons {
  display: flex;
  justify-content: center;
  width: 100%; }
.note {
  color: $white;
  font-family: AnitaSemiSquare;
  text-align: center;
  margin-bottom: 15px; }

.noteHead {
  color: $primary-orange;
  font-family: AnitaSemiSquare; }

@import "../../styles/helpers";

.footer {
    background-color: $primary-blue;
    // letter-spacing: 2px
    margin: 0 auto;
    padding: 0 25px;
    width: 100%;
    max-width: 1550px;
    @include m {
        margin-top: 50px; } }

.row {
    display: flex;
    padding: 80px 0 48px;
    border-bottom: 1px solid $neutrals6;
    @include t {
        display: block; }
    @include m {
        padding: 64px 0 48px; } }

.col {
    &:first-child {
        flex-grow: 1;
        padding-right: 32px;
        @include t {
            position: relative;
            margin-bottom: 32px;
            padding: 0; } }
    &:nth-child(2) {
        display: flex;
        flex-shrink: 0;
        width: 352px;
        @include d {
            width: 276px; }
        @include t {
            width: auto;
            margin-bottom: 32px; }
        @include m {
            display: block;
            border-width: 1px 0;
            border-style: solid;
            border-color: $neutrals6;
            @include dark {
                border-color: $neutrals3; } } }
    &:nth-child(3) {
        flex-shrink: 0;
        width: 384px;
        padding-left: 32px;
        @include d {
            width: 304px; }
        @include t {
            width: 100%;
            padding: 0; } } }

.logo {
    display: inline-block;
    margin-bottom: 32px;
    img {
        width: 190px; } }

.info {
    max-width: 256px;
    @include body-1;
    @include d {
        font-size: 16px; }
    @include dark {
        color: $neutrals7; } }

.version {
    display: flex;
    align-items: center;
    margin-top: 24px;
    @include t {
        position: absolute;
        top: 5px;
        right: 0;
        margin: 0; } }

.details {
    margin-right: 16px;
    @include caption-2;
    @include m {
        margin-right: 10px; } }

.category {
    margin-bottom: 40px;
    @include body-bold-2;
    @include t {
        margin-block-end: 24px; } }

.text {
 }    // margin-bottom: 24px

.foot {
    display: flex;
    justify-content: space-between;
    padding: 15px 24px;
    @include caption-2;
    @include m {
        display: block;
        padding: 24px 0 32px; } }

.copyright {
    color: $white;
    font-family: HeeboThin;
    align-self: center;
    a {
        color: $white; }
    @include m {
        text-align: center; } }

.note {
    color: $white;
    font-family: RocketRinder;
    font-size: 20px;
    @include m {
        text-align: center; }
    a {
        margin-left: 16px;
        font-weight: 600;
        color: $white;
        transition: color .2s; } }

.buttons {
    margin: 0px auto;
    text-align: center; }

.twitter {
    height: 80px;
    margin-top: 24px; }
.discord {
    height: 100px;
    margin: 0 16px; }
.opensea {
    height: 80px;
    margin-top: 48px; }
.eighteenPlus {
    height: 40px;
    position: absolute;
    left: 50%;
    @include m {
        margin: 10px auto;
        display: flex;
        position: inherit; } }
.flexLink {
    display: flex;
    width: 70%;
    justify-content: space-evenly;
    margin: 0 auto 30px;
    @include m {
        flex-direction: column;
        width: 100%; } }
.menu {
    color: $white;
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
    @include m {
        font-size: 12px;
        margin-bottom: 20px; } }
.flexImg {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #373B3B;
    border-top: 1px solid #373B3B;
    padding: 50px 0;
    align-items: center;
    @include m {
        align-items: flex-start;
        flex-direction: column; }
    img {
        height: 16px;
        @include m {
            margin-bottom: 20px; } } }
.content {
    color: #9698A1;
    font-family: 'Poppins';
    font-weight: 500;
    width: 75%;
    font-size: 14px;
    @include m {
        width: 100%;
        font-size: 12px; } }
.flexCopyright,.community {
    display: flex;
    justify-content: space-between;
    color: #9698A1;
    font-family: 'Poppins';
    margin: 30px 0;
    align-items: center;
    @include m {
        flex-direction: column;
        align-items: flex-start;
        margin: 20px 0;
        font-size: 12px; }
    span {
        font-weight: 500; } }
.community {
    width: 50%;
    @include m {
        width: 100%; }
    img {
        height: 7px;
        @include m {
            display: none; } } }
.icons {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include m {
        width: 100%;
        margin: 20px 0; }
    svg {
        font-size: 23px; } }
.insta,.discords,.Twitter {
    color: #9698A1; }
.discords {
    font-size: 28px !important; }
.Twitter {
    font-size: 26px !important; }
.opensea1 {
    display: none;
    @include m {
        display: block !important;
        height: 29px !important; } }
.text {
    color: #404040;
    font-size: 24px;
    font-family: RocketRinder;
    letter-spacing: 2mm;
    margin-bottom: 0; }
.subhead {
    color: $white; }

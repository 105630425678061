@import "../../../styles/helpers";

.head {
    text-align: center;
    font-family: 'ChakraSemiBold';
    font-weight: 600;
    font-size: 32px;
    color: $white;
    margin-bottom: 15px;
    @include t {
        font-size: 18px; } }

.wager {
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 18px;
    text-align: center;
    img {
        height: 28px;
        width: 28px;
        margin: 0 3px;
        @include t {
            height: 22px;
            width: 22px; }
        @include m {
            height: 13px;
            width: 13px; } }
    @include t {
        font-size: 14px; }
    @include m {
        font-size: 12px; } }
.value {
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 16px; }
.shade {
    width: 80%;
    display: flex;
    margin: 20px auto; }

.yield {
    color: $white;
    font-family: 'ChakraSemiBold';
    // font-weight: lighter
 }    // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange

.loader {
    margin: 25px auto; }

.button {
    margin: 10px auto;
    width: fit-content; }
.challengeText {
    color: #9698A1;
    font-family: 'Poppins';
    font-weight: lighter;
    font-size: 14px;
    text-align: center;
    // text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary
    margin-bottom: 15px;
    font-weight: 500;
    @include t {
        font-size: 12px; } }
.input {
    width: 200px;
    margin: 0 auto;
    margin-top: 10px;
    @include m {
        width: 80%;
        margin: 0 auto;
        margin-top: 10px; } }

.previewImg {
    width: 100%;
    display: flex;
    margin: 0 auto;
    border: 2px solid $border-grey;
    border-radius: 3px;
    padding: 5px;
    // border-image: linear-gradient(to right, $primary-blue, $secondary) 1
    @include m {
        width: 100%; } }
.imageDiv {
    // width: 90%
    align-content: center;
    margin: 0 auto 3px; }
.error {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    color: #f13838;
    text-align: center;
    font-weight: 600;
    font-family: 'Poppins'; }
.coundown, .timestamp div {
    font-size: 12px;
    font-family: AnitaSemiSquare;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    margin-left: 10px; }
.note {
    text-align: center;
    font-family: 'Poppins';
    color: #9698A1;
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-grey;
    p {
        color: $white;
        // text-decoration: underline
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 10px; }
    div {
        margin-bottom: 10px; } }
.Card {
    display: block;
    cursor: pointer;
    width: 70%;
    margin: 0 auto 30px;
    &:hover {
        box-shadow: 0px 29px 36px -34px rgba(250, 198, 55, 0.6);
        .cardsTxt {
            border-top: solid 1px $dark-yellow;
            border-bottom: solid 1px $dark-yellow;
            border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1;
            &::before {
                border-top: solid 1px $dark-yellow;
                border-left: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; }
            &::after {
                border-bottom: solid 1px $dark-yellow;
                border-right: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; } }
        .previewImg {
            border: 1px solid $dark-yellow;
            border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; }
        .cards {
            background: transparent;
            &::before {
                border-top: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; }
            &::after {
                border-bottom: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; } } } }
.cards, .cards-border {
    color: #9698a1;
    text-decoration: non;
    display: inline-block;
    font-size: 14px;
    font-family: "Poppins";
    position: relative;
    overflow: hidden;
    padding: 0 14px;
    width: 100%;
    cursor: pointer;
    margin-top: 3px; }
.cards {
    &::before {
        border-top: solid 1px #2F2F2F;
        top: 3px;
        right: 7px; }
    &::after {
        border-bottom: solid 1px #2F2F2F;
        bottom: 3px;
        left: 7px; } }

.cards {
    &::before,
    &::after, {
        content: "";
        width: 20px;
        height: calc(90% - 13px);
        display: block;
        position: absolute;
        z-index: 3;
        transform: rotate(45deg); } }
.cardsTxt {
    &::before,
    &::after {
        content: "";
        width: 20px;
        height: calc(103% - 13px);
        display: block;
        position: absolute;
        z-index: 3; } }
.cardsTxt {
    &::before {
        top: -1px;
        left: -14px;
        border-top: solid 1px #2F2F2F;
        border-left: solid 1px #2F2F2F; } }
.cardsTxt {
    &::after {
        bottom: -1px;
        right: -14px;
        border-bottom: solid 1px #2F2F2F;
        border-right: solid 1px #2F2F2F; } }
.cardsTxt {
    padding: 5px 10px;
    display: block;
    position: relative;
    border-top: solid 1px #2F2F2F;
    border-bottom: solid 1px #2F2F2F;
    z-index: 3; }
.lineBorder {
    margin: 0 1px;
    border-top: 1px solid #2F2F2F;
    width: 93%; }
.title {
    text-align: center;
    color: #ffffff;
    font-family: 'Poppins';
    padding: 6px 4px;
    margin-left: 6px;
    font-size: 12px;
    font-weight: 500; }
.yellowTxt {
    color: $dark-yellow; }

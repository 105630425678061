@import "../../../styles/helpers";

.horizontalshade {
    height: 100%;
    width: 11px;
    display: flex;
    margin: 0 auto; }
.maleSection {
    margin: 10px 0; }

.container {
    background-image: url("../../../images/Gbh.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; }
.breeding_g1_container {
    font-family: AnitaSemiSquare;
    color: white; }
sup {
    font-size: 15px;
    @include m {
        font-size: 10px; } }
.Breeding_title {
    // background-color: #aaa
    width: 70%;
    position: relative;
    left: 20px;
    font-size: 30px;
    line-height: 2.5;
    text-shadow: 2px 2px 25px #ffffff;
    @include m {
        font-size: 15px;
        line-height: 1.5;
        width: 100%; } }
.Breeding_note {
    font-size: 24px;
    text-align: center;
    margin: 20px; }
.breeding {
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange; }
.priceValue {
    font-family: Poppins;
    font-weight: 600;
    color: $white;
    font-size: 16px; }
.Breeding_text {

    font-size: 15px;
    @include m {
        width: 100%;
        padding-right: 20px;
        margin-right: auto;
        // flex-wrap: nowrap
        font-size: 10px; } }

.breeding_center {
    width: 90%;
    margin: 0 auto;
 }    // background-color: #aaa
.center_title {
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
    font-family: ChakraSemiBold;
    @include m {
        font-size: 12px;
        margin-top: 10px; } }
.title_img {
    width: 30%;
    margin-top: 8px;
    display: flex;
    margin: 10px auto;
    @include m {
        width: 90%; } }
.dog {
    display: flex;
    justify-content: center; }
.imgdiv {
    overflow: auto;
    display: flex;
    width: 100%;
    @include m {
        display: block;
        height: auto;
        max-height: 600px;
        width: auto;
        min-height: auto; } }
.imgdiv::-webkit-scrollbar {
    height: 3px;
    @include m {
        width: 3px; } }
.imgdiv::-webkit-scrollbar-thumb {
    background: #2F2F2F;
    border-radius: 2px; }
.imgdiv::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 2px; }
.Questionimg {
    @include m {
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: center; } }

.baseimg {
    width: 30%;
    float: right; }
.baseimgright {
    width: 30%;
    margin-bottom: 10px; }
.titleimg {
    position: relative; }
.genderHead {
    background-image: url("../../../images/genderbase.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: fit-content;
    padding: 10px 44px;
    margin: 10px auto;
    @include m {
        width: 81%;
        padding: 5px;
        margin: 10px auto;
        text-align: center; } }
.gender {
    border: 2px solid #2F2F2F;
    color: $white;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 10px 0px;
    margin-bottom: 10px;
    &:hover {
        background-color: #2F2F2F; }
    @include m {
        font-size: 14px;
        padding: 5px 0px; } }

.male {
    @include m {
        font-size: 10px;
        margin-left: 9px; } }

.female {
    @include m {
        font-size: 10px;
        margin-left: 9px; } }
.Breeding_Bottom {
    display: flex;
    width: 100%;
    text-align: center;
    @include m {
        flex-wrap: wrap; } }
.bottom_left {
    width: 33.33%;
    text-align: left;
    margin-left: 20px;
    text-shadow: 2px 2px 25px #ffffff;
    @include m {
        width: 100%;
        order: 2;
        text-align: center;
        margin-left: 0;
        margin-top: 10px; } }
.mass {
    font-size: 30px;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
    @include m {
        font-size: 20px; } }
.mass2 {
    font-size: 30px;
    @include m {
        font-size: 20px; } }
.buttonContainer {
    width: 100%;
    text-align: center;
    margin: 20px 0 30px 0; }
.Bbtn {
    margin-bottom: 6px;
    font-size: 20px;
    margin-left: 57px;
    @include m {
        font-size: 10px;
        margin-left: 20px; } }

.text {
    width: 70%;
    @include m {
        width: 100%;
        margin: 0 auto; } }
.bottom_center {
    margin-top: 24px;
    width: 100%; }
.center {
    display: flex;
    width: 100%;
    justify-content: space-between; }
.balance {
    width: 100%;
    font-family: Poppins;
    font-weight: 400;
    color: #9698A1; }
.price {
    width: fit-content;
    text-align: center;
    margin-right: 30px;
    margin-left: auto;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    color: #9698A1;
    @include m {
        width: 100%;
        font-size: 14px;
        margin-right: 0; } }
.tranferButton {
    margin: 20px auto;
    @include m {
        margin-top: 23px; } }
.counterdiv {
    margin-top: 15px;
    text-align: left;
    font-size: 10px; }
.no {
    color: $purple;
    @include m {
        font-size: 10px; } }
.dogimg {
    height: 100px;
    margin: 2px;
    clip-path: polygon(-100% 0%, 0% 0%, 100% 0%, 100% 0%, 94% 0%, 193% 100%, 0% 100%, 6% 100%);
    @include m {
        width: 100px;
        height: auto;
        margin: 3px; } }
.dogselectimg {
    cursor: pointer;
    border: 1px solid $secondary;
    padding: 2px;
    margin: 3px;
    height: 100px;
    @include m {
        width: 100px;
        height: auto;
        margin: 3px; } }
.loader {
    height: 100px;
    padding: 10px;
    @include m {
        width: 100px; } }
.breeding_bottom {
    margin: 0 auto;
    width: fit-content; }
.bottom_right {
    width: fit-content;
    margin: 24px 0 0 30px;
    @include m {
        margin: 24px 0 0 10px; } }
.values {
    text-align: center; }
.percentage_container {
    height: 150px;
    width: 150px;
    background-color: #03111e;
    margin: -20px auto 0 auto;
    border: 1px solid $secondary;
    border-radius: 20px;
    position: relative;
    z-index: 2; }
.percentage {
    height: 100%;
    justify-content: center;
    align-items: center;
    font-family: AnitaSemiSquare;
    color: white;
    font-size: 50px;
    display: flex; }
.note {
    text-align: center;
    font-family: AnitaSemiSquare;
    color: white;
    margin-bottom: 20px; }
.modal {
    width: 40%;
    @include m {
        width: 90%; } }
.noData {
    text-align: center;
    margin: 55px auto;
    font-family: Poppins; }
.count {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    border-radius: 50%;
    color: black;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include m {
        font-size: 10px;
        height: 22px;
        width: 22px; } }
.assetSelected {
    background: url('../../../images/activeBreedBorder.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: fit-content;
    margin: 3px;
    @include m {
        width: fit-content;
        margin: 5px auto; } }
.genderCard {
    background: url('../../../images/breedImgBorder.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 2px;
    margin-bottom: 15px;
    @include m {
        width: fit-content;
        margin: 5px auto; }
    &:hover {
        background: url('../../../images/activeBreedBorder.png');
        background-repeat: no-repeat;
        background-size: 100% 100%; } }
.buyButton {
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    padding: 5px 15px;
    color: black;
    border-radius: 5px;
    font-family: Poppins;
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 5px; }

.ant-radio-checked .ant-radio-inner {
  border-color: #20668b !important;
  background-color: #11242e !important;
}
.ant-radio-inner {
  border-color: #20668b !important;
  background-color: #11242e !important;
}
.ant-radio-checked::after {
  border: 1px solid #20668b !important;
}

.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #20668b !important;
  background-color: #11242e !important;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #20668b !important;
}
.ant-radio-inner::after {
  background-color: #20668b !important;
}
.notched,
.notched:before,
.notched:after,
.notched-text,
.notched-text:before,
.notched-text:after {
  transition: border-color 0.3s ease, color 0.1s ease;
}

.notched, .notched-border {
  color: #9698a1;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-family: "Poppins";
  /* text-transform: uppercase; */
  position: relative;
  overflow: hidden;
  padding: 0 14px;
  /* width: fit-content; */
  cursor: pointer;
  margin: 0 20px 15px 0px;
}

.notched:before,
.notched:after {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  /* z-inxex: 3; */
}

.notched:before {
  border-top: solid 1px #9698a1;
  top: 5px;
  right: 4px;
}

.notched:after {
  border-bottom: solid 1px #9698a1;
  bottom: 5px;
  left: 4px;
}

.notched-bg {
  background-image: linear-gradient(90deg, #fdc22e 0%, #eddc65 100%);
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: -1;
  clip-path: polygon(0% 0%, 0% 0%, calc(100% - 14px) 0%, 100% 14px, 100% 100%, 100% 100%, 14px 100%, 0 18px)
}

.notched .notched-text {
  padding: 5px 3px;
  display: block;
  position: relative;
  border-top: solid 1px #9698a1;
  border-bottom: solid 1px #9698a1;
  z-index: 3;
}

.notched:before,
.notched:after,
.notched-text:before,
.notched-text:after {
  content: "";
  width: 20px;
  height: calc(100% - 13px);
  display: block;
  position: absolute;
  z-index: 3;
}

.notched-text:before {
  top: -1px;
  left: -14px;
  border-top: solid 1px #9698a1;
  border-left: solid 1px #9698a1;
}

.notched-text:after {
  bottom: -1px;
  right: -14px;
  border-bottom: solid 1px #9698a1;
  border-right: solid 1px #9698a1;
}
.notched-active{
  color: black;
  background-image: linear-gradient(90deg, #fdc22e 0%, #eddc65 100%);
  clip-path: polygon(0% 0%, 0% 0%, calc(100% - 14px) 0%, 100% 14px, 100% 100%, 100% 100%, 14px 100%, 0 18px)
}
.notched-bg:hover{
  opacity: 1;
  z-index: 3;
  color: black;
}


.notched-border:before,
.notched-border:after {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  /* z-inxex: 3; */
}
.notched-border:before {
  border-top: solid 1px #9698a1;
  top: 1px;
  right: 4px;
}

.notched-border:after {
  border-bottom: solid 1px #9698a1;
  bottom: 2px;
  left: 4px;
}
.notched-border:before,
.notched-border:after,
.notched-border-text:before,
.notched-border-text:after {
  content: "";
  width: 20px;
  height: calc(113% - 13px);
  display: block;
  position: absolute;
  z-index: 3;
}

.notched-border-text:before {
  top: -1px;
  left: -10px;
  border-top: solid 1px #9698a1;
  border-left: solid 1px #9698a1;
}

.notched-border-text:after {
  bottom: -1px;
  right: -10px;
  border-bottom: solid 1px #9698a1;
  border-right: solid 1px #9698a1;
}
.notched-border .notched-border-text {
  padding: 5px 3px;
  display: block;
  position: relative;
  border-top: solid 1px #9698a1;
  border-bottom: solid 1px #9698a1;
  z-index: 3;
}
.notched-border-active{
  color: black;
  background: #9698A1;
  clip-path: polygon(0% 0%, 0% 0%, calc(100% - 8px) 0%, 110% 14px, 100% 100%, 100% 100%, 14px 109%, 0 24px)
}
@media only screen and (max-width: 767px) {
  .notched-border-text:before {
    width: 10px;
  }
  .notched-border-text:after {
    width: 10px;
  }
}


.leader,
.leader:before,
.leader:after,
.leader-text,
.leader-text:before,
.leader-text:after {
  transition: border-color 0.3s ease, color 0.1s ease;
}
.leader, .leader-border {
  color: #9698a1;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-family: "Poppins";
  /* text-transform: uppercase; */
  position: relative;
  overflow: hidden;
  padding: 0 14px;
  /* width: 100%; */
  cursor: pointer;
  margin: 0 20px 0px 0px;
}
.leader:before,
.leader:after {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  /* z-inxex: 3; */
}
.leader:before {
  border-top: solid 1px #9698a1;
  top: 3px;
  right: 5px;
}

.leader:after {
  border-bottom: solid 1px #9698a1;
  bottom: 4px;
  left: 5px;
}
.leader:before,
.leader:after,
.leader-text:before,
.leader-text:after {
  content: "";
  width: 20px;
  height: calc(105% - 13px);
  display: block;
  position: absolute;
  z-index: 3;
}

.leader-text:before {
  top: -1px;
  left: -14px;
  border-top: solid 1px #9698a1;
  border-left: solid 1px #9698a1;
}

.leader-text:after {
  bottom: -1px;
  right: -14px;
  border-bottom: solid 1px #9698a1;
  border-right: solid 1px #9698a1;
}
.leader .leader-text {
  padding: 5px 3px;
  display: block;
  position: relative;
  border-top: solid 1px #9698a1;
  border-bottom: solid 1px #9698a1;
  z-index: 3;
  white-space: nowrap;
}
.leader-active{
  color: #FDC22E;
  background: #3F3800;
  clip-path: polygon(0% 0%, 0% 0%, calc(95% - 8px) 0%, 110% 19px, 100% 100%, 100% 100%, 18px 109%, 0 22px);
  padding: 6px 17px;
  overflow: hidden;
  display: inline-block;
  margin: 0 15px 0 0;
  font-size: 14px;
  font-family: 'Poppins';
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .leader-text:before {
    width: 14px;
  }
  .leader-text:after {
    width: 14px;
  }
  .leader:before {
    right: 6px;
  }
  .leader:after {
    left: 6px;
    bottom: 3px;
  }
}



.cards,
.cards:before,
.cards:after,
.cards-text,
.cards-text:before,
.cards-text:after {
  transition: border-color 0.3s ease, color 0.1s ease;
}
.cards, .cards-border {
  color: #9698a1;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-family: "Poppins";
  /* text-transform: uppercase; */
  position: relative;
  overflow: hidden;
  padding: 0 14px;
  width: 100%;
  cursor: pointer;
  margin-top: 3px
}
.cards:before,
.cards:after {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  /* z-inxex: 3; */
}
.cards:before {
  border-top: solid 1px #2F2F2F;
  top: 2px;
  right: 6px;
}
.cards:hover::before {
  border-top: solid 1px #FDC22E;
  top: 2px;
  right: 6px;
}

.cards:after {
  border-bottom: solid 1px #2F2F2F;
  bottom: 2px;
  left: 6px;
}
.cards:hover::after {
  border-bottom: solid 1px #FDC22E;
  bottom: 2px;
  left: 6px;
}

.cards:before,
.cards:after,
.cards-text:before,
.cards-text:after {
  content: "";
  width: 20px;
  height: calc(109% - 13px);
  display: block;
  position: absolute;
  z-index: 3;
}

.cards-text:before {
  top: -1px;
  left: -14px;
  border-top: solid 1px #2F2F2F;
  border-left: solid 1px #2F2F2F;
}
.cards-text:hover::before {
  top: -1px;
  left: -14px;
  border-top: solid 1px #FDC22E;
  border-left: solid 1px #FDC22E;
}
.cards-text:after {
  bottom: -1px;
  right: -14px;
  border-bottom: solid 1px #2F2F2F;
  border-right: solid 1px #2F2F2F;
}
.cards-text:hover::after {
  bottom: -1px;
  right: -14px;
  border-bottom: solid 1px #FDC22E;
  border-right: solid 1px #FDC22E;
}
.cards .cards-text {
  padding: 5px 3px;
  display: block;
  position: relative;
  border-top: solid 1px #2F2F2F;
  border-bottom: solid 1px #2F2F2F;
  z-index: 3;
}
.cards .cards-text:hover {
  padding: 5px 3px;
  display: block;
  position: relative;
  border-top: solid 1px #FDC22E;
  border-bottom: solid 1px #FDC22E;
  z-index: 3;
}
.cards-active{
  color: black;
  background: #2F2F2F;
  clip-path: polygon(0% 0%, 0% 0%, calc(100% - 8px) 0%, 110% 14px, 100% 100%, 100% 100%, 14px 109%, 0 24px)
}
@media only screen and (max-width: 767px) {
  .cards-text:before {
    width: 10px;
  }
  .cards-text:after {
    width: 10px;
  }
}
@import "../../styles/helpers";

.section {
    overflow: hidden;
    margin-top: 20px; }

.wrapper {
    position: relative;
    padding: 0 100px;
    @include m {
        padding: 0 20px; } }

.title {
    margin-bottom: 64px;
    font-family: Deltha;
    text-align: center;
    font-size: 25px;
    color: $white;
    margin-bottom: 0;
    @include dark {
        color: $neutrals7; } }

.inner {
    //margin: 0 -16px
    margin: 0 167px;
    @include m {
        margin: 0 -50px; } }

.shade {
    display: flex;
    margin: 10px auto; }
.card {
    padding: 10px;
    width: 100%;
    max-width: 350px;
    display: flex !important;
    justify-content: center;
    position: relative; }


.previewImg {
    width: 100%;
    float: left;
    border: 1px solid #20668b;
    border-radius: 3px;
    padding: 5px;
    display: flex;
    justify-content: center;
    @include m {
        width: 85%; } }

.border {
    float: left;
    height: 100%;
    width: 16px; }
.title {
    background-image: url('../../images/base.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: $white;
    font-family: AnitaSemiSquare;
    padding: 12px 40px;
    margin-left: 5px;
    font-size: 12px;
    position: absolute;
    left: 11px;
    bottom: 6%;
    @include t {
        padding: 12px 10px;
        left: 35px; } }


@import "../../../styles/helpers";

.head {
    text-align: center;
    font-family: 'ChakraSemiBold';
    font-size: 32px;
    color: $white;
    font-weight: 600;
    @include m {
        font-size: 18px; } }
.heading {
    color: $white;
    font-family: Poppins;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange; }
.subhead {
    font-family: Poppins;
    font-size: 24px;
    color: $white;
    // background-image: url('../../../images/stakearrow.png')
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-right: 130px;
    width: fit-content;
    white-space: nowrap;
    margin: 24px 0;
    @include t {
        font-size: 16px;
        padding-right: 90px; }
    @include m {
        font-size: 12px;
        padding-right: 70px; } }
.balance {
    text-align: center;
    color: $white;
    font-family: Poppins;
    font-size: 16px; }
.shade {
    width: 80%;
    display: flex;
    margin: 24px auto; }

.card {
    width: 100%; }
.card {
    height: 235px;
    display: inline-block;
    padding: 10px;
    @include m {
        height: auto; } }
.previewImg {
    width: 100%;
    height: auto;
    float: right;
    // border: 1px solid #20668b
    // border-radius: 3px
    background-image: url('../../../images/activeBreedBorder.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 5px;
    img {
        clip-path: polygon(0 0, 94% 0, 100% 4.5%, 100% 80%, 100% 100%, 6% 100%, 0 93%, 0% 20%);
        width: 100%; }
    @include d {
        height: auto; }
    @include m {
 }        // height: 235px
    @include w {
        height: auto; }
    // +t
    //     height: 235px
    // +x
 }    //     height: 235px
.border {
    float: left;
    width: 16px;
    height: 100%;
    @include m {
        width: 12px; } }
.title {
    // background-image: url('../../../images/base.png')
    // background-repeat: no-repeat
    // background-size: 100% 100%
    // text-align: center
    // color: $white
    // font-family: Poppins
    // padding: 10px 4px
    // margin-left: 27px
    // font-size: 10px
    // position: absolute
    // bottom: 15%
    text-align: center;
    color: #ffffff;
    font-family: Poppins;
    padding: 10px 4px;
    font-size: 15px;
    font-weight: 500;
    @include d {
        font-size: 12px; } }
.yield {
    color: $dark-yellow;
    font-family: Poppins;
    font-weight: lighter;
    // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
    @include m {
        font-size: 12px; } }

.loader {
    margin: 25px auto; }

.coundown {
    font-size: 12px;
    @include m {
        font-size: 10px; } }
.empty {
    margin: 70px auto;
    color: $white;
    font-family: Poppins;
    text-align: center;
    font-size: 18px; }
.tranferButton {
    margin: 20px auto;
    width: 100%; }

.tranferModal {
    width: 40% !important;
    @include t {
        width: 90% !important; } }
.close {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 0;
  img {
    height: 37px;
    width: 37px;
    transition: transform .2s;
    img {
      fill: $neutrals8; } }
  &:hover {
    img {
      transform: rotate(90deg); } } }
.modalContainer {
    margin-top: 0 !important; }
.lineBorder {
    margin: 30px auto;
    border-top: 2px solid $border-grey;
    width: 93%; }
.margin {
    @include m {
        margin-top: 12px; } }

@import "../../../styles/helpers";
.container {
    // background-image: url("../../../images/Gbh.png")
    // background-repeat: no-repeat
 }    // background-size: 100% 100%
.breeding_g1_container {
    font-family: AnitaSemiSquare;
    color: white; }
sup {
    font-size: 15px;
    @include m {
        font-size: 10px; } }
.Breeding_title {
    // background-color: #aaa
    width: 70%;
    position: relative;
    left: 20px;
    font-size: 30px;
    line-height: 2.5;
    text-shadow: 2px 2px 25px #ffffff;
    @include m {
        font-size: 20px;
        line-height: 1.5;
        width: 100%;
        margin-top: 10px; } }
.Breeding_note {
    font-size: 24px;
    text-align: center;
    margin: 20px;
    @include m {
        font-size: 16px;
        margin: 10px; } }
.breeding {
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange; }
.Breeding_text {
    font-size: 15px;
    @include m {
        width: 100%;
        padding-right: 20px;
        margin-right: auto;
        // flex-wrap: nowrap
        font-size: 10px;
        margin: 10px 0; } }

.breeding_center {
    width: 90%;
    margin: 0 auto;
 }    // background-color: #aaa
.center_title {
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 900;
    text-shadow: 2px 2px 25px #ffffff;
    @include m {
        font-size: 12px;
        margin-top: 10px; } }
.title_img {
    width: 30%;
    margin-top: -22px;
    @include m {
        width: 90%; } }
.dog {
    display: flex;
    justify-content: center; }
.imgdiv {
    overflow: auto;
    display: flex;
    width: 582px;
    max-width: 600px;
    @include m {
        display: block;
        height: 600px;
        max-height: 600px;
        width: auto;
        max-width: auto; } }
.imgdiv::-webkit-scrollbar {
    height: 2px;
    @include m {
        width: 2px; } }
.imgdiv::-webkit-scrollbar-thumb {
    background: $secondary;
    border-radius: 1px; }
.imgdiv::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 1px; }
.Questionimg {
    @include m {
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: center; } }

.baseimg {
    width: 30%;
    float: right; }
.baseimgright {
    width: 30%;
    margin-bottom: 10px; }
.titleimg {
    position: relative; }
.titleimgleft {
    background-image: url("../../../images/genderbase.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: fit-content;
    padding: 10px 44px;
    margin-bottom: 10px;
    margin-left: 22rem;
    @include m {
        width: 100%;
        padding: 5px;
        margin-bottom: 0px;
        margin-left: 0rem; } }

.titleimgright {
    background-image: url("../../../images/genderbase.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: fit-content;
    padding: 10px 45px;
    margin-bottom: 10px;
    @include m {
        width: 100%;
        padding: 5px;
        margin-bottom: 0px;
        margin-left: 0rem; } }

.male {
    @include m {
        font-size: 10px;
        margin-left: 9px; } }

.female {
    @include m {
        font-size: 10px;
        margin-left: 9px; } }
.Breeding_Bottom {
    display: flex;
    width: 100%;
    text-align: center;
    @include m {
        flex-wrap: wrap; } }
.bottom_left {
    width: 33.33%;
    text-align: left;
    margin-left: 20px;
    text-shadow: 2px 2px 25px #ffffff;
    @include m {
        width: 100%;
        order: 2;
        text-align: center;
        margin-left: 0;
        margin-top: 10px; } }
.mass {
    font-size: 30px;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
    @include m {
        font-size: 20px; } }
.mass2 {
    font-size: 30px;
    @include m {
        font-size: 20px; } }
.breed_btn1 {
    background-image: url('../../../images/Default.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 40%;
    text-align: end;
    padding: 5px;
    text-align: center;
    box-shadow: #00d8ff -1px 1px 8px;
    white-space: nowrap;
    background-color: #04dbe04f;
    border: 2px solid $secondary;
    border-radius: 2px;
    font-size: 20px;
    cursor: pointer;
    position: relative;

    @include m {
        width: 50%;
        font-size: 15px;
        margin: 0 auto; } }
.buttonContainer {
    width: 200px;
    text-align: center;
    margin: 10px auto 30px auto; }
.Bbtn {
    margin-bottom: 6px;
    font-size: 20px;
    margin-left: 57px;
    @include m {
        font-size: 10px;
        margin-left: 20px; } }

.text {
    width: 70%;
    @include m {
        width: 100%;
        margin: 0 auto; } }
.bottom_center {
    margin-top: 24px;
    width: 100%; }
.center {
    display: flex;
    width: 100%;
    justify-content: space-between; }
.balance {
    width: 100%; }
.price {
    width: 50%;
    text-align: end;
    margin-right: 30px;
    @include m {
        width: 100%;
        margin-right: 0; } }
.tranferButton {
    margin: 20px auto;
    @include m {
        margin-top: 23px; } }
.counterdiv {
    margin-top: 15px;
    text-align: left;
    font-size: 10px; }
.no {
    @include m {
        font-size: 10px; } }
.dogimg {
    margin: 3px;
    height: 100px;
    border: 1px solid transparent;
    padding: 2px;
    &:hover,
    &.active {
        cursor: pointer;
        border: 1px solid $secondary;
        padding: 2px;
        margin: 3px; }
    @include m {
        width: 100px;
        height: auto;
        margin: 3px; } }
.dogselectimg {
    cursor: pointer;
    border: 1px solid $secondary;
    padding: 2px;
    margin: 3px;
    height: 100px;
    @include m {
        width: 100px;
        height: auto;
        margin: 3px; } }
.loader {
    height: 100px;
    padding: 10px;
    @include m {
        width: 100px; } }
.breeding_bottom {
    margin: 0 auto;
    width: fit-content; }
.bottom_right {
    width: fit-content;
    margin: 24px 0 0 30px;
    @include m {
        margin: 24px 0 0 10px; } }
.values {
    text-align: left; }
.gender {
    border: 2px solid #2F2F2F;
    color: $white;
    font-family: Poppins;
    font-size: 16px;
    text-align: center;
    padding: 3px 0;
    margin-bottom: 10px;
    &:hover {
        background-color: #2F2F2F; } }
.assetImage {
    width: 100%;
    padding: 2px;
    clip-path: polygon(-100% 0%, 0% 0%, 100% 0%, 100% 0%, 92% 0%, 193% 100%, 0% 100%, 10% 100%);
    border: 1px solid transparent; }

.imageDiv {
    background: url('../../../images/breedImgBorder.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &:hover {
        background: url('../../../images/activeBreedBorder.png');
        background-repeat: no-repeat;
        background-size: 100% 100%; } }
.assetSelected {
    width: 100%;
    padding: 2px;
    border: 1px solid transparent;
    border: 1px solid $secondary; }

.title {
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    margin-bottom: 5px;
    @include m {
        font-size: 10px; } }
.breedingFee {
    font-family: Poppins;
    font-size: 18px;
    color: $white;
    text-align: center;
    margin: 10px;
    color: #9698A1;
    @include m {
        font-size: 16px;
        display: none; } }
.pricemob {
    font-family: Poppins;
    font-size: 18px;
    color: #9698A1;
    text-align: center;
    margin: 10px;
    display: none;
    @include m {
        font-size: 16px;
        display: block; } }
.amount {
    color: $white;
    font-family: Poppins;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin: 0 5px;
    @include m {
        font-size: 18px; } }
.error {
    color: #f13838;
    text-align: center;
    margin-top: 5px;
    font-weight: 600;
    font-family: Poppins; }
.placeholder {
    color: grey;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }
.hash {
    text-align: center;
    color: white;
    margin-bottom: 5px;
    font-size: 14px;
    font-family: Poppins;
    @include m {
        font-size: 12px; } }
.success {
    text-align: center;
    color: #27AE60;
    margin-bottom: 5px;
    font-size: 14px;
    font-family: Poppins;
    @include m {
        font-size: 12px; } }
.importantNote {
    font-family: Poppins;
    color: $white;
    font-size: 12px;
    span {
        color: $purple;
        text-decoration: underline;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        display: block; } }
.breedButton {
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: center; }
.raceBg {
    min-height: 400px;
    position: relative;
    margin: 20px 30px;
    @include m {
        margin: 10px 0 20px;
        min-height: 180px; }
    @include a {
        min-height: 100px; } }
.raceBg1 {
    width: 100%; }
.raceMask {
    position: absolute;
    top: 36%;
    left: 5%;
    width: 100%;
    @include t {
        top: 15%; }
    @include m {
        width: 90%;
        margin: 0 20px;
        top: 32%;
        left: 0; }
    @include s {
        top: 20%; }
    h2 {
        font-family: 'Chakra';
        color: $white;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 42px;
        @include d {
            font-size: 24px; }
        @include m {
            font-size: 14px; } }
    p {
        font-size: 16px;
        font-family: 'Poppins';
        color: $white;
        @include d {
            font-size: 14px; }
        @include m {
            font-size: 12px; } } }
.top, .info {
    position: relative; }
.banner {
    width: 100%;
    border-radius: 30px;
    height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
    @include m {
        height: 150px; } }
.heading {
    color: $white;
    font-size: 32px;
    font-family: 'ChakraSemiBold';
    margin-bottom: 10px;
    @include m {
        font-size: 24px; } }
.subHead {
    color: $white;
    font-family: 'Poppins';
    font-weight: 500; }
.topContainer {
    position: absolute;
    top: 30%;
    left: 7%;
    @include m {
        top: 10%; } }
.info {
    height: 150px; }
.dotsRight {
    height: 100px;
    float: right;
    @include m {
        width: 40%; } }
.dotsLeft {
    height: 100px;
    float: left;
    @include m {
        width: 40%; } }
.breedText {
    color: #9698A1;
    font-size: 14px;
    font-family: Poppins;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center; }
.information {
    font-size: 30px;
    font-family: ChakraSemiBold;
    color: $white;
    position: absolute;
    left: 0;
    right: 0;
    top: 34px;
    text-align: center;
    @include m {
        font-size: 16px; }
    span {
        color: #FDC22E; } }

@import "../../styles/helpers";
.head {
    text-align: center;
    font-family: 'ChakraSemiBold';
    font-size: 32px;
    color: $white;
    margin-bottom: 24px;
    font-weight: 600;
    @include m {
        font-size: 18px; } }
.orange {
    color: $white;
    font-family: Poppins;
    // font-weight: lighter
 }    // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
.white {
    color: $white;
    font-family: Poppins;
    font-weight: lighter;
 }    // text-shadow: 2px 2px 25px $white
.img {
    width: 100%; }
.text {
    font-family: Poppins;
    color: $white;
    font-size: 16px;
    text-align: center;
    @include m {
        font-size: 14px; } }
.label {
    font-family: Poppins;
    color: $white;
    font-size: 16px;
    display: flex;
    margin-bottom: 8px;
    @include m {
        font-size: 14px; }
    img {
        height: 20px;
        margin-right: 5px; } }
.activelabel {
    font-family: Poppins;
    color: $secondary;
    font-size: 16px;
    img {
        height: 20px;
        margin-right: 5px; } }
.input {
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border-radius: 1px;
    // border: 1px solid $secondary
    // background: $primary
    font-family: Poppins;
    font-weight: bold;
    margin-top: -5px;
    @include caption-bold-1;
    color: $white;
    align-items: center;
    display: flex; }


.iconIput {
    border-left: none !important; }
.line {
    width: 100%;
    margin-top: -15px;
    height: 3px; }

.description {
    font-family: PoppinsThin;
    font-size: 12px;
    margin-top: 3px;
    color: $white;
    @include m {
        font-size: 10px; } }

.wrap {
    // display: flex
    background-image: url('../../images/inputBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat; }
.kibblez {
    border-radius: 1px;
    border: 1px solid $secondary;
    background: $primary;
    height: 40px;
    padding: 6px;
    padding-right: 0px;
    border-right: none;
    margin-top: -5px; }
.iconIput::placeholder, .input::placeholder {
    color: #04dfe569; }
.shade {
    width: 100%;
    display: flex;
    margin: 20px auto;
    @include m {
        margin: 15px auto; } }
.body {
    margin-left: 30px;
    @include m {
        margin-left: 0; } }
.value {
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border-radius: 1px;
    // border: 1px solid #04dfe56e
    // background: transparent
    font-family: Poppins;
    font-weight: bold;
    margin-top: -5px;
    @include caption-bold-1;
    color: $white;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 16px;
    @include m {
        font-size: 13px; } }
.available {
    width: fit-content;
    text-align: center;
    margin: 0 auto;
    @include m {
        margin: 10px 0; } }

.button {
    align-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%; }
.quantity {
    @include m {
        margin: 10px 0; } }
.plusIcon {
    font-size: 30px;
    &:hover {
        cursor: pointer; } }
.minusIcon {
    font-size: 30px;
    justify-content: end;
    &:hover {
        cursor: pointer; } }
.quantityValue {
    justify-content: center;
    margin: 0 auto; }
.blockIcon {
    font-size: 30px;
    color: #ffffff4a;
    &:hover {
        cursor: not-allowed; } }
.ethimg {
    height: 22px; }
.error {
    color: red;
    font-size: 16px;
    font-family: Poppins;
    text-align: center;
    margin-bottom: 10px; }

.success {
  text-align: center;
  color: $white;
  margin-top: 16px;
  font-size: 16px;
  font-family: Poppins; }
.lineBorder {
    margin: 25px;
    border-top: 1px solid $border-grey;
    width: 93%; }

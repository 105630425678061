@import "../../../styles/helpers";

.community {
    padding: 20px 30px; }
.heading {
    color: $white;
    font-family: ChakraSemiBold;
    font-size: 32px;
    margin-bottom: 10px;
    margin-left: 110px;
    @include m {
        font-size: 18px;
        margin-left: 16px; } }
.socialMedia {
    background-image: url('../../../images/communityhover.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 80%;
    margin: 0 auto;
    padding: 15px 0;
    color: #9698A1;
    &:hover {
        transition: background-image 1.5s, color 1.5s;
        background-image: url('../../../images/community.png');
        color: $white; }
    @include m {
        margin-bottom: 20px; } }
.icon {
    font-size: 42px;
    color: inherit;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    margin: 0 auto; }
.name {
    color: $white;
    font-weight: 700;
    font-family: 'Poppins';
    font-style: normal;
    @include m {
        font-size: 12px; } }
.type {
    color: inherit;
    font-weight: 500;
    font-family: 'Poppins';
    font-style: normal;
    @include m {
        font-size: 12px; } }
.a {
    color: inherit;
    &:hover {
        color: inherit; } }

@import "../../styles/helpers";

.head {
    text-align: center;
    font-family: 'ChakraSemiBold';
    font-size: 32px;
    color: $white;
    font-weight: 600;
    @include m {
        font-size: 18px; } }
.subhead {
    font-family: AnitaSemiSquare;
    font-size: 24px;
    color: $white;
    background-image: url('../../images/stakearrow.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-right: 130px;
    width: fit-content;
    margin: 24px 0;
    @include t {
        font-size: 16px;
        padding-right: 90px; } }
.balance {
    text-align: center;
    color: $white;
    font-family: Poppins;
    font-size: 16px;
    @include m {
        font-size: 10px; } }
.shade {
    width: 80%;
    display: flex;
    margin: 24px auto; }
.previewImg {
    width: 100%;
    height: 235px;
    float: right;
    border: 1px solid #20668b;
    border-radius: 3px;
    padding: 5px; }

.border {
    float: left;
    height: 100%;
    width: 16px; }
.title {
    background-image: url('../../images/base.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: $white;
    font-family: AnitaSemiSquare;
    padding: 10px 4px;
    margin-left: 27px;
    font-size: 10px;
    position: absolute;
    bottom: 15%; }
.yield {
    color: $dark-yellow;
    font-family: Poppins;
    font-weight: lighter;
 }    // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;


.input {
    color: $white;
    width: 200px;
    margin: 0 auto;
    @include d {
        width: 150px; } }


.button {
    // margin-top: 32px
    @include t {
        margin: 24px auto 0 auto;
        width: fit-content; } }

.card {
    width: 70%;
    margin: 0 auto;
    background: #2F2F2F;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: fit-content;
    padding: 32px;
    @include t {
        width: 80%; } }

.coundown {
    font-size: 12px;
    @include m {
        font-size: 10px; } }
.modal {
    width: 50% !important;
    @include t {
        width: 90% !important; } }
.info {
    font-family: Poppins;
    font-size: 12px;
    margin-top: 3px;
    color: $white;
    text-align: center; }
.close {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 0;
  img {
    height: 37px;
    width: 37px;
    transition: transform .2s;
    img {
      fill: $neutrals8; } }
  &:hover {
    img {
      transform: rotate(90deg); } } }
.modalContainer {
    margin-top: 0 !important; }
.lineBorder {
    margin: 30px auto;
    border-top: 2px solid $border-grey;
    width: 93%; }
.rowFlex {
    align-items: center; }
.margin {
    @include m {
        margin-top: 12px; } }

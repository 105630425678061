@import "../../styles/helpers";

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: #9698A1; }

.activelabel {
    font-family: 'Poppins';
    color: $secondary;
    font-size: 16px;
    img {
        height: 20px;
        margin-right: 5px; } }
.input,.iconIput {
    width: 100%;
    height: fit-content;
    padding: 4px 14px;
    // border-radius: 12px
    // border: 2px solid $neutrals6
    // background-image: url('../../images/dropdownImg.png')
    // background-size: 100% 100%
    // background-repeat: no-repeat
    background-color: transparent !important;
    font-weight: bold;
    // margin-top: -5px
    @include caption-bold-1;
    color: $white;
    @include placeholder {
        color: #9698A1;
        font-weight: 100;
        font-family: 'Poppins'; }
    &:focus {
        // border-color: $neutrals4
        // color: $primary
        // background: $secondary
        // border: 1px solid $secondary
        // font-weight: bold
 } }        // font-family: HeeboBold
.iconIput {
    border-left: none !important; }
.line {
    width: 100%;
    margin-top: -15px;
    height: 3px; }

.description {
    font-family: HeeboThin;
    font-size: 12px;
    margin-top: 3px;
    color: $white; }
.wrap {
    display: flex;
    background-image: url('../../images/inputBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    height: fit-content;
    padding: 4px 14px; }
.kibblez,.kibblezFocus {
    // border-radius: 1px
    // border: 1px solid $secondary
    // background: $primary
    height: 32px;
    padding: 6px;
    padding-right: 0px;
    border-right: none;
 }    // margin-top: -5px
.kibblezFocus {
 }    // background-color: $secondary !important
.iconIput::placeholder, .input::placeholder {
 }    // color: #04dfe569
.disabled {
    cursor: not-allowed; }
.success {
    display: flex;
    background-image: url('../../images/successInput.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    height: fit-content;
    padding: 4px 14px; }
.error {
    display: flex;
    background-image: url('../../images/errInput.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    height: fit-content;
    padding: 4px 14px; }

@import "../../../styles/helpers";

.title {
    color: $white;
    font-size: 32px;
    font-family: ChakraBold;
    text-align: center; }
.container {
    max-width: 1550px;
    margin: 30px auto;
    padding: 0 20px; }
.heading {
    color: $white;
    font-family: ChakraSemiBold;
    font-size: 24px;
    margin-bottom: 15px; }
.date, .address, .reward, .email, .status {
    font-family: 'Poppins';
    color: $grey;
    text-align: center;
    font-size: 14px;
    @include m {
        font-size: 13px; } }
.address, .reward {
    font-weight: 500;
    color: $white; }
.address {
    cursor: pointer; }
.reward {
    color: $white;
    span {
        color: $green; } }
.submitted {
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600; }
.process {
    color: $purple;
    font-weight: 500; }

.transfer {
    color: $green;
    font-weight: 500; }

.email {
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600; }
.table {
    overflow-x: auto; }
.spin {
    margin: 20px auto;
    padding: 15px;
    width: auto;
    overflow: auto;
    @include m {
        width: 1000px; }
    @include a {
        width: 1000px; } }
.header {
    height: 50px;
    align-items: center;
    border: 1px solid $border-grey;
    border-left: none;
    border-right: none; }

.head {
    color: $white;
    font-family: Poppins;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    @include m {
        font-size: 14px; } }
.row, .highlightedRow {
    height: 40px;
    align-items: center; }
.highlightedRow {
    background-color: #1E1E1E; }

.nodata {
    text-align: center;
    color: $white;
    font-family: Poppins;
    font-weight: 500;
    margin: 40px auto; }
.loader {
    position: absolute;
    top: 65px;
    left: 0;
    right: 0; }
.table {
    width: 95%;
    margin: 0 auto; }
.extraBadge,.freeBadge {
    width: fit-content;
    font-family: 'Poppins';
    color: black;
    background: $purple;
    border-radius: 50px;
    padding: 3px 12px;
    font-size: 13px;
    font-weight: 600;
    margin: 0 auto; }
.freeBadge {
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    color: black; }

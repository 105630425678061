@import "../../styles/helpers";

.top {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    // border-bottom: 1px solid $neutrals6
    @include t {
        display: block;
        margin-bottom: 32px;
        padding-bottom: 0;
        border: none; }
    @include m {
        margin-bottom: 24px; }
    @include dark {
        border-color: $neutrals3; } }
.section {
    padding: 0; }
.title {
    color: white;
    font-size: 32px;
    margin-right: 100px;
    margin-bottom: 16px;
    color: $white;
    font-family: 'ChakraSemiBold';
    font-weight: lighter;
    // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
    @include m {
        font-size: 18px;
        margin-right: auto; } }

.search {
    position: relative;
    flex-shrink: 0;
    width: 320px;
    margin-left: 30px;
    @include t {
        width: 100%;
        margin: 0; } }

.input {
    width: 100%;
    height: 48px;
    padding: 0 48px 0 14px;
    border-radius: 12px;
    background: none;
    border: 2px solid $neutrals6;
    @include poppins;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    @include placeholder {
        color: $neutrals4; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: $blue;
    transition: background .2s;
    svg {
        fill: $neutrals8; }
    &:hover {
        background: darken($blue, 10); } }

.row {
    display: flex;
    @include m {
        display: block; } }

.wrapper {
    flex-grow: 1;
    padding-left: 22px;
    @include t {
        padding-left: 0; } }

.dropdown {
    width: 256px;
    margin-right: auto;
    @include t {
        width: 100%;
        margin: 0 0 32px; } }
.list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include s {
        display: block;
        margin: 0; } }

.card {
    flex-grow: 0;
    flex-shrink: 0;
    flex: 0 0 calc(25% - 32px);
    max-width: calc(25% - 32px);
    margin: 32px 10px 0;
    @include x {
        flex: 0 0 calc(30% - 32px);
        max-width: calc(30% - 32px);
        margin: 32px 16px 0; }
    @include d {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px);
        margin: 32px 16px 0; }
    @include t {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px); }
    @include m {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px); }
    @include s {
        flex: 0 0 calc(100% - 32px);
        max-width: calc(100% - 32px); } }

.btns {
    margin-top: 32px;
    text-align: center; }

.loader {
    margin: 32px auto 10px;
    @include dark {
        background: $neutrals1; } }
.loading {
    margin-right: auto;
    @include m {
        margin: 0 auto; } }
.nopost {
    color: $white;
    text-align: center;
    margin: 25% auto; }
.asset {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 50px; }
.assetBtn {
    background-image: url('../../images/dropdownImg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 6px 25px;
    color: #9698A1;
    font-family: 'Poppins'; }
.titleFlex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 96%;
    @include m {
        width: 100%;
        flex-direction: column;
        gap: 20px; } }
.titles {
    color: #9698A1;
    font-family: 'Poppins';
    h1 {
        margin-bottom: 5px;
        font-size: 24px;
        font-weight: 600;
        color: $white;
        @include m {
            font-size: 14px; } }
    p {
        margin-bottom: 0; } }
.searchBox {
    @include m {
        width: 60%; } }

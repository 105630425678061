@import "../../styles/helpers";

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 48px;
  background-image: url('../../images/modalbg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0;
  animation: showModal .4s forwards;
  overflow: auto;
  z-index: 999;
  @include m {
    padding: 30px 16px; } }

@keyframes showModal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.outer {
  position: relative;
  width: fit-content;
  max-width: 80%;
  margin: auto;
  padding: 32px;
  z-index: 2;
  background-image: url('../../images/modalscreenbg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: $white; }

.container {
  @include t {
 } }    // margin-top: 34px

.close {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 0;
  img {
    height: 37px;
    width: 37px;
    transition: transform .2s;
    img {
      fill: $neutrals8; } }
  &:hover {
    img {
      transform: rotate(90deg); } }
  @include m {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 0; } }
.gallery {
  width: 80%;
  height: 90%; }

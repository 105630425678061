.ant-pagination {
  color: white !important;
}
.pagenate {
  color: white !important;
}
.ant-pagination-item-active a{
  font-family: inherit !important;
}
.ant-pagination-item-active {
  color: black !important;
  border: none !important;
  background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%) !important;
  border-radius: 50% !important;
}
.ant-pagination-item {
  font-family: inherit !important;
  background-color: transparent;
  border: 1px solid #9698A1 !important;
  border-radius: 50% !important;
  min-width: 47px !important;
  height: 45px !important;
  line-height: 45px !important;
  margin-right: 50px !important;

}
.anticon svg {
  color: white !important;
  margin-top: -5px !important;
}
.ant-pagination-item-ellipsis {
  color: white !important;
}
.ant-pagination-item-ellipsis:hover {
  color: aqua !important;
}
.ant-pagination-options {
  /* display: none; */
}
.ant-pagination-jump-next,.ant-pagination-jump-prev{
  margin-right: 50px !important;
}
.ant-pagination.ant-pagination-mini .ant-pagination-next{
  background-image: url('../../images/slickBcwdImg..png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-width: 37px !important;
  height: 35px !important;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev {
  background-image: url('../../images/slickFrwdImg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-width: 37px !important;
  height: 35px !important;
  margin-right: 50px !important;
}
@import "../../../styles/helpers";

.head {
    text-align: center;
    font-family: 'ChakraSemiBold';
    font-size: 32px;
    color: $white;
    margin-bottom: 24px;
    @include t {
        font-size: 18px;
        margin-bottom: 16px; } }

.field {
    text-align: start;
    color: $white;
    font-family: Poppins;
    font-size: 16px;
    @include t {
        font-size: 14px; } }
.value {
    color: $white;
    font-family: Poppins;
    font-size: 16px;
    @include t {
        font-size: 14px; } }
.shade {
    width: 80%;
    display: flex;
    margin: 24px auto; }

.yield {
    color: $white;
    font-family: Poppins;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange; }

.loader {
    margin: 25px auto; }

.button {
    margin: 30px auto 0;
    width: 35%;
    display: block; }
.body {
    width: 80%;
    margin: 0 auto;
    @include t {
        width: 95%; } }
.notes {
    margin-left: 10%;
    margin-top: 10px;
    font-family: Poppins; }

.notes_heading {
    color: $purple;
    font-weight: 600; }
.notes_content {
    display: list-item;
    margin-left: 15px; }

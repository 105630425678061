@import "../../../styles/helpers";

.cyberdogz {
    padding: 20px 30px; }
.heading {
    color: $white;
    font-family: ChakraSemiBold;
    font-size: 32px;
    margin-bottom: 10px;
    @include m {
        font-size: 18px; } }
.text {
    color: inherit;
    font-weight: 500;
    font-family: 'Poppins';
    font-style: normal;
    color: #9698A1;
    @include m {
        margin-bottom: 30px;
        font-size: 12px; } }

.cyberdogzImg {
    max-height: 600px;
    display: flex;
    margin: 0 auto; }
.container {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    @include m {
        width: 100%; } }
.videoContainer {
    position: relative; }
.play {
    position: absolute;
    top: 41%;
    left: 45%;
    z-index: 10; }
.muteContainer {
    position: absolute;
    top: 40px;
    z-index: 10;
    left: 30%;
    @include d {
        left: 20%; }
    @include t {
        left: 5%; }
    @include m {
        left: 10%; } }
.icon {
    font-size: 50px;
    color: $white;
    z-index: 20; }
.mute {
    font-size: 30px;
    color: $white; }

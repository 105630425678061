@import "../../styles/helpers";
.container {
    margin: 20px auto;
    padding: 0 30px; }

.title {
    // text-align: center
    color: $white;
    // text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary
    font-family: 'ChakraSemiBold';
    font-size: 42px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 600;
    @include d {
        font-size: 24px;
        margin-bottom: 5px; }
    @include m {
        font-size: 14px; } }
.heading {
    text-align: center;
    color: $white;
    // text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary
    font-family: 'ChakraSemiBold';
    font-size: 32px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    @include m {
        font-size: 18px;
        margin-top: 20px; } }
.orangeTitle {
    color: $light-yellow;
    // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange
    // font-family: AnitaSemiSquare
    font-size: 30px;
    margin-bottom: 10px;
    text-transform: uppercase;
    @include m {
        font-size: 20px; } }
.text {
    // text-align: center
    color: $white;
    // text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary
    font-family: 'Poppins';
    // font-size: 16px
    margin-bottom: 20px;
    width: 60%;
    @include d {
        margin-bottom: 5px;
        font-size: 12px;
        width: 80%; }
    @include m {
        font-size: 10px;
        width: 100%; } }
.shade {
    width: 60%;
    display: flex;
    margin: 10px auto;
    height: 15px;
    @include m {
        height: 10px; } }
.registerButton {
    display: flex;
    align-items: center;
    margin: 10px auto;
    padding: 7px 15px;
    border-radius: 1px;
    font-size: 14px;
    font-family: AnitaSemiSquare;
    background-color: transparent;
    box-shadow: #00d8ff -1px 1px 8px;
    color: $neutrals8;
    transition: all .2s;
    background-image: url('../../images/Active.png');
    background-repeat: no-repeat;
    background-size: 101% 101%;
    a {
        color: $white; }
    &:hover {
        color: $white; } }
.content {
    color: #9698A1;
    font-family: 'Poppins';
    width: 50%;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
    font-weight: 500;
    @include m {
        width: 100%;
        font-size: 12px; } }
.close {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 0;
    img {
        height: 37px;
        width: 37px;
        transition: transform .2s;
        img {
            fill: $neutrals8; } }
    &:hover {
        img {
            transform: rotate(90deg); } } }
.modal {
    width: 30%;
    @include t {
        width: 80%; }
    @include m {
        width: 90%; } }
.email {
    width: 35%;
    margin: 26px auto;
    @include t {
        width: 80%; }
    @include m {
        width: 90%; } }
.lotteryBg {
    position: relative; }
.lotteryMask {
    width: 100%;
    height: 450px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 30px;
    @include d {
        height: 300px; }
    @include m {
        height: 150px;
        margin-bottom: 0; } }
.lotteryMask1 {
    position: absolute;
    top: 23%;
    left: 7%;
    @include m {
        width: 90%;
        margin: 0 20px;
        top: 20%;
        left: 0; } }
.registerBtn {
    // border: 1px solid #FFB033
    // background-color: #EE9200
    background-image: url('../../images/buyBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: $white;
    padding: 8px 30px;
    height: fit-content;
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: bold;
    display: block;
    &:hover {
        background-image: url('../../images/opnseaBtn.png');
        color: $dark-yellow; }
    @include m {
        padding: 5px 20px; } }
.downloadFlex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 100px;
    @include w {
        gap: 50px; }
    @include d {
        gap: 20px; }
    @include t {
        flex-direction: column;
        align-items: center;
        gap: 10px; } }
.bg {
    background-image: url('../../images/downloadBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 20px;
    // max-width: 380px
    text-align: center;
    width: 33.33%;
    @include t {
        width: 100%; }
    @include m {
        margin-bottom: 10px; }
    img {
        background: #1E1E1E;
        padding: 10px;
        border-radius: 50%;
        @include m {
            height: 50px; } }
    h2 {
        color: $white;
        font-family: 'ChakraSemiBold';
        font-size: 22px;
        margin-top: 20px;
        font-weight: 600;
        margin-bottom: 0;
        @include m {
            font-size: 14px; } }
    p {
        color: #9698A1;
        font-family: 'Poppins';
        font-size: 12px;
        margin-top: 10px;
        font-weight: 500;
        margin-bottom: 10px;
        @include m {
            font-size: 10px; } } }
.container1 {
    margin: 0 50px;
    background-image: url('../../images/SpaceshooterBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; }

@import "../../styles/helpers";
.claimbutton {
    display: flex;
    width: fit-content; }
.disableButton {
    display: flex;
    width: fit-content;
    cursor: no-drop; }

.label {
    background-image: url('../../images/Buttonbase.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    color: $white;
    font-family: AnitaSemiSquare;
    width: fit-content;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    height: 48px;
    white-space: nowrap;
    min-width: 118px;
    justify-content: center;
    @include t {
        font-size: 12px; } }
.img {
    background-image: url('../../images/buttonicon.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 48px;
    width: 48px; }


.spinner {
    margin-left: 18px;
    margin-top: 3px; }

.disableLabel {
    background-image: url('../../images/Secondarybase.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    color: $white;
    font-family: AnitaSemiSquare;
    width: fit-content;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    height: 48px;
    white-space: nowrap;
    min-width: 118px;
    justify-content: center;
    cursor: no-drop;
    @include t {
        font-size: 12px; } }
.disableImg {
    background-image: url('../../images/secondarybaseicon.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 48px;
    width: 48px; }

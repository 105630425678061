@import "../../../styles/helpers";

.breeding_bg {
    background-image: url('../../../images/Decal.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: block;
    @include m {
        // background-image: none
        background-size: contain;
        background-repeat: repeat;
 } }        // object-fit: cover

.mfimg {
    width: 100%;
    @include m {
        width: 100%; } }

.breeding_content {
    display: flex;
    width: 100%;
    @include m {
        flex-wrap: wrap; } }
.breeding_left {
    width: 60%;
    font-family: AnitaSemiSquare;
    @include m {
        width: 100%; } }
.breeding_right {
    width: 40%;
    color: #ffffff;
    font-family: AnitaSemiSquare;
    @include m {
        width: 100%; } }
.breeding_right_top {
    position: relative;
    text-align: end;
    right: 68px;
    top: 31px;
    @include m {
        position: none;
        text-align: center;
        right: 0;
        top: 0; } }
.breeding_title {

    font-size: 35px;
    color: #FF8A34;
    @include m {
        font-size: 35px;
        color: #FF8A34;
        // visibility: hidden
        display: none;
        float: none;
        text-align: center;
        right: 0;
        left: 0; } }
.breeding_title_top {
    font-size: 35px;
    color: #FF8A34;
    display: none;

    @include m {
        font-size: 35px;
        color: #FF8A34;
        display: block;
        float: none;
        text-align: center;
        font-family: AnitaSemiSquare;
        margin: 0 auto;
        right: 0;
        left: 0; } }
.breedbutton {
    margin: 10px 0; }


.breeding_title2 {
    font-size: 35px;
    color: #FF8A34;
    padding-bottom: 20px;
    @include m {
        font-size: 25px; } }
p {
    font-size: 16px;
    @include m {
        font-size: 11px; } }

.para {
    font-size: 15px;
    text-align: end;
    color: #04DFE5;
    text-shadow: 2px 2px 25px #ffffff;
    @include m {
        position: relative;
        bottom: 10px;
        text-align: center;
        padding: 10px;
        font-size: 10px; } }

.breed_btn {
    background-image: url('../../../images/Default.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: fit-content;
    float: right;
    text-align: end;
    padding: 5px 10px;
    text-align: center;
    box-shadow: #00d8ff -1px 1px 8px;
    white-space: nowrap;
    background-color: #04dbe04f;
    border: 2px solid $secondary;
    border-radius: 2px;
    font-size: 20px;
    cursor: pointer;
    display: block;
    color: $white;
    &:hover {
        color: $white; }
    @include m {
        float: none;
        padding: 5px 10px;
        text-align: center;
        box-shadow: #00d8ff -1px 1px 8px;
        white-space: nowrap;
        background-color: #04dbe04f;
        border: 2px solid #04DFE5;
        border-radius: 2px;
        font-size: 15px;
        cursor: pointer;
        position: none;
        top: 0;
        right: 0;
        margin: 0 auto; }
    a {
        color: $white;
        &:hover {
            color: $white; } } }

.bottom_content {
    margin-top: 16.5rem;
    margin-left: -10rem;
    width: 80%;
    @include m {
        margin-top: 0;
        margin-left: 0;
        width: 100%;
        padding: 20px;
        margin: 0 auto;
        text-align: center; } }
.G1 {
    color: #04DFE5; }
.sub {
    color: #ffffff;
    font-size: 15px;
    top: -1.5em;
    @include m {
        font-size: 10px; } }
.p3 {
    color: #04DFE5; }
.btn {
    margin: 20px 0 40px 0;
    display: flex; }
.required {
    font-size: 30px;
    @include m {
        font-size: 20px; } }


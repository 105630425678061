@import "../../styles/helpers";

.container {
    padding: 0px 30px;
    width: auto;
    margin: 0px auto;
    @include m {
        padding: 0; } }
.heading {
    color: $white;
    font-family: ChakraSemiBold;
    // font-weight: lighter
    font-size: 24px;
    // text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary;
    @include m {
        font-size: 16px; } }
.title {
    color: white;
    font-size: 24px;
    font-family: Poppins;
    padding-bottom: 10px;
    @include m {
        font-size: 16px; } }
.quantity {
    color: white;
    font-family: Poppins;
    padding-bottom: 10px;
    text-align: center; }

.emptyCard {
    // background-image: url('../../images/Diamond/diamond2.png')
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    opacity: 0.2; }
.cardimghide {
    width: 100%;
    opacity: 0.2; }
.cardimg {
    width: 100%; }
.card {
    margin: 20px;
    height: 100%;
    @include m {
        margin: 5px; } }
.cardButton {
    margin-top: 55%;
    height: 100%;
    @include m {
        margin: 20px; } }
.body {
    margin-top: 25%;
    height: 100%;
    @include m {
        margin: 20px; } }
.button {
    margin-top: 20px; }
.text {
    color: $white;
    font-family: Poppins;
    font-weight: lighter;
    font-size: 12px;
    @include m {
        font-size: 10px; } }
.text1 {
    color: $white;
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
    @include m {
        font-size: 12px; } }
.soon {
    margin-top: 200px;
    font-family: Poppins;
    text-align: center;
    color: $white;
    font-size: 24px; }

@import "../../styles/helpers";

.gamewindow {
    width: 100%;
    height: 741px;
    @include t {
        width: 100%;
        height: 500px; } }
.adcard {
    border: 3px solid $primary-orange;
    border-radius: 2px; }
.adimage {
    width: 100%; }
.adbody {
    padding: 20px;
    background-color: #212020;
    position: relative; }
.adlogo {
    height: 50px; }
.adhead {
    display: flex; }
.title {
    color: $white;
    margin-left: 20px;
    font-size: 35px;
    font-family: sans-serif;
    font-weight: 700; }
.adText {
    color: grey;
    font-size: 14px;
    font-weight: 500; }
.couponButton {
    background-color: $blue-light;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    color: $white;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-left: 5px;
    justify-content: center; }
.adbalance {
    position: absolute;
    color: $white;
    font-size: 13px;
    padding: 4px 31px;
    background-color: #443423;
    font-weight: 700;
    right: 0px;
    top: 0px; }
.advertisement {
    height: 300px;
    width: 415px;
    @include m {
        height: auto;
        width: auto; } }


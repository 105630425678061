@import "../../styles/helpers";

.filters {
    flex-shrink: 0;
    width: 220px;
    margin-right: 10px;
    background: #161616;
    padding: 20px;
    height: fit-content;
    @include m {
        width: 100%;
        margin-bottom: 32px; } }
.filters {
    flex-shrink: 0;
    width: 220px;
    margin-right: 10px;
    padding: 20px;
    @include m {
        width: 100%;
        margin-bottom: 32px; } }

.range {
    margin-bottom: 24px; }

.scale {
    display: flex;
    justify-content: space-between;
    font-weight: 500; }

.group {
    margin: 24px 0;
    padding: 24px 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: $border-grey;
    @include dark {
        border-color: $neutrals3; } }

.item {
    &:not(:last-child) {
        margin-bottom: 20px;
        border-bottom: 1px solid $border-grey;
        padding-bottom: 20px; } }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }

.reset {
    display: inline-flex;
    align-items: center;
    color: #9698A1;
    @include button-2;
    font-weight: 100;
    cursor: pointer;
    transition: color .2s;
    font-family: 'Poppins';
    svg {
        margin-right: 8px;
        fill: #9698A1;
        transition: fill .2s; }
    &:hover {
        color: $blue;
        svg {
            fill: $blue; } } }
.dropdown {
        // width: 256px
        margin-right: auto;
        @include t {
            width: 100%;
            margin: 0 0 32px; } }
.gender {
    color: white;
    margin-bottom: 5px; }
.filterbutton {
    // background-image: url('../../images/login.png')
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // padding: 10px 20px
    text-align: center;
    // margin: 0 auto
    // width: 45%
    // font-family: AnitaSemiSquare
    // color: $white
    // font-size: 14px
    transition: color .2s;
    margin-bottom: 24px;
    display: none;
    position: fixed;
    bottom: 20px;
    right: 0px;
    left: 0px;
    z-index: 4;
    cursor: pointer;
    @include m {
        display: block; } }

.visible {
    animation: fadein 1s; }

@keyframes fadein {
    from {
        opacity: 0; }
    to {
        opacity: 1; } }
.gender {
    background-image: url('../../images/selectImg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 6px 18px;
    margin: 0 20px 10px 0px;
    color: #9698A1;
    cursor: pointer;
    font-family: Poppins;
    height: fit-content;
    font-weight: 500; }
.genderActive {
    background-image: url('../../images/SelectActive.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 6px 18px;
    margin: 0 20px 10px 0px;
    color: $white;
    cursor: pointer;
    font-family: Poppins;
    height: fit-content;
    font-weight: 500; }
.genderFlex {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }

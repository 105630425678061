@import "../../styles/helpers";
.homeContainer {
    max-width: 1550px;
    margin: -10px auto; }
.bg {
    height: 100%;
    background-color: #101010;
    // background-image: url('../../images/homebg.png')
    background-repeat: no-repeat;
    background-size: 100% 100%; }

.content {
    padding: 30px 0;
    min-height: 500px;
    position: relative;
    @include m {
        min-height: 200px;
        padding: 0; } }
.dogbg {
    width: 100%; }

.logo {
    width: 65%;
    margin-bottom: 75px; }
.container {
    padding: 0 50px;
    @include m {
        padding: 0 18px; } }
.story {
    color: $white;
    margin-top: 20%;
    @include t {
        margin-top: 0; } }
.modal {
    padding: 0 30px; }

.metamask {
    height: 40px;
    width: 40px;
    margin: 5px 25px; }

.iconimg {
    float: right;
    margin-top: 14px; }
.wallet {
    width: 280px;
    cursor: pointer;
    border: 2px solid $neutrals6;
    border-radius: 12px;
    height: 55px;
    @include dark {
        border-color: $neutrals3; }
    @include t {
        width: 260px; }
    @include m {
        width: 100%;
        margin-bottom: 12px; } }
.banner {
    height: 40px;
    margin-top: 20px;
    background-color: #06E9EF;
    text-align: center;
    align-items: center;
    font-family: AnitaSemiSquare;
    box-shadow: #06e9ef 0px 5px 14px -3px, #06e9ef 0px 4px 4px -2px;
    p {
        margin: 0;
        padding: 10px;
        letter-spacing: 4px;
        color: black; } }
.stake_container {
    margin: 50px 0;
    position: absolute;
    top: 70px;
    @include m {
        padding: 10px 0;
        margin: -6% 0;
        width: max-content; } }

.stake_text {
    text-align: left;
    color: #06E9EF;
    text-shadow: 2px 2px 25px #06E9EF;
    font-family: AnitaSemiSquare;
    font-size: 40px;
    @include t {
        font-size: 14px; }
    @include m {
        list-style-type: none; } }
.levelup_text, .yield_text {
    font-size: 40px;
    text-align: left;
    color: $white;
    text-shadow: 2px 2px 25px $white;
    font-family: AnitaSemiSquare;
    margin: 0 15px;
    @include t {
        font-size: 14px;
        text-align: left; }
    @include m {
        list-style-type: none;
        margin: 0 5px; } }
.stake_mob_screen {
    display: none;
    margin: 0 50px;
    @include m {
        display: block; } }

.stakescreen {
    @include m {
        display: none; } }
.bonez_text {
    color: $primary-orange;
    text-shadow: 2px 2px 25px $primary-orange;
    font-family: AnitaSemiSquare;
    font-size: 40px;
    margin-left: 10px;
    @include t {
        font-size: 16px;
        text-align: left;
        margin-left: 10px; }
    @include m {
        list-style-type: none; } }

.stake_para {
    text-align: left;
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 20px;
    text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary;
    inline-size: 500px;
    overflow-wrap: break-word;
    @include m {
        font-size: 14px;
        inline-size: 100%;
        overflow-wrap: break-word; } }
.stake_head {
    display: flex; }

.asset_card {
    margin: 25px 30px;
    font-family: AnitaSemiSquare; }
.asset_card_top {
    display: flex;
    p {
        color: $white;
        text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
        font-size: 14px;
        margin: 0 16px 0 0;
        @include d {
            font-size: 12px; } }
    img {
        width: 100px; } }
.asset_card_body {
    border: 2px solid #04dbe054;
    padding: 5px;
    margin: 5px 0;
    img {
        width: 100%; } }
.live {
    color: $secondary;
    font-size: 16px;
    font-family: Arial; }
.live_dots {
    margin: 0 5px; }
.breeding {
    margin: 32px; }
.breed_head {
    position: absolute;
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 32px;
    z-index: 1;
    left: 0;
    right: 0;
    text-align: center;
    @include m {
        top: 10%; } }
.breed_text {
    color: #DA0019;
    font-family: Heebo;
    position: absolute;
    bottom: 3%;
    left: 0;
    right: 0;
    text-align: center;
    margin-bottom: 0;
    @include m {
        bottom: 0; } }
.breed_card {
    @include m {
        height: 600px; }
    @include a {
        height: 400px; } }
.landVideo {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-height: 600px; }

.pitbull {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-height: 600px;
    margin-top: -67px;
    position: absolute;
    @include t {
        margin-top: 0; } }
.mission_head {
    color: $secondary;
    text-align: center;
    font-size: 32px;
    font-family: AnitaSemiSquare; }
.mission_collapse, .misson_Chair {
    width: 80%;
    display: flex;
    margin: 24px auto;
    // max-height: 500px
    @include m {
        width: 100%; } }
.planet {
    margin-top: -10rem;
    position: relative;
    @include m {
        margin-top: -6rem; } }
.planetImg {
    width: 100%; }
.palnet_text {
    position: absolute;
    top: 55%;
    bottom: 50%;
    color: #ffffff;
    font-family: AnitaSemiSquare;
    font-size: 18px;
    text-align: end;
    width: 54%;
    right: 50px;
    @include m {
        font-size: 10px;
        right: 30px;
        width: 46%;
        top: 40%; } }

.kibblezText {
    text-align: center;
    font-family: AnitaSemiSquare;
    color: $white;
    font-size: 20px;
    margin: 5px 50px;
    @include m {
        font-size: 14px; } }
.kibblez {
    color: $primary-orange; }
.asset {
    // background-image: url('../../images/carouselshade.png')
    // background-repeat: no-repeat
    // background-size: 100% 100%
    background-image: url('../../images/rightbg.png'), url('../../images/leftbg.png');
    background-position: right bottom, left top;
    background-repeat: no-repeat, no-repeat;
    background-size: 20%,20%;
    padding: 60px 0px 10px 0px;
    @include m {
        background-size: 45%,45%; } }
.button {
    width: fit-content;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    font-family: AnitaSemiSquare;
    letter-spacing: 2px;
    padding: 0 24px;
    color: $white;
    transition: color .2s;
    display: flex;
    color: white;
    background-image: url('../../images/Active.png');
    background-repeat: no-repeat;
    background-size: 101% 100%;
    box-shadow: #00d8ff -1px 1px 8px;
    white-space: nowrap;
    background-color: transparent;
    @include x {
        font-size: 9px;
        padding: 0 14px; }
    @include t {
        margin-left: 0;
        letter-spacing: 1px;
        font-size: 12px; }
    @include m {
        margin-left: 0;
        font-size: 17px;
        line-height: 64px;
        background-color: #04dbe04f;
        font-size: 14px; }
    &:hover,
    &.active {
        color: white;
        background-image: url('../../images/Active.png');
        background-repeat: no-repeat;
        background-size: 101% 100%; } }

.buttondiv {
    margin: 34px auto;
    width: fit-content; }
.startButtondiv {
    margin: 50px 0;
    @include m {
        margin: 12px auto; } }

.startButton {
    width: fit-content;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    font-family: AnitaSemiSquare;
    letter-spacing: 2px;
    padding: 10px 40px;
    color: $white;
    transition: color .2s;
    display: flex;
    color: white;
    background-image: url('../../images/Active.png');
    background-repeat: no-repeat;
    background-size: 101% 100%;
    box-shadow: #00d8ff -1px 1px 8px;
    white-space: nowrap;
    background-color: transparent;
    @include x {
        font-size: 9px;
        padding: 0 14px; }
    @include t {
        margin: 0 auto;
        letter-spacing: 1px;
        font-size: 14px;
        padding: 0 14px; }
    @include m {
        font-size: 17px;
        background-color: #04dbe04f;
        font-size: 14px;
        padding: 0 14px; }
    &:hover,
    &.active {
        color: white;
        background-image: url('../../images/Active.png');
        background-repeat: no-repeat;
        background-size: 101% 100%; } }

.spaceship {
    position: relative;
    margin-bottom: 30px;
    @include m {
        position: inherit;
        margin-bottom: 0; } }
.spaceshipImg {
    width: 100%;
    background-color: $primary;
    @include m {
        margin: 0;
        display: none; } }
.spaceshipImgMob {
    display: none;
    @include m {
        display: block;
        width: 100%; } }
.spaceship_text {
    position: absolute;
    font-family: AnitaSemiSquare;
    font-size: 24px;
    text-align: end;
    top: 20px;
    right: 30px;
    @include m {
        font-size: 16px;
        text-align: center;
        position: inherit;
        padding: 30px 0; }
    span:nth-child(6) {
        color: $white;
        font-family: HeeboThin;
        font-size: 14px; } }
.spaceship_bottom_text {
    position: absolute;
    font-family: AnitaSemiSquare;
    font-size: 24px;
    bottom: 50px;
    left: 30px;
    width: 100%;
    @include m {
        font-size: 16px;
        text-align: center;
        position: inherit;
        padding: 30px 0; } }
.mintButton {
    width: fit-content;
    font-size: 34px;
    font-weight: 400;
    line-height: 40px;
    font-family: AnitaSemiSquare;
    letter-spacing: 2px;
    padding: 13px 40px;
    color: $white;
    display: flex;
    box-shadow: #00d8ff -1px 1px 8px;
    white-space: nowrap;
    background-color: #04dbe04f;
    border: 2px solid $secondary;
    border-radius: 2px;
    margin-left: auto;
    margin-right: 60px;
    margin-top: 20px;
    text-shadow: 2px 2px 25px $secondary;
    @include m {
        margin: 16px auto 0 auto;
        font-size: 16px;
        padding: 8px 32px; } }

.orange {
    color: $primary-orange;
    text-shadow: 2px 2px 25px $primary-orange; }
.white {
    color: $white;
    text-shadow: 2px 2px 25px $white; }
.blue {
    color: $secondary;
    text-shadow: 2px 2px 25px $secondary; }
.mintModal {
    width: 70%;
    @include m {
        width: 95%; } }
.wallethead {
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 32px;
    @include m {
        font-size: 20px; } }
.walletText {
    color: $white;
    font-family: Heebo;
    font-size: 16px;
    @include m {
        font-size: 12px; } }
.shadowBg {
    background-image: url('../../images/shadowBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; }
.winnersBg {
    background-image: url('../../images/activityBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; }

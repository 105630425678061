@import "../../../styles/helpers";

.winner {
    padding: 20px 30px;
    position: relative; }
.card {
    background-image: url('../../../images/stockborder.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 10px auto;
    position: relative;
    height: fit-content; }
.stockbg {
    position: absolute;
    z-index: 1;
    bottom: 0;
    height: 34%; }

.previewImage {
    width: 100%;
    padding: 2px;
    border-radius: 2px;
    min-height: 50px;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(-100% 0%, 0% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 100%, 0% 100%, 63% 101%); }

.heading {
    color: $white;
    font-family: ChakraSemiBold;
    font-size: 32px;
    margin-bottom: 10px;
    margin-left: 110px;
    @include m {
        margin-left: 0;
        font-size: 18px; } }

.text {
    font-weight: 500;
    font-family: 'Poppins';
    font-style: normal;
    color: #9698A1;
    font-size: 12px; }
.amount {
    font-weight: 700;
    font-family: 'Poppins';
    font-style: normal;
    color: $white;
    font-size: 14px; }
.date {
    font-weight: 400;
    font-size: 12px;
    font-family: 'Poppins';
    font-style: normal;
    color: $white; }
.stakeImg {
    width: 100%; }
.container {
    margin: 10px; }
.prevArrow {
    background-image: url('../../../images/slickFrwdImg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 4px 0px;
    svg {
        color: #9698A1;
        margin-left: -3px;
        &:hover {
            color: $white; } } }
.nextArrow {
    background-image: url('../../../images/slickBcwdImg..png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 4px 0px;
    svg {
        color: #9698A1;
        margin-left: -3px;
        &:hover {
            color: $white; } } }

@import "../../../styles/helpers";

.user {
    position: relative;
    width: fit-content !important;
    @include m {
        position: static;
 } }        // display: none

.head {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px 38px;
    @include button-2;
    cursor: pointer;
    transition: box-shadow .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    background-image: url('../../../images/walletBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 32px;
    white-space: nowrap;
    font-size: 16px;
    @include m {
        padding: 0;
        border-radius: 0;
        box-shadow: none; } }

.avatar {
    width: 32px;
    cursor: pointer;
    flex-shrink: 0;
    height: 32px;
    margin-right: 20px;
    @include m {
        width: 32px;
        height: 32px;
        position: absolute;
        right: 0px;
        bottom: 17px; }
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%; } }
.title {
    color: #EE9200;
    font-size: 24px;
    font-weight: 400;
    font-family: AnitaSemiSquare;
    margin-right: 100px; }
.walletname {
    color: $white;
    font-family: AnitaSemiSquare;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
    @include m {
        display: none; } }

.currency {
    color: $green; }

.body {
    position: absolute;
    z-index: 1000;
    top: calc(100% + 10px);
    left: 0;
    width: 154px;
    margin-left: -54px;
    box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);
    border-radius: 1px;
    background: #0d2236e0;
    opacity: 0;
    transform: scale(.9);
    transform-origin: 50% 50px;
    will-change: transform;
    color: $white;
    // border: 0.2px solid $secondary
    animation: showUser .4s forwards;
    display: grid;
    justify-content: center;
    @include t {
        left: auto;
        right: 0; }
    @include m {
        right: 16px;
        top: 59px;
        margin: 0 auto; }
    &:before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 32px;
        height: 13px;
        transform: translateX(-50%);
        @include m {
            display: none; } } }

.stickBody {
    position: absolute;
    z-index: 1000;
    top: calc(100% + 20px);
    left: 50%;
    width: 207px;
    margin-left: -101px;
    padding: 32px 16px 20px;
    box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);
    border-radius: 12px;
    background: #0d2236e0;
    opacity: 0;
    transform: scale(.9);
    transform-origin: 50% 50px;
    will-change: transform;
    color: $white;
    border: 0.2px solid #ffffff61;
    animation: showUser .4s forwards;
    @include t {
        left: auto;
        right: 0; }
    @include m {
        left: 16px;
        right: 16px;
        top: calc(100% + 16px);
        width: auto;
        margin: 0; }
    &:before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 32px;
        height: 13px;
        transform: translateX(-50%);
        background: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A") no-repeat 100% 50% / 100% auto;
        @include m {
            display: none; } }
    @include dark {
        background: $neutrals2;
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%2323262F'/%3E%3C/svg%3E%0A"); } } }

@keyframes showUser {
    0% {
        opacity: 0;
        transform: scale(.9); }
    100% {
        opacity: 1;
        transform: scale(1); } }

.name {
    @include body-bold-1; }

.code {
    display: flex;
    align-items: center;
    margin-bottom: 8px; }

.number {
    font-weight: 500;
    color: $white;
    font-family: AnitaSemiSquare; }

.copy {
    margin-left: 8px;
    svg {
        fill: $secondary;
        transition: fill .2s; } }

.wrap {
    box-shadow: 0px 24px 24px -8px rgba(15, 15, 15, 0.2); }

.line {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }

.preview {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%; } }

.info {
    @include caption-2;
    color: $neutrals4; }

.price {
    font-family: AnitaSemiSquare;
    font-weight: bold;
    font-size: 18px; }

.button {
    width: 40%;
    height: 40px;
    padding: 0 10px;
    font-size: 14px;
    margin: 10px auto; }
.btns {
    text-align: center; }
.item {
    display: flex;
    align-items: center;
    padding: 10px 0 10px;
    @include button-2;
    color: $neutrals4;
    &:hover {
        color: $blue;
        svg {
            fill: $secondary; }
        .text {
            text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary; } } }

.icon {
    flex-shrink: 0;
    width: 20px;
    margin-right: 8px;
    svg {
        fill: $white;
        transition: fill .2s;
        text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange; } }
.icons {
    width: 25px;
    @include m {
        width: 20px; } }

.text {
    margin-right: auto; }

.wallet {
    width: 280px;
    cursor: pointer;
    border: 2px solid $neutrals6;
    border-radius: 12px;
    height: 55px;
    margin: 0 auto;
    @include dark {
        border-color: $neutrals3; }
    @include t {
        width: 260px; }
    @include m {
        width: 100%;
        margin-bottom: 12px; } }

.metamask {
    height: 40px;
    width: 40px;
    margin: 5px 25px; }

.iconimg {
    float: right;
    margin-top: 14px; }

.item {
    display: flex; }

.register {
    margin-top: 32px;
    margin-bottom: 16px; }

.alert {
    color: #f44336;
    margin: -20px 0 5px 15px; }

.field {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include m {
            margin-bottom: 20px; } } }

.cursor {
    cursor: pointer; }
.wallethead {
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 32px;
    text-align: center;
    text-shadow: 2px 2px 25px $white;
    @include m {
        font-size: 20px; } }
.text {
    color: $white;
    font-family: Heebo;
    font-size: 16px;
    margin-left: 4px;
    font-family: AnitaSemiSquare;
    font-weight: lighter;
    @include m {
        font-size: 12px; } }
.outermodal {
    width: 35%;
    @include m {
        width: 90%; } }

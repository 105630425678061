@import "../../styles/helpers";

.section {
    overflow: hidden;
    margin-top: 20px; }

.wrapper {
    position: relative; }

.title {
    margin-bottom: 64px;
    font-family: 'ChakraSemiBold';
    // text-align: center
    font-size: 32px;
    color: $white;
    margin-bottom: 0;
    font-weight: 600;
    @include dark {
        color: $neutrals7; } }

.inner {
    margin: 0 -16px;
    @include m {
        margin: 0 -8px; } }

.card {
    width: 100%; }
.shade {
    display: flex;
    margin: 5px auto; }
.similar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-bottom: 30px;
    @include t {
        width: 70%; }
    @include m {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 10px; } }
.prevArrow {
    background-image: url('../../images/slickFrwdImg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 4px 0px;
    svg {
        color: #9698A1;
 } }        // margin-left: -3px
.nextArrow {
    background-image: url('../../images/slickBcwdImg..png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 4px 0px;
    svg {
        color: #9698A1;
 } }        // margin-left: -3px

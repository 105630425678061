@import "../../styles/helpers";
.content {
    margin: 0 3%; }
.bg {
    height: 200%; }
.welcome {
    font-family: FF5733;
    color: $white;
    font-size: 36px;

    margin-top: 35px;
    margin-bottom: 35px;
    text-align: center; }
.welcome1 {


    align: center;
    justify-content: center;
    align-items: center;
    font-family: FF5733; }

.welcome2 {
    background-color: #2B5466;
    align: center;
    color: #FFC300 !important;
    justify-content: center;
    align-items: center;
    font-family: FF5733; }
.title {
    margin-bottom: 64px;

    color: $white;
    font-size: 32px;
    font-family: ChakraSemiBold;
    @include m {
        margin-bottom: 18px; } }
.paragraph {

    justify-content: center;
    align-items: center;
    font-family: Heebo !important; }
.store {
    color: $white;
    font-weight: 500;
    font-family: Poppins !important;
    // padding-left: 5%
    font-size: 18px;
    padding: 20px;
    @include m {
        font-size: 16px;
        font-family: Poppins !important; } }
.grop {
    font-family: Poppins !important;
    color: #9698A1;
    font-size: 16px !important;
    text-align: justify;
    padding: 30px 20px;
    @include m {
        font-size: 12px !important;
        font-family: Poppins !important;
        padding: 20px; } }
.faqFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $border-grey;
    svg {
        color: $white;
        font-size: 22px;
        margin-right: 20px;
        cursor: pointer; } }
.faqFlex1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $border-grey;
    border-bottom: 1px solid $border-grey;
    svg {
        color: $white;
        font-size: 22px;
        margin-right: 20px;
        cursor: pointer; } }

@import "../../../styles/helpers";

.container {
    padding: 0;
    margin-top: 10px; }

.head {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 326px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;
    @include t {
        height: 260px;
        padding-bottom: 64px; }
    @include m {
        height: 230px;
        padding-bottom: 56px; }
    .container {
        display: flex;
        justify-content: flex-end; }
    &.active {
        .btns {
            opacity: 0; }
        .file {
            opacity: 1;
            visibility: visible; } } }

.title {
    color: white;
    font-size: 32px;
    // text-align: center
    font-family: 'ChakraSemiBold';
    // letter-spacing: 4px
    margin-bottom: 0;
    @include m {
        font-size: 18px; } }
.cyberdogzText {
    color: white;
    font-size: 32px;
    // text-align: center
    font-family: 'ChakraSemiBold';
    // letter-spacing: 5px
    // text-shadow: 2px 2px 25px $white
    font-weight: 600;
    @include m {
        font-size: 18px; } }
.raceText {
    color: #9698A1;
    font-size: 12px;
    font-family: 'Poppins';
    height: 100%;
    // align-items: center
    // display: flex
    text-align: center; }
.raceDay {
    padding: 1px 5px;
    width: fit-content;
    border-radius: 50px;
    font-weight: 500;
    margin: 0 auto; }
.sub_title {
    color: white;
    margin-left: 32px;
    font-size: 16px;
    font-family: AnitaSemiSquare;
    @include m {
        font-size: 15px; } }

.heading {
    color: $white;
    font-family: AnitaSemiSquare;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange; }

.wrap {
    position: relative;
    z-index: 2; }
.wrapper {
    flex: 0 0 calc(110% - 256px);
    width: calc(100% - 256px);
    padding-left: 64px;
    @include x {
        padding-left: 32px; }
    @include t {
        width: 100%;
        padding-left: 0; } }

.nav {
    display: flex;
    margin: 0 -6px 0px;
    @include m {
        margin: 0 -32px 0px;
        overflow: auto;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none; }
        &:before,
        &:after {
            content: "";
            flex-shrink: 0;
            width: 32px;
            height: 1px; } } }

.link {
    flex-shrink: 0;
    margin: 0 6px;
    padding: 10px 30px;
    border-radius: 1px;
    font-family: AnitaSemiSquare;
    background-color: #04e0d13f;
    white-space: nowrap;
    border: 1px solid $neutrals4;
    text-shadow: 0 0 1em #00d8ff, 0 0 2em #00d8ff, 0 0 2em #00d8ff;
    // +button-2
    color: $neutrals8;
    transition: all .2s;
    @include m {
        margin: 0; }
    &:hover, &.active {
        color: white;
        background-image: url('../../../images/Active.png');
        background-repeat: no-repeat;
        background-size: 101% 101%;
        text-shadow: none;
        background-color: transparent;
        // border: 1px solid#00d8ff
        box-shadow: #00d8ff -1px 1px 8px; }
    &:not(:last-child) {
        @include m {
            margin-right: 12px; } } }

.item {
    overflow-x: auto; }
.history_head {
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 24px; }
.history_table {
    margin: 24px 0;
    width: auto;
    overflow: auto;
    padding-bottom: 5px;
    @include m {
        width: 1400px;
        margin: 0;
        padding-bottom: 0; }
    @include a {
        width: 1400px;
        margin: 0;
        padding-bottom: 0; } }
.rowdata {
    padding: 20px;
    border-bottom: 1px solid $border-grey;
    &:hover {
        background: #161616; }
    &:nth-child(1) {
        border-top: 1px solid $border-grey;
        &:hover {
            background: none; } } }
.rowdatabg {
    background-image: url('../../../images/historybg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 5px;
    padding: 5px 20px 5px 0; }
// .rowdatabg:first-child
//     padding-left: 10px
.rowdatabgLoader {
    background-image: url('../../../images/historybg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 5px;
    height: 45px; }
.rowLoader {
    background-image: linear-gradient(to right,$primary-blue 0%, #085568 20%, $primary-blue 40%,$primary-blue 100%);
    animation: shine-lines 2.0s infinite ease-out;
    box-shadow: #085568 0px 30px 60px -12px inset, #085568 0px 18px 36px -18px inset;
    width: 100%;
    height: 100%; }
@keyframes shine-lines {
    0% {
        background-position: -350px 0px; }
    100% {
        background-position: 350px 0px; } }

.buyer,.seller {
    color: $white;
    font-family: 'Poppins' !important;
    font-weight: 500;
    // letter-spacing: 2px
    margin-top: 5px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: center;
    height: 35px;
 }    // text-shadow: 0 0 1em #00d8ff, 0 0 2em #00d8ff, 0 0 2em #00d8ff

.pricefield {
    font-family: 'Poppins';
    font-size: 16px;
    color: #9698A1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    padding-top: 8px;
    height: 40px;
    text-align: center;
    a {
        color: $white; } }
.winfield {
    font-family: 'Poppins';
    font-size: 16px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    padding-top: 8px;
    height: 40px;
    text-align: center;
    a {
        color: $white; } }
.history_shade {
    width: 100%;
    height: 12px;
    display: flex;
    margin: 0 auto; }
.status, .countdown div {
    font-family: 'Poppins';
    font-size: 14px;
    color: #9698A1;
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: center;
    height: 40px; }
.statusName {
    font-family: 'Poppins';
    font-size: 14px;
    color: $white;
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: center;
    height: 40px;
    font-weight: 500; }
.hash {
    font-family: 'Poppins';
    font-size: 14px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: center;
    height: 40px;
    a {
        color: $white;
        text-align: center; } }

.loader {
    margin: 70px auto; }
.empty {
    margin: 70px auto;
    color: $white;
    font-family: 'Poppins';
    text-align: center; }
.openicon {
    color: $white;
    font-size: 15px;
    cursor: pointer; }
.icon {
    height: 20px;
    margin-right: 5px; }
.style {
    width: 5px !important;
    height: 117% !important;
    margin-top: -4px; }
.dogIcon {
    height: 40px;
    margin: 0 25px; }
.KibblezTable {
    margin-right: 15px;
    @include m {
        margin-right: 0; } }
.bonezTable {
    margin-left: 15px;
    @include m {
        margin-left: 0; } }
.places {
    color: $primary-orange;
    font-weight: bold;
    font-style: italic;
    font-size: 18px;
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: center;
    height: 40px; }
.viewButton {
    flex-shrink: 0;
    // margin: 1px auto
    padding: 5px 15px;
    border-radius: 1px;
    font-size: 14px;
    font-family: 'Poppins';
    background-color: transparent;
    white-space: nowrap;
    // box-shadow: #00d8ff -1px 1px 8px
    // +button-2
    color: $neutrals8;
    transition: all .2s;
    background-image: url('../../../images/viewBtn.png');
    background-repeat: no-repeat;
    background-size: 101% 101%;
    display: flex;
    width: fit-content;
    font-weight: 600;
    &:hover {
        color: $white; }
    a {
        &:hover {
            color: $white; } } }
.playButton {
    flex-shrink: 0;
    border-radius: 1px;
    font-size: 14px;
    font-family: 'Poppins';
    background-color: transparent;
    white-space: nowrap;
    // box-shadow: #00d8ff -1px 1px 8px
    color: $white;
    transition: all .2s;
    background-image: url('../../../images/viewBtn.png');
    background-repeat: no-repeat;
    background-size: 101% 101%;
    display: flex;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-left: 8px;
    padding: 0 10px;
    height: 32px;
    font-weight: 600;
    &:hover {
        color: $white; }
    a {
        &:hover {
            color: $white;
            height: 32px; } } }

.betButton {
    flex-shrink: 0;
    margin: 1px auto;
    padding: 5px 15px;
    border-radius: 1px;
    font-size: 14px;
    font-family: AnitaSemiSquare;
    background-color: #00d9ff14;
    white-space: nowrap;
    box-shadow: #00d8ff -1px 1px 8px;
    // +button-2
    color: $neutrals8;
    text-shadow: 2px 2px 25px $secondary;
    transition: all .2s;
    border: 2px solid $secondary;
    display: flex; }
.modal {
    width: 80% !important;
    max-width: 90% !important;
    @include t {
        width: 90% !important; } }
.badge {
    width: fit-content;
    font-family: 'Poppins';
    color: black;
    background: linear-gradient(90deg, $dark-yellow 0%, $light-yellow 100%);
    border-radius: 50px;
    padding: 3px 12px;
    font-size: 12px;
    font-weight: 600;
    display: flex !important;
    justify-content: center;
    align-items: center;
    svg {
        margin-right: 5px;
        font-size: 14px;
        margin-top: -2px; } }
.endBadge {
    width: fit-content;
    font-family: 'Poppins';
    color: $white;
    background: #EB5757;
    border-radius: 50px;
    padding: 3px 12px;
    font-size: 12px;
    font-weight: 600; }
.freebadge {
    display: flex !important;
    justify-content: center;
    align-items: center; }
.freebadgeDiv {
    width: fit-content;
    font-family: 'Poppins';
    color: black;
    background: linear-gradient(90deg, $dark-yellow 0%, $light-yellow 100%);
    border-radius: 50px;
    padding: 3px 12px;
    font-size: 12px;
    font-weight: 600; }
.joinContainer {
    display: flex;
    align-items: center;
    justify-content: center; }
.header {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
.switchdesign {}
.switch {
    margin-left: auto;
    color: $white;
    font-family: 'Poppins';
    font-weight: lighter;
    font-weight: 500; }

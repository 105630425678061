@import "../../styles/helpers";

.containner {
    padding: 0; }

.head {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 326px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;
    @include t {
        height: 260px;
        padding-bottom: 64px; }
    @include m {
        height: 230px;
        padding-bottom: 56px; }
    .container {
        display: flex;
        justify-content: flex-end; }
    &.active {
        .btns {
            opacity: 0; }
        .file {
            opacity: 1;
            visibility: visible; } } }

.title {
    color: white;
    font-size: 28px;
    font-family: AnitaSemiSquare;
    @include m {
        font-size: 15px; } }

.sub_title {
    color: white;
    margin-left: 32px;
    font-size: 16px;
    font-family: AnitaSemiSquare;
    @include m {
        font-size: 15px; } }

.heading {
    color: $white;
    font-family: AnitaSemiSquare;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange; }


.wrap {
    position: relative;
    z-index: 2; }
.wrapper {
    flex: 0 0 calc(110% - 256px);
    width: calc(100% - 256px);
    padding-left: 64px;
    @include x {
        padding-left: 32px; }
    @include t {
        width: 100%;
        padding-left: 0; } }

.nav {
    display: flex;
    margin: 0 -6px 32px;
    @include m {
        margin: 0 -32px 32px;
        overflow: auto;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none; }
        &:before,
        &:after {
            content: "";
            flex-shrink: 0;
            width: 32px;
            height: 1px; } } }

.link {
    flex-shrink: 0;
    margin: 0 6px;
    padding: 16px 30px;
    border-radius: 1px;
    font-family: AnitaSemiSquare;
    background-color: #04e0d13f;
    white-space: nowrap;
    border: 1px solid $neutrals4;
    text-shadow: 0 0 1em #00d8ff, 0 0 2em #00d8ff, 0 0 2em #00d8ff;
    // +button-2
    color: $neutrals8;
    transition: all .2s;
    @include m {
        margin: 0; }
    &:hover, &.active {
        color: white;
        background-image: url('../../images/Active.png');
        background-repeat: no-repeat;
        background-size: 101% 101%;
        text-shadow: none;
        background-color: transparent;
        border: 1px solid#00d8ff;
        box-shadow: #00d8ff -1px 1px 8px; }
    &:not(:last-child) {
        @include m {
            margin-right: 12px; } } }



.item {
    overflow-x: auto; }
.history_head {
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 24px; }
.history_table {
    background-color: $model;
    margin: 24px 0;
    border: 1px solid #20668b;
    width: auto;
    overflow: auto;
    padding-bottom: 5px;
    @include m {
        width: 800px; }
    @include a {
        width: 600px; } }
.rowdata {
    background-color: $model;
    // margin-bottom: 5px
    padding: 13px 20px; }
.rowdatabg {
    background-image: url('../../images/historybg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // margin-bottom: 5px
    padding: 13px 20px; }
.rowdatabg:first-child {
    padding-left: 10px; }
.buyer,.seller {
    color: $white;
    font-family: AnitaSemiSquare !important;
    letter-spacing: 2px;
    margin-top: 5px;
    text-align: center; }
.pricefield {
    font-family: HeeboThin;
    font-size: 14px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    a {
        color: $white; } }
.history_shade {
    width: 100%;
    height: 12px;
    display: flex;
    margin: 0 auto; }
.status {
    font-family: HeeboBold;
    font-size: 14px;
    color: $white;
    text-align: center; }
.hash {
    font-family: HeeboBold;
    font-size: 14px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    a {
        color: $white; } }

.loader {
    margin: 70px auto; }
.empty {
    margin: 70px auto;
    color: $white;
    font-family: 'Poppins';
    text-align: center; }
.icon {
    height: 25px;
    width: 25px;
    margin: 0 3px; }

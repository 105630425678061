@import "../../styles/helpers";

.dropdown {
    position: relative;
    z-index: 3;
    &.active {
        z-index: 10;
        .head {
            .arrow {
                svg {
                    transform: rotate(180deg); } } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.head {
    position: relative;
    height: 48px;
    padding: 0 48px 0 16px;
    border-radius: 3px;
    border: 1px solid #20668b;
    background-color: $primary-blue;
    font-size: 14px;
    font-weight: 500;
    line-height: 48px;
    cursor: pointer;
    color: $white;
    // -webkit-tap-highlight-color: rgba(0,0,0,0)
    transition: box-shadow .2s;
    @include dark {
 }        // box-shadow: inset 0 0 0 2px $neutrals3
    &:hover {
 } }        // box-shadow: inset 0 0 0 2px $neutrals4

.selection {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: lowercase; }
.selection::first-letter {
    text-transform: uppercase; }

.arrow {
    position: absolute;
    top: 50%;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #20668b;
    transform: translateY(-50%);
    svg {
        fill: #EE9200;
        transition: transform .2s; }
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3;
        svg {
            fill: #EE9200; } } }

.body {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    padding: 8px 0;
    border-radius: 3px;
    background: $neutrals8;
    // box-shadow: inset 0 0 0 2px $neutrals6, 0 4px 12px rgba($neutrals2, .1)
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    border: 1px solid #20668b;
    background-color: $primary-blue;
    max-height: 400px;
    overflow-x: auto; }
.body::-webkit-scrollbar {
    display: none; }


.option {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    transition: color .2s;
    text-transform: lowercase;
    cursor: pointer;
    -webkit-tap-highlight-color: $white;
    color: $white;
    &:hover,
    &.selectioned {
        color: #EE9200; } }
.option::first-letter {
    text-transform: uppercase; }
.icons {
    height: 40px;
    width: 40px;
    border-radius: 5px; }

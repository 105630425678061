@import "../../styles/helpers";

.tab {
    height: 40px;
    width: 100%;
    background: #1E1E1E;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none; }
    @include m {
        justify-content: start; } }
.menu {
    color: #9698A1;
    font-family: 'Poppins';
    font-style: normal;
    padding: 6px 24px 7px 24px;
    border-bottom: 3px solid #3F3800;
    margin-bottom: 0;
    font-weight: 300;
    transition: all .5s;
    white-space: nowrap;
    /* margin-top: 19px; */
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
        font-size: 20px;
        margin-right: 10px; }
    &:hover {
        color: $white;
        border-bottom: 3px solid #FDC22E; } }

.selectedMenu {
    font-family: 'Poppins';
    font-style: normal;
    padding: 6px 20px 7px 20px;
    margin-bottom: 0;
    font-weight: 300;
    transition: all .5s;
    white-space: nowrap;
    /* margin-top: 19px; */
    display: flex;
    color: $white;
    border-bottom: 3px solid #FDC22E;
    cursor: pointer;
    svg {
        font-size: 20px;
        margin-right: 10px; } }

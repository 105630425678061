@import "../../../styles/helpers";

.breeding {
    max-width: 1550px;
    margin: -10px auto;
    padding: 30px;
    @include m {
        padding: 5px 30px; } }
.top, .info {
    position: relative; }
.banner {
    width: 100%;
    border-radius: 30px;
    height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
    @include m {
        height: 150px; } }
.heading {
    color: $white;
    font-size: 32px;
    font-family: 'ChakraSemiBold';
    margin-bottom: 10px;
    @include m {
        font-size: 24px; } }
.subHead {
    color: $white;
    font-family: 'Poppins';
    font-weight: 500; }
.topContainer {
    position: absolute;
    top: 30%;
    left: 7%;
    @include m {
        top: 10%; } }
.info {
    height: 150px; }
.dotsRight {
    height: 100px;
    float: right;
    @include m {
        width: 40%; } }
.dotsLeft {
    height: 100px;
    float: left;
    @include m {
        width: 40%; } }
.breedText {
    color: #9698A1;
    font-size: 14px;
    font-family: Poppins;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center; }
.information {
    font-size: 30px;
    font-family: ChakraSemiBold;
    color: $white;
    position: absolute;
    left: 0;
    right: 0;
    top: 34px;
    text-align: center;
    @include m {
        font-size: 16px; }
    span {
        color: #FDC22E; } }

.genesis {
    margin: 30px;
    @include m {
        margin: 5px; } }
.body {
    border: 1px solid #2F2F2F; }
.gender {
    border: 1px solid #2F2F2F;
    color: $white;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 10px 0;
    &:hover {
        background-color: #2F2F2F; }
    @include m {
        font-size: 14px;
        padding: 5px 0; } }
.assetImage {
    width: 100%;
    padding: 2px;
    clip-path: polygon(-90% 0%, 0% 0%, 100% 0%, 100% 0%, 91% 0%, 203% 100%, 0% 100%, 9% 100%); }

.genderContainer {
    margin: 5px 0;
    transition-timing-function: linear;
    overflow-y: scroll !important;
    height: fit-content;
    max-height: 400px;
    // height: 400px
    -webkit-overflow-scrolling: touch;
    margin-right: 3px; }
.genderContainer::-webkit-scrollbar {
    width: 2px; }
.genderContainer::-webkit-scrollbar-thumb {
    background: #2F2F2F;
    border-radius: 2px; }
.genderContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 2px; }
.letterSpace {
    letter-spacing: -12px;
    display: contents;
    @include m {
        letter-spacing: -9px; } }
.breedBox {
    width: 100%;
    pointer-events: none; }
.breedCenter {
    width: 88%;
    margin-top: -51%;
    padding-bottom: 24px;
    /* margin: 6px; */
    margin-left: 6px;
    margin-right: 10px;
    pointer-events: none; }
.breedCard {
    background: linear-gradient(to right, grey 4px, transparent 4px) 0 0,
    linear-gradient(to left, grey 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, grey 4px, transparent 4px) 0 0,
    linear-gradient(to top, grey 4px, transparent 4px) 100% 100% {}
    background-repeat: no-repeat;
    background-size: 20px 20px;
    height: 150px;
    width: 100%;
    background-color: black;
    // border: 2px solid $secondary
    box-shadow: 0 0 0 2px black,0 0 0 4px $secondary, 0 0 0 6px black;
    @include m {
        height: 100%; } }
.breedButton {
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: center; }
.noData {
    color: $white;
    font-family: Poppins;
    margin: 50px 10px;
    text-align: center; }
.loader {
    margin: 20px auto;
    width: 30%; }
.note {
    text-align: center;
    font-family: AnitaSemiSquare;
    color: white;
    font-size: 12px;
    span {
        color: $secondary;
        text-decoration: underline;
        font-weight: 600;
        font-size: 14px; } }
.description {
    font-size: 12px;
    font-family: AnitaSemiSquare;
    color: white;
    @include m {
        font-size: 10px; } }
.modal {
    width: 40%;
    @include d {
        width: 70%; }
    @include m {
        width: 90%; } }
.icon {
    height: 25px;
    margin: 0 5px; }
.price {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    color: #9698A1;
    text-align: center;
    width: fit-content;
    margin-left: 50%;
    @include m {
        font-size: 14px;
        margin: 0 auto; } }
.breedingFee {
    font-family: Poppins;
    font-size: 16px;
    color: #9698A1;
    text-align: center;
    margin: 10px;
    @include m {
        font-size: 16px;
        display: none; } }
.pricemob {
    font-family: Poppins;
    font-size: 16px;
    color: #9698A1;
    text-align: center;
    margin: 10px;
    display: none;
    @include m {
        font-size: 16px;
        display: block; } }
.amount {
    color: $white;
    font-family: Poppins;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin: 0 5px;
    @include m {
        font-size: 18px; } }
.error {
    color: #f13838;
    text-align: center;
    margin-top: 5px;
    font-weight: 600;
    font-family: Poppins; }
.placeholder {
    color: grey;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }
.hash {
    text-align: center;
    color: white;
    margin-bottom: 5px;
    font-size: 14px;
    font-family: Poppins;
    @include m {
        font-size: 12px; } }
.success {
    text-align: center;
    color: #27AE60;
    margin-bottom: 5px;
    font-size: 14px;
    font-family: Poppins;
    @include m {
        font-size: 12px; } }
.importantNote {
    font-family: Poppins;
    color: $white;
    font-size: 14px;
    span {
        color: $purple;
        text-decoration: underline;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        display: block; } }
.farm {
    width: 100%;
    margin-left: -6px; }
.card {
    position: relative;
    height: 100%; }
.maleCard, .femaleCard {
    width: 100%;
    position: absolute;
    z-index: 1; }
.maleCard {
    top: 13%; }
.femaleCard {
    bottom: 0; }
.drop {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9698A1;
    text-align: center;
    @include m {
        font-size: 10px; } }
.previewImage {
    width: 100%;
    clip-path: polygon(-100% 0%, 0% 0%, 100% 0%, 100% 0%, 94% 0%, 174% 100%, 0% 100%, 8% 100%); }
.count {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    border-radius: 50%;
    color: black;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include m {
        font-size: 10px;
        height: 22px;
        width: 22px; } }
.assetSelected {
    background: url('../../../images/activeBreedBorder.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; }

.genderCard {
    background: url('../../../images/breedImgBorder.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 2px;
    margin: 2px;
    &:hover {
        background: url('../../../images/activeBreedBorder.png');
        background-repeat: no-repeat;
        background-size: 100% 100%; } }
.button {
    width: 100%;
    margin: 10px;
    button {
        width: 100%;
        span {
            width: 100%; } } }
.title {
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    margin-bottom: 5px;
    @include m {
        font-size: 10px; } }
.buyButton {
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    padding: 5px 15px;
    color: black;
    border-radius: 5px;
    font-family: Poppins;
    font-weight: 500;
    margin-top: 5px; }

@import "../../../styles/helpers";
.container {
    margin: 20px auto; }

.title {
    text-align: center;
    color: $white;
    text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary;
    font-family: AnitaSemiSquare;
    font-size: 30px;
    margin-bottom: 20px;
    text-transform: uppercase;
    @include m {
        font-size: 20px; } }
.orangeTitle {
    color: $white;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
    font-family: AnitaSemiSquare;
    font-size: 30px;
    margin-bottom: 10px;
    text-transform: uppercase;
    @include m {
        font-size: 20px; } }
.text {
    text-align: center;
    color: $white;
    text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary;
    font-family: AnitaSemiSquare;
    font-size: 16px;
    margin-bottom: 10px;
    @include m {
        font-size: 14px; } }
.shade {
    width: 60%;
    display: flex;
    margin: 10px auto;
    height: 15px;
    @include m {
        height: 10px; } }
.registerButton {
    display: flex;
    margin: 10px auto;
    padding: 7px 15px;
    border-radius: 1px;
    font-size: 14px;
    font-family: AnitaSemiSquare;
    background-color: transparent;
    box-shadow: #00d8ff -1px 1px 8px;
    color: $neutrals8;
    transition: all .2s;
    background-image: url('../../../images/Active.png');
    background-repeat: no-repeat;
    background-size: 101% 101%;
    a {
        color: $white; }
    &:hover {
        color: $white; } }

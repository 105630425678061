@import "../../styles/helpers";

.card {
    display: flex;
    flex-direction: column;
    // padding: 10px
    // border: 1px solid $border-grey
    // background-color: $primary-blue
    // border-radius: 10px
    &:hover {
        // box-shadow: #E9F3FE 0px 0px 8px 0px
        // transition: all 0.1s ease 0s
        // border-radius: 10px
        // background-image: url('../../images/cardbg.png')
        // background-repeat: no-repeat
        // background-size: 100% 100%
        .control {
            // visibility: visible
            opacity: 1; } } }

.previewBorder {
    padding: 10px 20px 10px;
    border: 1px solid $border-grey;
    background-color: $primary-blue;
    border-radius: 10px;
    margin-bottom: 3px; }

.preview {
    position: relative;
    overflow: hidden;
    height: 100%;
    max-height: 350px;
    min-height: 265px;
    // padding: 10px 20px 20px
    // border: 1px solid $border-grey
    // background-color: $primary-blue
    // border-radius: 10px
    & > img,video {
        width: 100%;
        transition: 1s all ease-in-out;
        border-radius: 10px; } }

.preview:hover {
    // border: 3px solid #EE9200
    // border-radius: 15px
    // box-shadow: 0 0 10px #EE9200
    // animation: glow 1s ease-in-out infinite alternate
    // background-color: #757171
    & > img {
 }        // transform: scale(1.3)
    .title {
        color: #EE9200;
 } }        // transform: scale(1.3)
.control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $neutrals3;
    visibility: hidden;
    border-radius: 16px;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: $neutrals8; } }

.category {
    position: absolute;
    top: 11px;
    left: 8px; }

.tokenPrice {
    font-weight: 100;
    font-size: 11px;
    font-family: 'Poppins';
    color: #9698A1;
    font-weight: 500;
    margin-bottom: 0; }

.favorite {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background: $neutrals8;
    box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2);
    border-radius: 50%;
    svg {
        width: 20px;
        height: 20px;
        fill: $neutrals4;
        transition: fill .2s;
        transition: opacity .2s; }
    &:before {
        content: "";
        position: absolute;
        top: 51%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 18px;
        height: 18px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        opacity: 0;
        transition: opacity .2s; }
    &:hover {
        svg {
            fill: $neutrals2; } }
    &.active {
        svg {
            opacity: 0; }
        &:before {
            opacity: 1; } }
    @include dark {
        background: $neutrals2;
        &:hover {
            svg {
                fill: $neutrals8; } } } }

.button {
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
    color: $white; }

.link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: white;
    height: 100%;
    &:hover {
        color: $white; }
    @include dark {
        color: $neutrals8; } }

.body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 15px 20px;
    // border-radius: 15px
    // padding-bottom: 0
    background-image: url('../../images/marketplaceBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 3px; }

.line {
    display: flex;
    align-items: baseline;
    p {
        color: grey;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0; }
    &:nth-child(2) {
        margin-top: auto; } }

.title {
    padding-top: 1px;
    color: $white;
    text-transform: uppercase;
    // +body-bold-2
    font-family: 'Poppins';
    font-weight: 500; }

.price {
    flex-shrink: 0;
    margin-left: 8px;
    padding: 0 8px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px $green;
    font-size: 12px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    color: $green; }

.users {
    display: flex;
    margin-right: auto; }

.avatar {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    color: $white;
    border-radius: 50%;
    // border: 2px solid $neutrals8
    @include dark {
        // border-color: $neutrals2
        color: $neutrals8; }
    img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 10px; }
    &:not(:first-child) {
        margin-left: -8px; } }

.counter {
    color: grey;
    display: flex;
    font-family: HeeboBold; }

.foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $neutrals6;
    @include caption-2;
    color: $neutrals4;
    @include dark {
        border-color: $neutrals3; } }

.status {
    svg {
        margin: -2px 4px 0 0;
        fill: $neutrals4; }
    span {
        font-weight: 600;
        color: $neutrals3;
        @include dark {
            color: $neutrals8; } } }
.ethereumimg {
    height: 31px;
    float: left;
    margin-left: -10px;
    margin-top: 3px; }
.polygonimg {
    height: 25px;
    float: left;
    margin-left: -3px;
    margin-top: 9px;
    margin-right: 6px; }
.ethereum {
    width: 50%;
    margin-top: auto; }
.bidding {
    margin-left: auto;
    font-size: 12px;
    margin-bottom: 3px; }
.tier {
    margin-left: auto;
    i {
        color: #EE9200;
        margin-right: 3px;
        font-size: 12px; }
    svg {
        color: #37d737de;
        height: 16px; }
    span {
        font-size: 10px; } }
.of {
    font-family: Heebo;
    position: absolute;
    right: 0;
    padding: 5px 10px 10px 10px;
    img {
        height: 50px; } }
.gender {
    img {
        height: 18px !important;
        margin-left: 10px; }
    // display: flex
 }    // margin: 0 auto
.stock {
    // color: #EE9200
    display: flex;
    margin: 5px 0; }
.bitNow {
    // background-color: #FEB032
    // border: 1px solid #FFB033
    background-image: url('../../images/soldBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #878c8c;
    font-family: 'Poppins';
    font-weight: 500;
    padding: 8px 15px;
    margin-left: auto;
    height: fit-content; }
.calendar {
    background-color: #FEB032;
    border: 1px solid #FFB033;
    color: black;
    font-family: HeeboBold;
    padding: 3px 15px;
    height: fit-content;
    margin: 5px auto;
    width: 100%; }
.season {
    text-align: center;
    font-family: Heebo; }
.size {
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: 100;
    font-family: 'Poppins';
    color: #9698A1; }
.available {
    margin-left: auto;
    display: flex;
    color: $white;
    font-size: 12px;
    text-align: end;
    font-weight: 100; }
.availablebutton {
    border: 1px solid #FFB033;
    color: $white;
    padding: 6px 10px;
    margin-left: 3px;
    height: 30px;
    font-size: 12px;
    font-family: AnitaSemiSquare;
    margin-left: auto;
    display: block; }
.buyNow {
    // border: 1px solid #FFB033
    // background-color: #EE9200
    background-image: url('../../images/buyBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: $white;
    padding: 8px 15px;
    margin-left: 3px;
    height: fit-content;
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 500;
    margin-left: auto;
    display: block;
    &:hover {
        background-image: url('../../images/opnseaBtn.png');
        color: $dark-yellow; } }
.availabletext {
    line-height: 98%;
    font-family: HeeboThin;
    font-size: 13px;
    margin-top: 10px; }
.buyNowDiv {
    margin-left: auto;
    button {
        margin-bottom: -22px; }
    p {
        color: grey;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0px; } }
.head {
    font-weight: bold;
    color: #EE9200; }
.pricefield {
    font-size: 10px; }
.tooltip {
    font-family: Heebo;
    width: 160px;
    img {
        height: 25px;
        width: 25px;
        margin-left: -5px; } }
.lineBorder {
    margin: 0 1px;
    border-top: 1px solid $border-grey;
    width: 93%; }
.supplyflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 100;
    font-family: 'Poppins';
    color: #9698A1; }

@import "../../styles/helpers";

.title {
    color: $white;
    font-size: 32px;
    font-family: ChakraBold;
    text-align: center; }
.referral {}

.container {
    max-width: 1550px;
    margin: 30px auto;
    padding: 0 20px; }
.link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $grey; }
.referralCode {
    background: url('../../images/onesideborder.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    height: 40px;
    gap: 20px;
    color: $white;
    padding: 10px 30px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    width: 28%;
    @include m {
        width: 70%;
        padding: 10px 10px;
        gap: 4px;
        font-size: 12px;
        height: 33px; } }
.invite {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    position: absolute;
    top: 65px;
    left: 0;
    right: 0;
    @include m {
        top: 34px; }
    span {
        color: black;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
        border-radius: 8px;
        border-bottom-right-radius: 1px;
        border-top-right-radius: 1px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        @include m {
            white-space: nowrap;
            width: fit-content;
            padding: 0 8px;
            font-size: 12px;
            border-radius: 5px;
            height: 33px; } } }

.icon {
    color: #FDC22E;
    font-size: 33px;
    cursor: pointer;
    &:hover {
        color: $white; }
    @include m {
        font-size: 40px; } }
.transaction {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 20px auto;
    @include m {
        width: 100%; } }

.statistics {
    background: url('../../images/downloadBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-family: Poppins;
    text-align: center;
    padding: 15px;
    width: 60%;
    margin: 0 auto;
    @include t {
        width: 80%; }
    @include m {
        padding: 5px;
        width: 95%; } }
.value {
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    @include m {
        font-size: 14px; } }
.field {
    color: $white;
    font-family: ChakraSemiBold;
    text-align: center;
    font-size: 24px;
    @include t {
        font-size: 16px; }
    @include m {
        font-size: 10px; } }
.heading {
    color: $white;
    font-family: ChakraSemiBold;
    text-align: center;
    font-size: 24px;
    margin-bottom: 15px; }
.date, .address, .reward, .email {
    font-family: 'Poppins';
    color: $grey;
    text-align: center;
    font-size: 14px;
    @include m {
        font-size: 10px; } }
.address, .reward {
    font-weight: 500;
    color: $white; }
.address {
    cursor: pointer; }
.reward {
    color: $white;
    span {
        color: $green; } }
.email {
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600; }

.earnings {
    border: 1px solid $border-grey;
    margin: 20px auto;
    padding: 15px 0; }
.row, .highlightedRow {
    height: 40px;
    align-items: center; }
.highlightedRow {
    background-color: #1E1E1E; }
.referralBg {
    position: relative; }
.referralImage {
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 30px;
    @include d {
        height: 300px; }
    @include m {
        height: 150px;
        margin-bottom: 0;
        border-radius: 10px; } }
.mask {
    position: absolute;
    top: 38%;
    left: 7%;
    @include m {
        width: 90%;
        margin: 0 20px;
        top: 32%;
        left: 0; }
    h2 {
        font-family: 'Chakra';
        color: $white;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 42px;
        @include d {
            font-size: 24px; }
        @include m {
            font-size: 14px; } }
    p {
        font-size: 16px;
        font-family: 'Poppins';
        color: $white;
        @include d {
            font-size: 14px; }
        @include m {
            font-size: 12px; } } }
.dotContainer {
    // display: flex
    // justify-content: center
    // align-items: center
    position: relative;
    height: 150px;
    font-family: 'Poppins';
    color: #9698A1;
    text-align: center;
    margin: 40px 0;
    @include m {
        margin: 40px 0;
        height: 70px; }
    h3 {
        color: $white;
        font-family: 'ChakraSemiBold';
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 32px;
        position: absolute;
        left: 0;
        right: 0;
        @include m {
            font-size: 18px; } }
    p {
        position: absolute;
        left: 0;
        right: 0;
        top: 42px;
        @include m {
            top: 36px; } } }
.dot {
    float: left;
    height: 100px;
    @include m {
        width: 40%;
        height: auto; } }
.dot1 {
    float: right;
    height: 100px;
    @include m {
        width: 40%;
        height: auto; } }
.background {
    background-image: url('../../images/SpaceshooterBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; }
.logo {
    background-color: #1E1E1E;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    height: 50px;
    margin-bottom: 6px;
    @include m {
        height: 30px; } }
.nodata {
    text-align: center;
    color: $white;
    font-family: Poppins;
    font-weight: 500;
    margin: 40px auto; }
.loader {
    position: absolute;
    top: 65px;
    left: 0;
    right: 0; }

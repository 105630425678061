@import "../../styles/helpers";

.containner {
    padding: 0; }

.head {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 326px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;
    @include t {
        height: 260px;
        padding-bottom: 64px; }
    @include m {
        height: 230px;
        padding-bottom: 56px; }
    .container {
        display: flex;
        justify-content: flex-end; }
    &.active {
        .btns {
            opacity: 0; }
        .file {
            opacity: 1;
            visibility: visible; } } }

.title,.KibblezTitle {
    color: $light-yellow;
    font-size: 32px;
    font-family: 'ChakraSemiBold';
    margin-bottom: 0;
    @include m {
        font-size: 18px; } }
.KibblezTitle {
    margin-left: 10px;
    font-family: Poppins;
    @include t {
        margin-left: 0; } }

.sub_title {
    color: white;
    margin-left: 32px;
    font-size: 16px;
    font-family: Poppins;
    @include m {
        font-size: 15px; } }

.heading {
    color: $white;
    font-family: Poppins;
    font-weight: 500;
 }    // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;


.wrap {
    position: relative;
    z-index: 2; }
.wrapper {
    flex: 0 0 calc(110% - 256px);
    width: calc(100% - 256px);
    padding-left: 64px;
    @include x {
        padding-left: 32px; }
    @include t {
        width: 100%;
        padding-left: 0; } }



.kibblezTable {
    overflow-x: auto;
    margin-right: 10px;
    @include t {
        margin-right: 0; } }
.bonezTable {
    overflow-x: auto;
    margin-left: 10px;
    @include t {
        margin-left: 0px; } }

.history_head {
    color: $white;
    font-family: Poppins;
    font-size: 24px; }
.history_table {
    // background-color: $model
    margin: 24px 0;
    border: 1px solid #2F2F2F;
    width: auto;
    overflow: auto;
    padding-bottom: 5px;
    @include m {
        width: 800px; }
    @include a {
        width: 600px; } }
.rowdata {
    padding: 13px 20px;
    &:not(:last-child) {
       border-bottom: 1px solid $border-grey; }
    &:hover {
        background: #161616; }
    &:nth-child(1) {
        border-top: 1px solid $border-grey;
        padding: 20px;
        &:hover {
            background: none; } } }
.rowdatabg {
    background-image: url('../../images/historybg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // margin-bottom: 5px
    padding: 13px 20px; }
.rowdatabg:first-child {
    padding-left: 10px; }
.buyer,.seller {
    color: $white;
    font-family: Poppins !important;
    letter-spacing: 2px;
    margin-top: 5px;
    text-align: center; }
.pricefield {
    font-family: Poppins;
    font-size: 14px;
    color: #9698A1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    a {
        color: $white; } }
.history_shade {
    width: 100%;
    height: 12px;
    display: flex;
    margin: 0 auto; }
.status {
    font-family: HeeboBold;
    font-size: 14px;
    color: $white;
    text-align: center; }
.hash {
    font-family: HeeboBold;
    font-size: 14px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
    text-align: center;
    a {
        color: $white; } }
.loader {
    margin: 70px auto; }
.empty {
    margin: 70px auto;
    color: $white;
    font-family: 'Poppins';
    text-align: center; }

@import "../../../styles/helpers";
.message {
    color: white;
    font-family: Poppins;
    font-size: 24px;
    font-family: 800;
    text-align: center;
    span {
        background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 800; } }
.lottie {
    height: 300px; }
.loseLottie {
    height: 400px;
    margin-top: -80px;
    margin-bottom: -20px; }
.notes {
    font-family: Poppins;
    font-size: 16px;
    margin-top: 10px;
    span {
        color: $purple;
        text-decoration: underline;
        li {
            color: $white;
            text-decoration: none;
            font-size: 15px; } } }

@import "../../styles/helpers";

.page {
    overflow: hidden;
    background-color: $primary-blue; }

.inner {
    margin-top: 75px;
    min-height: 100vh; }
.discord {
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 80px;
    z-index: 998;
    cursor: pointer;
    transition: transform .3s ease-in-out;
    &:hover {
        transform: rotate(360deg); }
    @include m {
        display: none; } }
.BackToTop {
    position: fixed;
    bottom: 115px;
    right: 54px;
    z-index: 998;
    cursor: pointer;
    align-self: center;
    text-align: center;
    animation: fadein 2s;
    color: #1E1E1E;
    background-color: $grey;
    transition: transform .5s ease-in-out;
    border-radius: 50%;
    svg {
        fill: #1E1E1E;
        font-size: 30px; }
    @include m {
        bottom: 60px; } }

@keyframes fadein {
    from {
        opacity: 0; }
    to {
        opacity: 1; } }

@import "../../styles/helpers";
.page {
    margin-top: 80px; }
.container {
    max-width: 896px;
    margin: 0 auto; }

.top {
    margin-bottom: 64px;
    @include m {
        margin-bottom: 32px;
        padding-bottom: 16px;
        border-bottom: 1px solid $neutrals6;
        @include dark {
            border-color: $neutrals3; } } }
.title {
    color: $white;
    font-family: 'ChakraSemiBold';
    font-size: 32px;
    @include m {
        font-size: 18px; } }

.info {
    color: $neutrals4;
    strong {
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; } } }

.row {
    display: flex;
    margin: 0 -16px;
    @include d {
        display: block;
        margin: 0; } }

.col {
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px;
    @include d {
        width: 100%;
        margin: 0; }
    &:not(:last-child) {
        @include d {
            margin-bottom: 64px; }
        @include m {
            margin-bottom: 32px;
            padding-bottom: 32px;
            border-bottom: 1px solid $neutrals6;
            @include dark {
                border-color: $neutrals3; } } } }

.user {
    padding: 13px 10px;
    margin: 0 auto;
    @include d {
        max-width: 416px; } }

.avatar {
    flex-shrink: 0;
    width: 148px;
    height: 148px;
    display: flex;
    margin: 0 auto;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.details {
    flex-grow: 1;
    text-align: center;
    margin: 0 auto;
    margin-top: 10px;
    width: 60%;
    justify-content: center;
    @include m {
        width: 100%; } }

.stage {
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 16px; }

.text {
    margin-bottom: 16px;
    text-align: center;
    @include caption-2;
    color: $neutrals4; }

.file {
    display: inline-block;
    position: absolute;
    overflow: hidden;
    border: 2px solid #9698A1;
    border-radius: 50%;
    padding: 5px;
    right: 0;
    bottom: 0;
    background-color: $primary-blue; }
.load {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 140px;
    opacity: 0;
    width: -webkit-fill-available;
    cursor: pointer; }
.list {
    margin-bottom: 16px;
    @include d {
        display: flex;
        margin: 0 -20px 64px; }
    @include m {
        display: block;
        margin: 0 0 32px; } }

.item {
    @include d {
        flex: 0 0 calc(50% - 40px);
        width: calc(50% - 40px);
        margin: 0 20px; }
    @include m {
        width: 100%;
        margin: 0; }
    & > .button {
        margin-top: 32px; }
    &:not(:last-child) {
        margin-bottom: 40px;
        @include d {
            margin-bottom: 0; }
        @include m {
            margin-bottom: 32px; } } }

.category {
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 24px; }

.fieldset {
    & > .field {
        &:not(:last-child) {
            margin-bottom: 32px; } } }

.box {
    position: relative;
    .button {
        position: absolute;
        right: 12px;
        bottom: 8px;
        height: 32px;
        padding: 0 10px; } }

.note {
    color: $neutrals4; }

.btns {
    display: flex;
    width: fit-content;
    margin: 0 auto;
    align-items: center;
    gap: 50px;
    @include m {
        gap: 20px; } }

.clear {
    display: inline-flex;
    align-items: center;
    margin-left: 16px;
    @include button-1;
    color: $white;
    transition: color .2s;
    svg {
        margin-right: 8px;
        fill: $white;
        transition: fill .2s; }
    &:hover {
        color: $secondary;
        svg {
            fill: $secondary; } }
    @include m {
        margin-left: 0;
        margin-top: 12px; } }
.error {
    color: red;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    margin: 10px 0; }
.shade {
    width: 80%;
    display: flex;
    margin: 24px auto; }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }
.icon {
    color: #9698A1;
    font-size: 24px; }
.photo {
    color: #9698A1;
    font-size: 34px; }
.template, .templateimg {
    border: 1px solid #9698A1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
.templateimg {
    height: 150px;
    width: 150px;
    margin: 0 auto;
    object-fit: cover; }
.yellowbutton {
    background: url('../../images/yellowoutline.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 49px;
    padding: 0 30px; }
.cancelButton {
    background: url('../../images/secondaryBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 49px;
    padding: 0 30px;
    color: #9698A1;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Poppins';
    font-style: normal; }
.buttontext {
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Poppins';
    font-style: normal; }
.heading {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0px;
    text-align: center;
    margin: 15px auto; }
.cancelbutton {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 15px; }
.modal {
    width: 35%;
    @include t {
        width: 90%; }
    @include m {
        width: 100%; } }
.spinner {
    margin-left: 18px;
    margin-top: 3px; }
.divider {
    border-bottom: 1px solid #667085;
    margin: 30px auto; }
.member {
    color: #667085;
    font-family: Poppins;
    text-align: end;
    margin-left: 10px; }

@import "../../styles/helpers";

.raceTrack {
    background-image: url('../../images/background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;


    margin: 80px 0;
    @include m {
        background-size: 100% 100%; } }

.track {
    p {
        color: $white;
        font-family: Heebo;
        text-align: left;

        padding-top: 100px;

        font-size: 22px;
        font-weight: 400;

        @include t {
            font-size: 15px; }
        @include m {
            font-size: 12px !important;
            font-family: Heebo !important;
            padding-left: 25px;
            padding-top: 10px; }
        @include a {
            font-size: 10px;
            padding-left: 25px; } }

    h4 {
        color: #EE9200;
        font-family: polaris;
        padding: 3% 10% 0 0;
        font-size: 30px;
        text-align: end;
        @include t {
            font-size: 20px; }
        @include m {
            font-size: 20px;
            font-family: polaris !important; }
        @include a {
            font-size: 15px; } } }
.trackImg {

    width: 100%;

    margin-top: -39px;
    margin-left: -2%;
    margin-left: -2%; }

.title {
    margin-bottom: 64px;

    color: #EE9200;
    font-size: 28px;
    font-family: polaris;
    @include m {
        margin-bottom: 32px; } }

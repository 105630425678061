@import "../../styles/helpers";

.containner {
    padding: 0; }

.head {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 326px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;
    @include t {
        height: 260px;
        padding-bottom: 64px; }
    @include m {
        height: 230px;
        padding-bottom: 56px; }
    .container {
        display: flex;
        justify-content: flex-end; }
    &.active {
        .btns {
            opacity: 0; }
        .file {
            opacity: 1;
            visibility: visible; } } }

.title {
    color: white;
    font-size: 32px;
    font-family: 'ChakraSemiBold';
    @include m {
        font-size: 18px; } }

.sub_title {
    color: white;
    margin-left: 32px;
    font-size: 16px;
    font-family: Poppins;
    @include m {
        font-size: 15px; } }
.top {
    display: flex;
    align-items: center;
    @include m {
        display: block; } }
.heading {
    color: $white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700; }
.description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9698A1; }
.wrap {
    position: relative;
    z-index: 2; }
.wrapper {
    flex: 0 0 calc(110% - 256px);
    width: calc(100% - 256px);
    padding-left: 64px;
    @include x {
        padding-left: 32px; }
    @include t {
        width: 100%;
        padding-left: 0; } }

.nav {
    display: flex;
    margin-left: auto;
    border: 2px solid #9698A1;
    border-radius: 20px;
    height: 40px;
    align-items: center;
    width: fit-content;
    @include m {
        margin-top: 20px; } }
.link {
    padding: 4px 30px;
    border-radius: 1px;
    font-family: 'Poppins';
    white-space: nowrap;
    // +button-2
    color: #9698A1;
    transition: all .5s;
    display: flex;
    align-items: center;
    gap: 10px;
    @include m {
        padding: 4px 20px; }
    &:hover, &.active {
        transition: all .5s;
        color: black;
        background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
        border-radius: 53px;
        height: 37px;
        margin-left: -1px; } }

.item {
    overflow-x: auto; }
.history_head {
    color: $white;
    font-family: Poppins;
    font-size: 24px; }
.history_table {
    background-color: transparent;
    margin: 24px 0;
    width: auto;
    overflow: auto;
    padding-bottom: 5px;
    @include m {
        width: 900px; } }
.rowdata {
    background-color: transparent;
    border: 1px solid #667085;
    border-right: none;
    border-left: none;
    padding: 13px 20px; }
.rowdatabg {
    background-color: transparent;
    border-bottom: 1px solid #667085;
    padding: 13px 20px;
    align-items: center;
    &:hover {
        background-color: #2F2F2F; } }
.rowdatabg:first-child {
    padding-left: 10px; }
.buyer,.seller {
    color: $white;
    font-family: Poppins !important;
    // letter-spacing: 2px
    margin-top: 5px;
    text-align: center;
    font-weight: 500; }
.pricefield {
    font-family: Poppins;
    font-size: 14px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    a {
        color: $white; } }
.price {
    font-family: Poppins;
    font-size: 14px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-weight: 500; }
.history_shade {
    width: 100%;
    height: 12px;
    display: flex;
    margin: 0 auto; }
.status {
    font-family: 'Poppins';
    font-size: 14px;
    color: #27AE60;
    text-align: center;
    background: #21332F;
    border-radius: 50px;
    width: fit-content;
    margin: 0 auto;
    padding: 4px 20px; }
.sent {
    font-family: 'Poppins';
    font-size: 14px;
    color: #EB5757;
    text-align: center;
    background: #332121;
    border-radius: 50px;
    width: fit-content;
    margin: 0 auto;
    padding: 4px 20px; }
.progress {
    font-family: 'Poppins';
    font-size: 14px;
    color: #F2994A;
    text-align: center;
    background: #332921;
    border-radius: 50px;
    width: fit-content;
    margin: 0 auto;
    padding: 4px 20px; }
.hash {
    font-family: Poppins;
    font-size: 14px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
    text-align: center;
    font-weight: 500;
    a {
        color: $white;
        text-align: center; } }

.loader {
    margin: 70px auto; }
.empty {
    margin: 70px auto;
    color: $white;
    font-family: 'Poppins';
    text-align: center; }
.openicon {
    color: #41dcff;
    font-size: 22px;
    margin-left: 5px;
    cursor: pointer; }

@import "../../styles/helpers";

.head {
    // text-align: center
    font-family: 'ChakraSemiBold' !important;
    font-size: 42px;
    color: $white;
    font-weight: 600;
    // text-shadow: 2px 2px 25px $white
    @include d {
        font-size: 24px; }
    @include m {
        font-size: 14px; } }
.containerouter {
    height: fit-content;
    position: absolute; }
.body {
    // min-height: 400px
    position: relative;
    // margin: 20px 30px
    @include m {
        // margin: 10px 0 20px
        min-height: 180px; }
    @include a {
        min-height: 100px; } }
.bodyMask {
    width: 100%;
    height: 450px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 30px;
    @include d {
        height: 300px; }
    @include m {
        height: 150px;
        margin-bottom: 0; } }

.subhead {
    font-family: AnitaSemiSquare;
    font-size: 24px;
    color: $white;
    width: fit-content;
    margin: 5px 0;
    @include t {
        font-size: 16px; } }
.balance {
    text-align: center;
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 16px;
    @include m {
        font-size: 10px; } }
.shade {
    width: 80%;
    display: flex;
    margin: 24px auto; }
.vessel {
    position: absolute;
    bottom: 18rem;
    left: 15rem;
    width: 16%;
    @include m {
        bottom: 12.5rem;
        left: 13rem; } }
.borderimg {
    border-radius: 3px;
    border: 1px solid $secondary;
    position: relative;
    height: 100%;
    cursor: default;
    @include m {
        height: 100%; }
    &:hover {
        box-shadow: #0cc4c9b3 0px 0px 16px;
        transition-duration: 1s; } }

.previewImg {
    width: 100%;
    float: right;
    padding: 5px; }

.border {
    float: left;
    height: 100%;
    width: 16px; }
.title {
    background-image: url('../../images/base.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: $white;
    font-family: AnitaSemiSquare;
    position: absolute;
    // padding: 10px 4px
    margin-left: 6px;
    font-size: 13px;
    width: 13rem;
    // position: absolute
    // bottom: 12%
    padding: 8px 0;
    @include m {
        font-size: 11px;
        margin-left: 9px; } }
.titlemain {
    display: flex;
    position: relative;
    bottom: 62px;
    @include m {
        flex-wrap: wrap;
        bottom: 33px; } }
.select {
    background-image: url('../../images/selectbase.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: white;
    float: right;
    font-weight: 500;
    font-family: AnitaSemiSquare;
    margin-left: auto;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    @include m {
        margin-right: 0;
        font-size: 9px;
        padding: 3 10px;
        margin-right: 10px; }
    &:hover {
        color: #EE9201;
        background-image: url('../../images/selecthoverbase.png');
        text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange; } }

.yield {
    color: $white;
    font-family: AnitaSemiSquare;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
    @include m {
        font-size: 20px; } }

.button {
    margin: 15px 0;
    display: flex;
    justify-content: center; }

.containers {
    width: 70%;
    margin: 0 auto;
    // background-image: url('../../images/modalscreenbg.png')
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: fit-content;
    padding: 32px;
    @include t {
        width: 90%; }
    @include m {
        padding: 10px; } }
.card {
    width: 100%;
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    height: 100%;
    @include m {
        height: auto; } }

.modal {
    width: 60%;
    @include t {
        width: 60%; }
    @include m {
        width: 90%; } }
.codeModal {
    width: 35%;
    @include t {
        width: 50%; }
    @include m {
        width: 90%; } }
.codeModalBody {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    align-items: center; }
.empty {
    margin: 70px auto;
    color: $white;
    font-family: AnitaSemiSquare;
    text-align: center;
    font-size: 18px; }
.loader {
    margin: 25px auto; }

.spacegameImg {
    width: 100%;
    position: absolute; }
.spacemain {
    color: $white;
    font-size: 16px;
    // font-family: AnitaSemiSquare
    // font-size: 18px
    // text-shadow: 2px 2px 25px $white
    @include d {
        font-size: 14px; }
    @include m {
        font-size: 12px; } }

.download {
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 12px;
    text-shadow: 2px 2px 25px $white; }

.blueText {
    color: $secondary;
    font-family: AnitaSemiSquare;
    font-size: 18px;
    text-shadow: 2px 2px 25px $secondary;
    @include m {
        font-size: 12px; } }
.blueshade {
    color: $white;
    font-family: 'Poppins';
    font-weight: 500;
    margin-bottom: 20px;
    // font-size: 18px
    // text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary
    // text-align: center
    @include m {
        font-size: 12px;
        margin-bottom: 10px; } }
.battleImg {
    height: 200px; }
.copy {
    margin-left: 8px;
    font-size: 25px;
    svg {
        fill: $white;
        transition: fill .2s;
        margin-top: 38px;
        &:hover,
        &:active {
            fill: $secondary; } } }
.content {
    display: flex;
    justify-content: center;
    margin: 16px 0;
    align-items: center; }
.note {
    color: $white;
    font-family: Poppins;
    text-align: center;
    font-weight: 500; }
.noteHead {
    color: $dark-yellow;
    font-family: Poppins; }
.modalTitle {
    color: $white;
    font-family: ChakraSemiBold;
    // font-weight: lighter
    font-size: 32px;
    margin-bottom: 20px;
    // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange
    @include m {
        font-size: 20px; } }
.copyIcon {
    margin-left: 8px;
    font-size: 22px;
    &:active {
        color: $dark-yellow; } }
.appIcon {
    height: 70px;
    display: flex;
    margin: 0 auto;
    border-radius: 50%; }
.buyNow {
    // border: 1px solid #FFB033
    // background-color: #EE9200
    background-image: url('../../images/buyBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: $white;
    padding: 8px 15px;
    // margin-left: 3px
    height: fit-content;
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 500;
    display: block;
    &:hover {
        background-image: url('../../images/opnseaBtn.png');
        color: $dark-yellow; } }
.downloadBody {
    width: 90%;
    text-align: center;
    margin: 50px auto 0; }
.heads {
    font-family: 'ChakraSemiBold' !important;
    font-size: 32px;
    color: $white;
    font-weight: 600;
    @include m {
        font-size: 18px; } }
.text {
    color: $white;
    font-family: 'Poppins';
    color: #9698A1;
    width: 35%;
    margin: 0 auto 50px;
    font-weight: 500;
    @include t {
        font-size: 10px;
        width: 100%; } }
.downloadFlex {
    display: flex;
    justify-content: space-between;
    gap: 100px;
    @include w {
        gap: 50px; }
    @include d {
        gap: 20px; }
    @include t {
        flex-direction: column;
        align-items: center;
        gap: 10px; } }
.bg {
    background-image: url('../../images/downloadBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 20px;
    width: 33.33%;
    @include t {
        width: 100%; }
    img {
        background: #1E1E1E;
        padding: 10px;
        border-radius: 50%;
        @include m {
            height: 50px; } }
    h2 {
        color: $white;
        font-family: 'ChakraSemiBold';
        font-size: 22px;
        margin-top: 20px;
        font-weight: 600;
        margin-bottom: 0;
        @include m {
            font-size: 14px; } }
    p {
        color: #9698A1;
        font-family: 'Poppins';
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 500;
        @include m {
            font-size: 10px; } } }
.background {
    background-image: url('../../images/SpaceshooterBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; }
.contents {
    position: absolute;
    top: 28%;
    left: 5%;
    width: 100%;
    @include m {
        width: 90%;
        top: 17%; } }
.loginBtn {
    display: block;
    margin: 0 auto; }
.margin {
    margin-bottom: 7px !important; }

.hero,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    @include dm-sans;
    font-weight: 700; }

.hero {
    font-size: 96px;
    line-height: 1;
    letter-spacing: -.02em; }

.h1 {
    font-size: 58px;
    line-height: 1;
    letter-spacing: -.02em; }

.h2 {
    font-size: 48px;
    line-height: (56/48);
    letter-spacing: -.02em; }

.h3 {
    font-size: 40px;
    line-height: (48/40);
    letter-spacing: -.01em; }

.h4 {
    font-size: 32px;
    line-height: (40/32);
    letter-spacing: -.01em; }

@import "../../styles/helpers";
.container {
    margin: 50px auto;
    background-image: url('../../images/winnerbg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px;
    @include m {
        background-image: none; } }

.list {
    padding: 20px;
    // border: 1px solid #20668b
    // background-color: #0d2236
    border-radius: 3px;
    width: fit-content;
    margin: 20px auto 0 auto; }
.heading {
    text-align: center;
    color: $white;
    // text-shadow: 2px 2px 25px $secondary
    font-family: 'ChakraSemiBold';
    font-size: 20px;
    margin-top: -5px;
    @include m {
        font-size: 18px; } }
.address {
    font-size: 16px;
    text-align: center;
    color: #9698A1;
    // text-shadow: 2px 2px 25px $white
    font-family: 'Poppins';
    margin: 0 15px;
    text-transform: lowercase;
    font-weight: 500;
    @include m {
        font-size: 14px; } }
.addressList {
    display: flex;
    gap: 10px;
    margin-top: 15px; }
.avatar {
    height: 30px;
    border-radius: 50%;
    width: 30px;
    object-fit: cover; }
.you {
    color: $secondary;
    font-size: 14px;
    margin-left: 1px; }
.text {
    color: $white;
    font-size: 16px;
    text-align: center;
    // text-shadow: 2px 2px 25px $white
    margin: 40px auto;
    font-family: Poppins;
    font-weight: 500; }
.medalContent {
    text-align: center;
    p {
        font-family: 'Poppins';
        color: #9698A1;
        font-weight: 500;
        margin-bottom: 10px; }
    h1 {
        font-family: 'ChakraSemiBold';
        color: $white;
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 22px; } }
.medal {
    height: 100px; }
.button {
    margin: 0 auto;
    display: block; }
.border {
    &:nth-child(1) {
        border-right: 1px solid #2F2F2F;
        @include m {
            border-bottom: 1px solid #2F2F2F;
            border-right: 0;
            margin-bottom: 15px; } }
    &:nth-child(3) {
        border-left: 1px solid #2F2F2F;
        @include m {
            border-top: 1px solid #2F2F2F;
            border-left: 0;
            margin-top: 15px; } } }
.colFlex {
    display: flex !important;
    justify-content: center;
    align-items: center; }
.modal {
    width: 30%;
    @include t {
        width: 80%; }
    @include m {
        width: 90%; } }

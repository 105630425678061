@import "../../styles/helpers";

.item {
    // background: $neutrals2
    display: flex;
    @include m {
        display: block; } }
.head {
    color: $purple;
    font-family: Poppins; }
.register {
    margin-top: 32px;
    margin-bottom: 16px; }

.title {
    color: $white;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    font-family: ChakraBold;
    @include m {
        font-size: 16px;
        margin: 16px 8px; } }

.notice {
    color: $purple;
    font-size: 14px;
    font-weight: 400;
    font-family: Poppins;
    margin-top: 8px;
    @include m {
        font-size: 16px;
        margin: 16px 8px; } }

.claimNow {
    background-image: url('../../images/button_1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 54px;
    color: white;
    font-weight: 400;
    font-size: initial;
    font-family: AnitaSemiSquare;
    padding: 0 10%;
    width: fit-content;
    margin: 0 auto;
    margin-top: 3%;
    margin-left: 10px; }

.button {
    background-image: url('../../images/login.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 54px;
    color: white;
    font-weight: 400;
    font-size: initial;
    font-family: AnitaSemiSquare;
    padding: 0 10%;
    width: fit-content;
    margin: 0 auto;
    margin-top: 3%;
    margin-right: 10px;
    &:hover {
        border: none;
        background-image: url('../../images/cancel.png');
        background-repeat: no-repeat;
        background-size: 100% 100%; }
    &:not(:last-child) {
        background-image: url('../../images/cancel.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 8px; } }

.image {
    width: 35%;
    height: fit-content;
    border-radius: 16px;
    margin-right: 20px;
    @include m {
        width: 100%; }
    svg {
        fill: $neutrals8; } }

.info {
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
    @include body-bold-2; }

.field {
    border-radius: 8px;
    width: 100%;
    align-items: center;
    margin-left: 24px;
    padding-right: 46%; }

.field {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include m {
            margin-bottom: 20px; } } }
.heading {
    color: #EE9200;
    font-size: 24px;
    font-weight: 400;
    font-family: AnitaSemiSquare;
    text-align: center;
    margin-right: 100px; }
.note {
    margin-top: 4px;
    font-size: 14px;
    color: $neutrals8;
    margin-left: 25px;
    font-family: Poppins;
    @include m {
        margin-left: 16px; } }

.alert {
    text-align: center;
    color: #f44336;
    margin: 16px auto;
    font-size: 14; }

.success {
    text-align: center;
    color: $green;
    font-family: Poppins;
    margin-bottom: 16px;
    font-size: 14px; }

.spinner {
    margin-left: 8px; }

.closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s; }

.closebtn:hover {
    color: black; }

.avatar,
.icon {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    margin-right: 8px; }

.avatar {
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }
.text {
    font-weight: 500; }

.wallet {
    width: 280px;
    cursor: pointer;
    border: 2px solid $neutrals6;
    border-radius: 12px;
    height: 55px;
    @include dark {
        border-color: $neutrals3; }
    @include t {
        width: 260px; }
    @include m {
        width: 100%;
        margin-bottom: 12px; } }

.modal {
    padding: 0 42px; }

.metamask {
    height: 40px;
    width: 40px;
    margin: 5px 25px; }

.iconimg {
    float: right;
    margin-top: 14px; }

.delevery {
    display: flex;
    margin-right: 38px; }

.buttons {
    // display: flex
    background-image: url('../../images/button_2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 40%;
    hight: 100%;
    margin-left: auto; }

.leftbtn {
    width: auto !important;
    margin-right: auto; }

.rightbtn {
    width: auto !important;
    margin-left: auto; }
.deleveryModal {
    max-width: 1000px !important;
 }    // background-color: blue

.creator {
    display: flex;
    padding: 16px; }

.category {
    font-size: 16px; }

.description {
    margin-left: 10%; }

.btns {
    text-align: center;
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: center; }
.price {
    margin-left: 25px;
    @include m {
        margin-left: 16px; } }
.stock {
    margin-left: 24px;
    @include m {
        margin: 0; } }
.isWalletAvailable {
    text-align: center;
    color: #FFCD58;
    font-family: Poppins;
    .info {
        color: #FFCD58; }
    a {
        text-decoration: underline; } }
.notes {
    text-align: center; }

@import "../../styles/helpers";

.item {
    // background: $neutrals2
    display: flex;
    flex-direction: row;
    @include m {
       flex-direction: Column; } }
.head {
    color: #FFCD58;
    font-family: HeeboBold;
    text-shadow: 2px 2px 25px #FF0000; }
.register {
    margin-top : 32px;
    margin-bottom : 16px; }

.body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;
    border-radius: 15px;
    padding-bottom: 0; }

.counter {
    color: grey;
    display: flex;
    font-family: HeeboBold; }

.size {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 100;
    font-family: HeeboBold; }

.line {
    display: flex;
    align-items: flex-start;
    &:nth-child(2) {
        margin-top: auto; } }

.title {
    padding-top: 1px;
    color: $white;
    text-transform: uppercase;
    // +body-bold-2
    font-family: HeeboBold; }


.image {
    width: 35%;
    border-radius: 16px;
    margin-right: 20px;
    svg {
        fill: $neutrals8; } }

.info {
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
    @include body-bold-2; }

.button {
    background-image: url('../../images/login.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border:none {}
    width: 40%;
    font-family: polaris;
    &:hover {
        border: none;
        background-image: url('../../images/cancel.png');
        background-repeat: no-repeat;
        background-size: 100% 100%; }
    &:not(:last-child) {
        background-image: url('../../images/cancel.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 8px; } }

.field {
    margin: 25px 0;
    border-radius: 8px; }

.field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include m {
                margin-bottom: 20px; } } }

.note {
    margin-top: 4px;
    font-size: 14px;
    color: $neutrals4;
    margin-left: 25px; }

.alert {
  text-align: center;
  color: #f44336;
  margin-bottom: 16px;
  font-size: 14; }

.success {
  text-align: center;
  color: green;
  margin-bottom: 16px;
  font-size: 14; }

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s; }


.closebtn:hover {
  color: black; }

.avatar,
.icon {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    margin-right: 8px; }

.avatar {
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }
.text {
    font-weight: 500; }

.wallet {
    width: 280px;
    cursor: pointer;
    border: 2px solid $neutrals6;
    border-radius: 12px;
    height: 55px;
    @include dark {
        border-color: $neutrals3; }
    @include t {
        width: 260px; }
    @include m {
        width: 100%;
        margin-bottom: 12px; } }

.modal {
    padding: 0 42px; }

.metamask {
    height: 40px;
    width: 40px;
    margin: 5px 25px; }

.iconimg {
    float: right;
    margin-top: 14px; }

.delevery {
    display: flex;
    margin-right: 38px; }

.buttons {
    // display: flex
    background-image: url('../../images/button_2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 40%;
    hight: 100%;
    margin-left: auto; }

.leftbtn {
    width: auto !important;
    margin-right: auto; }

.rightbtn {
    width: auto !important;
    margin-left: auto; }
.deleveryModal {
    max-width: 1000px !important;
 }    // background-color: blue


.creator {
    display: flex;
    padding: 16px; }

.category {
    font-size: 16px; }

.description {
    margin-left: 10%; }

.btns {
    text-align: center;
    width: 100%;
    margin: 25px 0; }
.price {
    margin-left: 25px; }
.stock {
    margin-left: 64px; }
.ownLand {
    display: flex;
    flex-direction: row;
    margin-top: 8px; }

.of {
    font-family: Heebo;
    position: absolute;
    right: 0;
    padding: 5px 10px 10px 10px;
    img {
        height: 50px; } }

.preview {
    position: relative;
    overflow: hidden;
    height: 100%;
    max-height: 350px;
    & > img,video {
        width: 100%;
        transition: 1s all ease-in-out;
        border-radius: 3px; } }

.preview:hover {
    // border: 3px solid #EE9200
    // border-radius: 15px
    // box-shadow: 0 0 10px #EE9200
    // animation: glow 1s ease-in-out infinite alternate
    // background-color: #757171
    & > img {
 }        // transform: scale(1.3)
    .title {
        color: #EE9200;
 } }        // transform: scale(1.3)

.card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #20668b;
    background-color: #082737;
    border-radius: 3px;
    margin-left: 10%;
    margin-bottom: 10%;
    margin-right: 5%;
    @include m {
        max-width: 100%;
        margin-top: 8px;
        margin-left: 0px;
        margin-right: 0px; }

    &:hover {
        // box-shadow: #E9F3FE 0px 0px 8px 0px
        // transition: all 0.1s ease 0s
        // border-radius: 10px
        // background-image: url('../../images/cardbg.png')
        // background-repeat: no-repeat
        // background-size: 100% 100%
        .control {
            // visibility: visible
            opacity: 1; } } }

.asset {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #20668b;
    background-color: #082737;
    border-radius: 3px;
    margin-left: 10%;
    margin-bottom: 10%;
    @include m {
        max-width: 100%;
        margin-top: 8px; }
    &:hover {
        // box-shadow: #E9F3FE 0px 0px 8px 0px
        // transition: all 0.1s ease 0s
        // border-radius: 10px
        // background-image: url('../../images/cardbg.png')
        // background-repeat: no-repeat
        // background-size: 100% 100%
        .control {
            // visibility: visible
            opacity: 1; } } }
.avatar {
    flex-shrink: 0;
    width: 150px;
    height: 150px;
    margin-right: 20px;
    @include m {
        width: 72px;
        height: 72px;
        margin-right: 16px; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px; } }
.value {
    color: #FFCD58;
    font-family: HeeboBold;
    text-shadow: 2px 2px 25px #FF0000;
    font-size: 20px;
    padding-top: 11px;
    padding-left: 10px;
    @include m {
        font-size: 16px;
        padding-left: 10px; } }
.noassets {
    color: #FFFFFF;
    font-family: HeeboBold;
    text-align: center;
    font-size: 20px;
    padding-top: 101px;
    padding-left: 101px;
    @include m {
        font-size: 16px;
        padding-left: 40px; } }

.stock {
    color: #EE9200;
    display: flex; }

.buyNowDiv {
    margin-left: auto;
    button {
        margin-top: -32px; }
    p {
        color: grey;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0px; } }

.buyNow {
    border: 1px solid #FFB033;
    background-color: #EE9200;
    border-radius: 3px;
    color: black;
    padding: 8px 16px;
    // height: 30px
    font-size: 12px;
    font-family: HeeboBold;
    margin-left: auto;
    display: block; }

@import "../../styles/helpers";



.welcome {
        color: $white;
        font-family: Deltha;
        font-size: 20px;
        padding:  1%;
        @include m {
            font-size: 7px; } }
.storypara {
    color: $white;
    font-family: Deltha;
    text-align: left;
    margin-left: 35px; }




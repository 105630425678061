@import "../../styles/helpers";



.title {
    margin-bottom: 64px;

    color: $white;
    font-size: 32px;
    font-family: ChakraSemiBold;
    @include m {
        margin-bottom: 18px; } }
.store {
    color: $white;
    font-weight: 500;
    font-family: Poppins !important;
    // padding-left: 5%
    font-size: 24px;
    margin-bottom: 15px;
    margin-top: 50px;
    &:nth-child(1) {
        margin-top: 0; }
    @include m {
        font-size: 16px;
        font-family: Poppins !important; } }
.grop {
    font-family: Poppins !important;
    color: #9698A1;
    font-size: 16px !important;
    text-align: justify;
    @include m {
        font-size: 12px !important;
        font-family: Poppins !important; } }
.privacyBorder {
    padding: 50px;
    margin: 0 3%;
    border: 1px solid #2F2F2F;
    @include m {
        padding: 20px; } }
.history_table {
    margin: 24px 0;
    width: auto;
    overflow: auto;
    padding-bottom: 5px;
    @include m {
        width: 1400px;
        margin: 0;
        padding-bottom: 0; }
    @include a {
        width: 1400px;
        margin: 0;
        padding-bottom: 0; } }
.rowdata {
    padding: 20px;
    border-bottom: 1px solid $border-grey;
    border-left: 1px solid $border-grey;
    border-right: 1px solid $border-grey;
    &:hover {
        background: #161616; }
    &:nth-child(1) {
        border-top: 1px solid $border-grey;
        &:hover {
            background: none; } } }
.buyer,.seller {
    color: $white;
    font-family: 'Poppins' !important;
    font-weight: 500;
    @include m {
        font-size: 14px !important; } }
.raceText {
    color: #9698A1;
    font-size: 16px;
    font-family: 'Poppins';
    padding-right: 30px;
    text-align: initial;
    @include m {
        font-size: 12px !important; } }
.item {
    overflow-x: auto;
    margin: 10px 0; }

@import "../../styles/helpers";
.spin {
    margin: 10px 30px;
    @include m {
        margin: 10px 20px; } }

.spinText {
    color: white;
    font-family: Poppins;
    font-size: 24px;
    margin-bottom: 6px;
    font-weight: 600;
    text-align: center;
    margin-top: -20%;
    @include t {
        margin-top: 70%; }
    @include m {
        font-size: 16px;
        margin-top: 50%; } }
.extra {
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center; }
// .spinWheel
//     margin-left: 30%
//     margin-bottom: -13%
//     margin-top: -2%
//     +m
//         margin-top: 1%
//         margin-bottom: 0%
.heading {
    color: $white;
    font-family: ChakraSemiBold;
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    @include m {
        font-size: 24px; } }
.free, .premium {
    text-align: center;
    font-weight: 500;
    font-family: Poppins;
    font-size: 15px; }
.free {
    color: $purple; }
.premium {
    background: linear-gradient(90deg, #FDC22E 0%, #EDDC65 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600; }
.value {
    color: $white;
    font-size: 20px;
    color: $white;
    font-weight: 500; }
.spinned {
    position: absolute;
    height: 105%;
    width: 100%;
    top: -20px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000b8;
    z-index: 10;
    @include t {
        align-items: initial; } }

.spindiv {
    position: relative; }
.spinButton {
    display: flex;
    justify-content: center;
    margin-top: 10px; }
.wheel {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 20px auto;
    div {
        max-height: 500px !important;
        max-width: 500px !important;
        display: flex;
        justify-content: center; } }
.error {
    color: red;
    font-size: 13px;
    text-align: center;
    font-family: Poppins;
    font-weight: 600; }
.spinCount {
    position: absolute;
    top: 30%;
    right: 0;
    border: 2px solid #fdc22e8c;
    margin-right: 10%;
    z-index: 11;
    height: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    @include t {
        position: relative;
        width: 100%;
        margin: 10px auto; } }

.coupon {
    position: absolute;
    top: 30%;
    left: 0;
    border: 2px solid #fdc22e8c;
    padding: 15px 30px;
    margin-left: 10%;
    color: $white;
    text-align: center;
    width: 300px;
    font-size: 15px;
    font-family: 'Poppins';
    font-weight: 500;
    z-index: 11;
    @include t {
        position: relative;
        width: 100%;
        margin: 10px auto; } }
.codeButton {
    margin: 0 auto;
    margin-top: 5px; }
.icon {
    color: #FDC22E;
    font-size: 20px; }
.couponModal {
    width: 30%;
    @include t {
        width: 90%; } }
.trasaction {
    color: $white;
    font-family: Poppins;
    font-weight: 400;
    margin-top: 5px;
    a {
        font-family: Poppins;
        font-weight: 400; } }

@import "../../styles/helpers";

.verify {
    padding: 20px 10px;
    border: 1px solid #20668b;
    background-color: $primary-blue;
    border-radius: 3px;
    margin: 0 auto;
    margin-top: 120px;
    width: 50%;
    @include m {
        width: 90%; } }
.loader {
    width: 60%;
    margin: 0px auto;
    margin-top: -100px;
    @include m {
        margin-top: -50px;
        width: 70%; } }
.success {
    width: 70%;
    margin: -80px auto;
    @include m {
        margin: -25px auto;
        width: 75%; } }
.failed {
    width: 80%;
    margin: 0 auto;
    margin-top: 10px; }
.loading {
    color: $secondary;
    font-family: AnitaSemiSquare;
    font-size: 30px;
    text-align: center;
    text-shadow: 2px 2px 25px $secondary;
    @include m {
        font-size: 24px; } }
.connect {
    color: $secondary;
    font-family: AnitaSemiSquare;
    font-size: 30px;
    text-align: center;
    text-shadow: 2px 2px 25px $secondary;
    margin: 50px auto;
    @include m {
        font-size: 24px; } }
.subtitle {
    color: $white;
    text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary; }
.title {
    color: $white;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
    font-size: 30px;
    text-align: center;
    font-family: AnitaSemiSquare;
    @include m {
        font-size: 24px; } }
.failedText {
    color: #fc5757;
    font-family: AnitaSemiSquare;
    font-size: 30px;
    text-align: center;
    text-shadow: 2px 2px 25px #fc5757;
    @include m {
        font-size: 24px; } }
.successText {
    color: #3ccd4f;
    font-family: AnitaSemiSquare;
    font-size: 30px;
    text-align: center;
    text-shadow: 2px 2px 25px #3ccd4f;
    @include m {
        font-size: 24px; } }
.shade {
    height: 5px;
    width: 70%;
    display: flex;
    margin: 10px auto; }
.link {
    flex-shrink: 0;
    padding: 8px 20px;
    border-radius: 1px;
    font-family: AnitaSemiSquare;
    white-space: nowrap;
    border: 1px solid $neutrals4;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    color: $neutrals8;
    transition: all .2s;
    width: fit-content !important;
    background-image: url('../../images/Active.png');
    background-repeat: no-repeat;
    background-size: 101% 101%;
    font-size: 16px;
    box-shadow: #00d8ff -1px 1px 8px;
    margin: 11px auto;
    &:hover, &.active {
        color: white;
        background-image: url('../../images/Active.png');
        background-repeat: no-repeat;
        background-size: 101% 101%;
        text-shadow: none;
        background-color: transparent;
        // border: 1px solid#00d8ff
        box-shadow: #00d8ff -1px 1px 8px; } }
.info {
    color: $secondary;
    font-family: AnitaSemiSquare;
    font-size: 16px;
    text-align: center;
    text-shadow: 2px 2px 25px $secondary; }
.spinner {
    margin-left: 8px; }
.error {
    color: #fc5757;
    font-family: AnitaSemiSquare;
    font-size: 16px;
    text-align: center;
    text-shadow: 2px 2px 25px #fc5757; }

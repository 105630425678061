@import "../../../styles/helpers";

.NewsUpdates {
    padding: 20px 30px;
    position: relative; }
.heading {
    color: $white;
    font-family: ChakraSemiBold;
    font-size: 32px;
    margin-bottom: 30px;
    margin-left: 110px;
    @include m {
        margin-bottom: 20px;
        font-size: 18px;
        margin-left: 0; } }
.container {
    border: 1px solid;
    border-color: #131313 #4F596C #4F596C;
    border-radius: 20px;
    // border-top: 1px solid #131313
    // border-bottom: 1px solid #4F596C
    // background-image: linear-gradient(#131313, #4F596C),linear-gradient(#131313, #4F596C)
    // background-size: 1px 100%
    // background-position: 0 0, 100% 0
    // background-repeat: no-repeat
    max-width: 485px;
    @include m {
        height: fit-content; }
    img {
        width: 100%;
        max-height: 300px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 20px;
        @include m {
            height: 250px; } } }
.content {
    padding: 20px;
    color: $white;
    font-family: 'Poppins'; }
.title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
    @include m {
        font-size: 14px; } }
.text {
    font-weight: 500;
    font-family: 'Poppins';
    font-style: normal;
    color: #9698A1;
    margin-bottom: 30px;
    border-bottom: 1px solid #4F596C;
    padding-bottom: 30px;
    @include m {
        font-size: 12px; } }
.prevArrow {
    background-image: url('../../../images/slickFrwdImg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 4px 0px;
    svg {
        color: #9698A1;
        margin-left: -3px; } }
.nextArrow {
    background-image: url('../../../images/slickBcwdImg..png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 4px 0px;
    svg {
        color: #9698A1;
        margin-left: -3px; } }
.loadMore {
    position: absolute;
    top: 12px;
    right: 263px;
    @include m {
        position: inherit;
        margin-bottom: 20px; } }

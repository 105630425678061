@import "../../styles/helpers";

.head {
    text-align: center;
    font-family: Chakra;
    font-size: 32px;
    color: $white;
    margin-bottom: 24px;
    font-weight: 600;
    @include m {
        font-size: 24px; } }

.field {
    font-family: Poppins;
    color: $white;
    font-size: 16px; }
.value {
    font-family: Poppins;
    color: $dark-yellow;
    font-size: 16px;
 }    // text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary;

.shade {
    width: 80%;
    display: flex;
    margin: 24px auto;
    @include t {
        margin: 0 auto 24px auto; } }
.centerValue {
    font-family: Poppins;
    color: $white;
    font-size: 16px;
    text-align: center;
    @include m {
        display: none; } }



.yield {
    color: $white;
    font-family: Poppins;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange; }

.loader {
    margin: 25px auto; }

.button {
    // display: flex
    // justify-content: center;
    @include m {
        display: block; } }
.values {
    display: flex; }

.checking {
    font-family: Poppins;
    color: $white;
    font-size: 12px; }

.error {
    color: red;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    margin-top: 10px; }

.lineBorder {
    margin: 30px auto;
    border-top: 2px solid $border-grey;
    width: 93%; }

.button1 {
    width: 35%;
    display: block;
    margin: 0 auto; }

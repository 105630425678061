@import "../../styles/helpers";

.notOwner {
    font-size: 18px;
    font-family: Poppins;
    font-weight: 500;
    margin: 0 30px; }
.text {
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    color: #9698A1;
    width: 80%;
    text-align: center;
    margin: 0 auto; }
.buttons {
    display: flex;
    margin: 0 auto;
    width: fit-content;
    margin-top: 30px;
    align-items: center; }
.takeBack {
    padding: 20px 0;
    text-align: center; }
.title {
    font-size: 24px;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: 20px; }


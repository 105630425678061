@import "../../styles/helpers";

.section {
    padding: 0px 0; }

.container {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block;
        padding-top: 0;
        margin-top: 0;
        padding-bottom: 0;
        margin-bottom: 0; } }

.about {
    border-radius: 10px;
    color: $white;
    border: 1px solid white;
    padding: 25px; }
.radar {
    background-color: #FFCD58;
    box-shadow: inset 60px 0 120px #87E0F7,inset -60px 0 120px #87E0F7;
    padding: 20px;
    border-radius: 25px;
    height: 410px;
    margin-left: 30px;
    width: 80%;
    canvas {
        height: 100% !important;
        width: 400px !important;
        margin: 0 auto; } }
.id {
    text-decoration: underline;
    font-family: 'Poppins';
    font-size: 17px;
    color: #FFCD58; }
.head {
    display: flex;
    align-items: baseline;
    margin: 5px 0 30px; }
.carousel .thumb.selected {
    border: 3px solid #ffffff !important; }
.size {
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
    font-family: 'Poppins';
    background: #292133;
    color: $purple;
    width: fit-content;
    height: fit-content;
    padding: 5px 35px;
    border-radius: 50px;
    @include m {
        font-size: 12px;
        padding: 5px 20px; } }
.top {
    display: flex; }
.gender {
    display: flex;
    margin-left: 25px;
    font-family: 'Poppins' !important;
    align-items: center;
    @include m {
        margin-left: 0; }
    img {
        height: 33px;
        margin-left: 20px;
        margin-top: 16px;
        @include m {
            height: 29px;
            margin-top: 18px; } }

    p {
        // padding-top: 25px
        color: #9698A1;
        font-size: 17px;
        font-family: 'Poppins' !important;
        margin-bottom: 0;
        margin-right: 8px;
        font-weight: 500;
        @include m {
            font-size: 13px; } }
    svg {
        font-size: 20px;
        color: #00A2FD; } }

.of {
    padding-top: 11px;
    color: $white;
    font-size: 17px;
    font-family: 'Poppins' !important;
    @include m {
        padding-left: 7px; } }

.likes {
    display: flex;
    margin-left: 25px;
    img {
        height: 40px; }
    p {
        padding-top: 25px;
        color: $white;
        font-family: 'Poppins' !important;
        font-size: 17px;
        @include m {
            font-size: 13px; } } }
.sales {
    margin-top: 7px; }
.salesEnd,.detailHead,.priceHead {
    color: #00A2FD;
    font-family: 'Poppins';
    // text-shadow: 2px 2px 25px #FF0000
    font-size: 18px;
    padding: 7px;
    font-weight: 500;
    @include m; }
.auctionEnd {
    text-align: center;
    font-size: 16px;
    color: $dark-yellow;
    font-family: 'Poppins';
    // text-shadow: 2px 2px 25px #FF0000
    font-size: 20px;
    padding: 7px;
    @include m {
        font-size: 16px; } }
.salesDate {
    color: $white;
    font-family: 'Poppins';
    padding-top: 11px;
    height: 45px;
    width: fit-content;
    font-size: 16px;
    @include m {
        font-size: 12px;
        padding-left: 7px; } }
.salesTime {
    height: 45px;
    width: fit-content;
    font-family: 'Poppins';
    font-size: 14px;
    color: $white;
    padding: 0 1px;
    margin-top: -10px;
    @include m {
        padding: 11px 7px;
        height: fit-content;
        font-size: 12px; } }
.detailCard,.attributesCard {
    font-family: 'Poppins';
    padding: 15px;
    height: fit-content;
    width: 100%;
    transition-delay: 2s; }
.detailField {
    color: $white;
    font-family: 'Poppins';
    font-size: 16px; }
.attributeField {
    color: $white;
    font-family: 'Poppins';
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 4px;
    font-weight: 500;
    @include t {
        font-size: 10px; } }
.attributeImg {
    width: 70%;
    display: flex;
    margin: 0 auto;
    margin-top: 4px; }
.attributesRow {
    margin: 10px 0; }
.attributeValue {
    color: $white;
    font-family: 'Poppins';
    margin-bottom: 0;
    margin-top: 2px;
    font-size: 16px;
    font-weight: 500;

    span {
        font-family: 'Poppins'; } }
.detailValue {
    font-family: 'Poppins';
    font-size: 16px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    a {
        color: $white; } }
.buttons {
    display: flex;
    margin: 20px 0; }
.ethereum {
    display: flex;
    img {
        height: 70px; }
    h4 {
        font-size: 20px;
        color: $white;
        margin: 0px; }
    p {
        padding-top: 27px;
        color: $white;
        margin-left: -25px;
        font-family: 'Poppins'; } }
.buyNow {
    background-image: url('../../images/primaryBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: fit-content;
    color: black;
    font-weight: 500;
    font-size: initial;
    font-family: 'Poppins';
    padding: 10px 80px;
    width: fit-content;
    margin: 0 auto;
    white-space: nowrap;
    &:hover {
        background-image: url('../../images/opnseaBtn.png');
        color: $dark-yellow; }
    @include m {
        padding: 10px 30px; } }

.makeOffer {
    background-image: url('../../images/primaryBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 261px;
    height: 54px;
    color: black;
    font-weight: 500;
    font-size: initial;
    font-family: 'Poppins';
    padding: 0 6%;
    margin: 0 auto;
    margin-top: 12%;
    &:hover {
        background-image: url('../../images/opnseaBtn.png');
        color: $dark-yellow; }
    @include m {
        padding: 10px 30px; } }
.opensea {
    background-image: url('../../images/opnseaBtn.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: fit-content;
    height: 54px;
    color: $dark-yellow;
    font-weight: 500;
    font-size: initial;
    font-family: 'Poppins';
    padding: 0 6%;
    margin: 0 auto;
    margin-top: 12%;
    padding-top: 15px;
    text-align: center;
    &:hover {
        background-image: url('../../images/primaryBg.png');
        color: black; }
    @include m {
        padding: 10px 30px; }
    // color: $white
    // span
    //     background: $dark-yellow;
    //     background: linear-gradient(to right, $dark-yellow 0%, $light-yellow 100%);
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    //     &:hover
    //         background: none
    //         color: black
 }    //         -webkit-text-fill-color: black;

.transact {
    font-family: 'Poppins';
    font-size: 22px;
    text-shadow: 2px 2px 25px $white;
    color: $white;
    letter-spacing: 2px; }
.trasaction {
    margin-top: 38px;
    text-align: end; }
.heading {
    font-family: 'Poppins';
    font-size: 22px;
    text-shadow: 2px 2px 25px #FF0000;
    color: #FFCD58; }
.list {
    display: flex; }
.data {
    display: flex;
    img {
        height: 30px; }
    p {
        color: $white;
        margin-left: 10px; } }
.dataright {
    display: flex;
    margin-left: 25px;
    img {
        height: 30px; }
    p {
        color: $white;
        margin-left: 10px; } }
.breed {
    margin-left: 73px; }
.division {
    display: flex;
    margin-bottom: 20px; }

.bg {
    position: relative;
    flex-grow: 1;
    width: 50%;
    @include x {
        margin-right: 64px; }
    @include d {
        margin-right: 32px; }
    @include t {
        margin-top: 20px;
        width: 100%; } }

.details {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 56%;
    padding-left: 50px;
    @include d {
        width: 550px;
        padding-left: 30px; }
    @include t {
        width: 100%;
        padding-left: 10px; } }

.preview {
    position: relative;
    border-radius: 16px;
    margin: 10px 0;
    width: 90%;
    @include t {
        width: 100%; }
    img,video,canvas {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        max-height: 500px; } }
.img {
    height: 100%;
    max-height: 500px;
    width: auto !important;
    @include m {
        width: -webkit-fill-available !important; } }

.categories {
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    z-index: 1;
    flex-wrap: wrap;
    margin: -8px 0 0 -8px; }

.category {
    margin: 8px 0 0 8px; }

.options {
    position: absolute;
    top: auto;
    left: 50%;
    bottom: 25px;
    transform: translateX(-50%); }

.title {
    color: $white;
    font-size: 32px;
    font-weight: 600;
    font-family: 'ChakraSemiBold';
    width: 70%;
    @include m {
        font-size: 18px;
        width: 100%; } }

.stats {
    color: $white;
    font-size: 23px;
    font-weight: 200;
    margin: 0px auto; }
.shade {
    margin: 0px auto;
    display: flex;
    width: 50%;
    margin-bottom: 25px; }
.cost {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px; }

.price {
    color: $white;
    @include dm-sans;
    font-size: 16px;
    line-height: 30px;
    @include m {
        font-size: 12px; }
    &:not(:last-child) {
        margin-right: 8px; } }

.counter {
    @include button-1;
    color: $neutrals4; }

.info {
    margin-bottom: 40px;
    @include body-2;
    color: $neutrals4;
    a {
        text-decoration: underline;
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; }
        &:hover {
            text-decoration: none; } } }

.nav {
    display: flex;
    margin-bottom: 32px;
    padding: 6px;
    border-radius: 20px;
    margin-top: 25px;
    box-shadow: inset 0 0 0 2px $neutrals6;
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3; } }

.link {
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    margin-bottom: 1px;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.users {
    margin-bottom: auto; }

.control {
    margin-top: 40px;
    @include m {
        margin-top: 32px; } }

.tags {
    margin-bottom: 20px; }

.tag {
    background-color: #e6e6e6;
    border-radius: 30px;
    padding: 3px 16px;
    color: #4f5566;
    border: none;
    margin-bottom: 8px;
    margin-left: 10px; }
.edition {
    font-weight: 600;
    font-size: 16px;
    color: #000;
    @include dark {
        color: $neutrals6; } }

.btns {
    display: flex;
    flex-direction: column;
    .button {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 8px; } } }

.loader {
    margin: 10% 50%; }

.search {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    margin-right: 24px;
    @include t {
        display: none; }
    @include m {
        display: block;
        width: 100%;
        margin: auto 0 0; } }

.input {
    width: 100%;
    height: 40px;
    padding: 0 42px 0 16px;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 8px;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
        color: $neutrals4; }
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 42px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $blue; } } }

.allComments {
    width: 100%;
    margin: 5px 0;
    padding: 5px; }

.commentItem {
    display: flex; }

.user {
    height: 45px;
    width: 45px;
    border-radius: 50%; }
.text {
    margin-top: 10px;
    margin-left: 20px;
    @include dark {
        color: $neutrals8; } }
.load {
    text-align: right;
    cursor: pointer;
    &:hover {
        color: #292ff9; } }
.collapse {
    background-color: $neutrals8 !important;
    margin-top: 20px;
    @include dark {
        background-color: $neutrals1 !important;
        color: $neutrals8 !important;
        border-color: #B1B5C3; } }
.field {
    font-weight: bold;
    @include dark {
        color: $neutrals8; } }

.mysterious {
    padding: 0 50px 25px;
    @include m {
        padding: 0 0px 16px; } }

.mystryhead {
    background-image: url('../../images/mystrery.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 107px;
    width: fit-content;
    color: $white;
    padding: 53px 33px 0px 90px;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins'; }
.first {
    text-align: center;
    margin-top: -32px; }
.combat {
    background-image: url('../../images/combat.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
    width: 150px;
    margin: 0 auto;
    text-align: center; }

.cognition {
    background-image: url('../../images/cognition.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
    width: 150px;
    text-align: center;
    @include d {
        margin: 0 auto; } }

.culture {
    background-image: url('../../images/culture.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
    width: 150px;
    margin-left: auto;
    text-align: center;
    @include d {
        margin: 0 auto; } }

.second,.fourth {
    display: flex;
    width: 100%;
    margin-top: -100px;
    @include d {
        margin-top: 0px;
        display: inline; } }
.third,.fifth {
    margin-top: -100px;
    @include d {
        margin-top: 0px; } }
.breed {
    background-image: url('../../images/breed.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
    width: 150px;
    margin: 0 auto;
    text-align: center; }

.corevalue {
    background-image: url('../../images/visual.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
    width: 150px;
    text-align: center; }

.distinct {
    background-image: url('../../images/distinct.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
    width: 150px;
    margin-left: auto;
    text-align: center; }

.visual {
    background-image: url('../../images/corevalue.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
    width: 150px;
    margin: 0 auto;
    text-align: center; }
.history {
    margin: 5px 10px 25px 10px;
    transition-timing-function: linear;
    overflow: auto; }
.historyhead {
    color: $white;
    font-size: 15px;
    font-weight: 200;
    text-align: center;
    margin-top: 25px; }
.slider {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include m {
        display: block;
        margin: 0; } }
.rowdata {
    background-color: #2F2F2F;
    margin-bottom: 5px;
    padding: 12px 25px; }
.buyer,.seller {
    color: $white;
    font-family: 'Poppins' !important;
    // letter-spacing: 2px
    margin-left: 25px;
    margin-bottom: 5px; }
.buyername,.sellername {
    font-family: 'Poppins' !important;
    color: $light-yellow;
    font-size: 18px; }
.buyerid,.sellerid {
    background-color: #ee92005c;
    color: $light-yellow;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 80%;
    padding: 0 10px;
    margin-top: 12px;
    a {
        color: $light-yellow; } }
.ethimg {
    float: left;
    height: 50px !important;
    @include t {
        height: 24px !important; } }
.priceimg {
    float: left;
    height: 40px !important;
    margin-left: -10px; }
.date {
    color: grey;
    text-transform: uppercase;
    font-size: 12px;
    @include t {
        font-size: 8px; } }
.mystryheading {
    color: $white;
    padding-top: 100px;
    font-family: 'Poppins';
    letter-spacing: 2px; }

.mystrydata {
    color: $white;
    font-family: 'Poppins';
    letter-spacing: 2px; }
.galleryimg {
    width: 90%;
    border: 0.5px solid#79a6b1;
    padding: 10px;
    margin-bottom: 10px;
    height: auto !important;
    &:hover {
        border: 3px solid#79a6b1;
        background: linear-gradient(to right, #87E0F7 4px, transparent 4px) 0 0,linear-gradient(to left, #87E0F7 4px, transparent 4px) 100% 100% , linear-gradient(to bottom, #87E0F7 4px, transparent 4px) 0 0, linear-gradient(to top, #87E0F7 4px, transparent 4px) 100% 100%;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        box-shadow: #79a6b1 -1px -1px 6px 0px; } }
.historyAccordian,.characteristsAccordian,.detailsAccordian {
    // box-shadow: 3px 3px 15px #2F2F2F inset
    background-image: url('../../images/accordianBorder.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 4px 20px;
    // border-radius: 15px
    transition: 1s all ease-in-out;
    margin: 10px 10px 20px; }
.accordianHead {
    color: $white;
    font-family: 'Poppins';
    // text-shadow: 2px 2px 25px #FF0000
    font-size: 18px;
    font-weight: 500;
    padding: 9px 15px; }
.accordian {
    display: flex; }
.accordianImgPlus {
    background-image: url('../../images/plus.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 38px;
    width: 38px;
    margin: 4px 15px 4px auto; }
.accordianImgMinus {
    background-image: url('../../images/minus.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 40px;
    width: 40px;
    margin: 4px 15px 4px auto; }
.description {
    color: $white;
    font-family: 'Poppins' !important;
    margin: 11px 0;
    font-size: 16px;
    width: 80%;
    font-weight: 500;
    @include x {
        width: 66%; }
    @include d {
        width: 55%; }
    @include t {
        width: 40%; }
    @include m {
        padding-left: 10px;
        font-size: 13px;
        width: 100%; } }
.offerPrice {
    font-family: 'Poppins' !important;
    font-size: 13px;
    color: $white;
    margin-left: 25px;
    margin-top: 10px; }
.listing {
    margin: 20px 0; }
.listingText {
    font-family: 'Poppins' !important;
    color: $white;
    margin: 20px; }

.purchase {
    background: #0A3248; }
.priceRow {
    h4 {
        color: $white;
        font-size: 18px;
        font-family: 'Poppins';
        margin-bottom: 0;
        margin-top: -4px; } }
.usd {
    color: #9698A1;
    font-size: 17px;
    font-family: 'Poppins';
    margin-left: 10px; }
.pricefield {
    color: $light-yellow;
    font-family: 'Poppins' !important;
    font-size: 16px; }
.pricevalue {
    color: $white;
    font-family: 'Poppins' !important;
    font-size: 16px; }
.ethimage {
    height: 25px;
    float: left;
    margin-right: 10px;
    margin-left: 10px; }
.arrow {
    margin-left: 10px;
    color: $dark-yellow; }
.leftPrice {
    color: $light-yellow;
    font-size: 20px;
    margin-left: 16px;
    font-family: 'Poppins';
    font-weight: 100;
    @include m {
        font-size: 16px;
        display: block;
        margin-bottom: 16px; } }
.tokenId {
    margin-top: 8px;
    a {
        color: $white;
        font-family: 'Poppins';
        margin-left: 20px;
        @include m {
            margin-left: 0; } } }
.historyCard {
    max-height: 450px;
    height: fit-content;
    overflow: auto; }
.historyCard::-webkit-scrollbar {
    display: none; }
.priceStatus {
    background-color: #c50808;
    color: white;
    border-radius: 20px;
    padding: 4px 22px;
    width: fit-content;
    margin: 0 auto;
    width: 110px;
    text-align: center; }
.activePrice {
    background-color: #1d9e04;
    color: white;
    border-radius: 20px;
    padding: 4px 22px;
    width: fit-content;
    margin: 0 auto;
    width: 110px;
    text-align: center; }
.AccordianBody {
    width: auto;
    overflow: auto;
    @include t {
        width: 450px; }
    @include m {
        width: auto; }
    @include a {
        width: 450px; } }
.currency {
    font-size: 16px; }
.openicon {
    color: $light-yellow;
    font-size: 32px;
    margin: 1rem; }
.salesbuttons {
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-top: 5%; }

.mintModal {
    max-width: 70%;
    @include m {
        max-width: 95%; } }

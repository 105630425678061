@import "../../styles/helpers";

.button {
    margin: 20px auto 0;
    width: 100%; }
.button1 {
    margin: 20px auto 0;
    width: 90%; }
.title {
    text-align: center;
    color: $white;
    // text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary
    font-family: 'ChakraSemiBold';
    font-size: 32px;
    margin-bottom: 10px;
    font-weight: 600;
    // text-transform: uppercase
    margin-top: -5px;
    @include m {
        font-size: 18px; } }
.orangeTitle {
    color: $white;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
    font-family: AnitaSemiSquare;
    font-size: 30px;
    margin-bottom: 10px;
    text-transform: uppercase;
    @include m {
        font-size: 20px; } }
.error {
    color: red;
    font-family: AnitaSemiSquare;
    font-weight: 600;
    font-size: 13px;
    margin-top: 5px;
    letter-spacing: .8px; }

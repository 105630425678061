@import "../../../styles/helpers";

.containner {
    padding: 0; }

.title,.KibblezTitle {
    color: white;
    font-size: 32px;
    font-family: 'ChakraSemiBold';
    margin-bottom: 0;
    @include m {
        font-size: 15px; } }
.KibblezTitle {
    margin-left: 10px;
    @include t {
        margin-left: 0; } }

.sub_title {
    color: white;
    margin-left: 32px;
    font-size: 16px;
    font-family: Poppins;
    @include m {
        font-size: 15px; } }

.heading {
    color: $white;
    font-family: Poppins;
    font-weight: lighter;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange; }

.wrap {
    position: relative;
    z-index: 2; }
.wrapper {
    flex: 0 0 calc(110% - 256px);
    width: calc(100% - 256px);
    padding-left: 64px;
    @include x {
        padding-left: 32px; }
    @include t {
        width: 100%;
        padding-left: 0; } }

.kibblezTable {
    overflow-x: auto;
    margin-right: 10px;
    @include t {
        margin-right: 0; } }
.bonezTable {
    overflow-x: auto;
    margin-left: 10px;
    @include t {
        margin-left: 0px; } }

.history_head {
    color: $white;
    font-family: Poppins;
    font-size: 24px; }
.history_table {
    // background-color: $model
    margin: 24px 0;
    // border: 1px solid #20668b
    width: auto;
    overflow: auto;
    padding-bottom: 5px;
    @include m {
        width: 1000px; } }
.rowdata {
    padding: 13px 20px;
    min-height: 50px;
    border-bottom: 1px solid $border-grey;
    &:hover {
        background: #161616; }
    &:nth-child(1) {
        border-top: 1px solid $border-grey;
        min-height: 50px;
        &:hover {
            background: none; } } }
.rowdatabg {
    background-image: url('../../../images/historybg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 50px; }

.rowdatabg:first-child {
    padding-left: 10px; }
.buyer,.seller {
    color: $white;
    font-family: Poppins !important;
    // letter-spacing: 2px
    margin-top: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    font-weight: 500; }
.pricefield {
    font-family: Poppins;
    font-size: 14px;
    color: #9698A1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    a {
        color: $white; } }
.history_shade {
    width: 100%;
    height: 12px;
    display: flex;
    margin: 0 auto; }
.status {
    font-family: Poppins;
    font-size: 14px;
    color: $white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-weight: 500;
    .complete {
        // text-shadow: 1px 1px 3px $green, 0 0 1em $green, 0 0 0.2em $green
        background: #21332F;
        padding: 5px 20px;
        color: #27AE60;
        border-radius: 50px; }
    .progress {
        // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange
        background: #332921;
        padding: 5px 20px;
        color: #F2994A;
        border-radius: 50px; } }
.hash {
    font-family: Poppins;
    font-size: 14px;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    a {
        color: $white; } }
.loader {
    margin: 70px auto; }
.empty {
    margin: 70px auto;
    color: $white;
    font-family: 'Poppins';
    text-align: center; }
.assetImg {
    height: 50px;
    display: flex;
    margin: 0 auto; }
.center {
    margin-top: 5px; }
.openicon {
    color: #30ccd6;
    font-size: 24px;
    margin-left: 5px; }

@import "../../../styles/helpers";

.stake {
    padding: 20px 30px; }
.heading {
    color: $white;
    font-family: ChakraSemiBold;
    font-size: 32px;
    margin-bottom: 10px;
    @include m {
        font-size: 18px; } }

.text {
    color: inherit;
    font-weight: 500;
    font-family: 'Poppins';
    font-style: normal;
    color: #9698A1;
    @include m {
        font-size: 12px; } }

.stakeImg {
    width: 100%;
    @include m {
        margin-bottom: 20px; } }
.container {
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    text-align: end;
    @include m {
        width: 100%; } }

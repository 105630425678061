@import "../../../styles/helpers";

.head {
    text-align: center;
    font-family: Chakra;
    font-weight: 600;
    font-size: 32px;
    color: $white;
    margin-bottom: 24px; }

.field {
    text-align: center;
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 16px; }
.value {
    color: $white;
    font-family: AnitaSemiSquare;
    font-size: 16px; }
.shade {
    width: 80%;
    display: flex;
    margin: 24px auto; }



.yield {
    color: $white;
    font-family: Chakra;
    font-weight: 600;
 }    // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;

.loader {
    margin: 25px auto; }

.button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @include m {
        display: block; } }
.values {
    display: flex; }
.renter, .owner {
    font-family: 'Poppins';
    color: $white;
    font-size: 16px; }
.owner {
    margin-left: auto;
    text-align: end; }
.checking {
    font-family: 'Poppins';
    color: $white;
    font-size: 12px; }
.steps {
    width: 257px;
    margin: 16px auto; }
.steps .ant-steps-item-icon {
    background: $secondary !important; }
.approve {
    margin-right: 24px;
    @include m {
        margin-right: 0; } }
.rent {
    margin-left: 24px;
    @include m {
        margin-left: 0; } }
.error {
    color: red;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    margin-top: 10px; }
.steps {
    display: flex;
    margin: 20px auto;
    width: 40%;
    @include m {
        width: 80%; } }
.firstStep {
    height: 25px;
    width: 32px;
    background-color: $dark-yellow;
    border: 1px solid black;
    border-radius: 50%;
    color: black;
    align-items: center;
    text-align: center;
    z-index: 1; }
.bar,.fullBar {
    width: 100%;
    background: linear-gradient(to right,$dark-yellow 50%,  #9698A1 50% );
    height: 12px;
    margin: auto -5px; }
.secondStep {
    height: 25px;
    width: 32px;
    background-color: $white;
    border: 1px solid black;
    border-radius: 15px;
    color: black;
    align-items: center;
    text-align: center; }
.fullBar {
    background: #04DFE5; }

.success {
    color: $white;
    font-family: Poppins;
    text-align: center;
    margin-top: 16px; }

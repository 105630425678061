@import "../../styles/helpers";

.container {
 }    // padding: 0

.head {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 326px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;
    @include t {
        height: 260px;
        padding-bottom: 64px; }
    @include m {
        height: 230px;
        padding-bottom: 56px; }
    .container {
        display: flex;
        justify-content: flex-end; }
    &.active {
        .btns {
            opacity: 0; }
        .file {
            opacity: 1;
            visibility: visible; } } }

.title {
    color: white;
    font-size: 32px;
    font-family: 'ChakraSemiBold';
    @include m {
        font-size: 18px; } }

.sub_title {
    color: white;
    margin-left: 32px;
    font-size: 16px;
    font-family: 'Poppins';
    @include m {
        font-size: 15px; } }

.heading {
    color: $dark-yellow;
    font-family: 'Poppins';
    font-weight: lighter;
 }    // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;

.empty {
    color: $white;
    text-align: center; }

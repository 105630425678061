@import "../../styles/helpers";
.rowdatabgLoader {
    // background-image: url('../../images/historybg.png')
    // background-repeat: no-repeat
    // background-size: 100% 100%
    background-color: #2F2F2F;
    margin-bottom: 5px;
    height: 45px; }
.rowLoader {
    background-image: linear-gradient(to right,$border-grey 0%, #2F2F2F 20%, $border-grey 40%,$border-grey 100%);
    animation: shine-lines 2.0s infinite ease-out;
    box-shadow: #2F2F2F 0px 30px 60px -12px inset, #2F2F2F 0px 18px 36px -18px inset;
    width: 100%;
    height: 100%; }
@keyframes shine-lines {
    0% {
        background-position: -350px 0px; }
    100% {
        background-position: 350px 0px; } }

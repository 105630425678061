@import "../../../styles/helpers";
.tournament {
    color: $white;
    font-family: Poppins;
    width: 80%;
    margin: 0 auto;
    @include s {
        width: 90%; } }
.LotteryContainer {
    position: relative;
    color: $white;
    font-family: Poppins;
    min-height: 750px;
    margin-bottom: 20px;
    @include d {
        min-height: 350px; }
    @include t {
        min-height: auto; } }

.bg {
    width: 100%;
    // margin-top: -90px
    filter: brightness(100%);
    @include m {
        margin-top: 0;
        display: block;
        width: 100%; } }
.fire {
    width: 100%;
    margin-top: -90px;
    @include m {
        margin-top: 0; } }
.content {
    position: absolute;
    top: 36%;
    width: 100%;
    text-align: center;
    @include m {
        top: 25%; } }
.welcome {
    font-size: 20px;
    @include m {
        font-size: 18px;
        margin-bottom: 10px; } }
.live {
    font-size: 16px;
    position: absolute;
    top: 50px;
    right: 30px;
    @include s {
        font-size: 12px;
        top: 10px;
        padding: 5px; } }
.liveanimation {
    width: 130px;
    @include m {
        width: 50px; } }
.blink {
    background-color: $green;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 8px;
    animation: blink-animation 0.5s steps(5, start) infinite;
    -webkit-animation: blink-animation 0.5s steps(5, start) infinite; }
@keyframes blink-animation {
    to {
        visibility: hidden; } }
@-webkit-keyframes blink-animation {
    to {}
    visibility: hidden; }
.message {
    font-size: 32px;
    // font-weight: 600
    font-family: ChakraSemiBold;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 16px;
    @include m {
        font-size: 18px;
        margin-bottom: 5px; } }
.label {
    font-size: 16px;
    font-weight: 400;
    color: $neutrals5;
    margin: 0 20px;
    margin-bottom: 24px;
    text-align: justify;
    @include m {
        font-size: 14px;
        margin: 5px 0 20px; } }

.address {
    height: 40px;
    width: 449px;
    border-radius: 56px;
    padding: 12px 24px 14px 24px;
    background: #FFFFFF;
    border-radius: 1px 1px 1px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    color: black;
    @include m {
        width: 240px;
        height: 30px;
        padding: 7px 15px 8px 15px;
        font-size: 12px; } }

.lottery {
    margin: 15px auto 0 auto;
    width: fit-content;
    @include m {
        margin: 20px auto 0 auto; } }

.access1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px 8px 16px;
    width: 240px;
    height: 30px;
    background: #00A2FD;
    border-radius: 53px;
    margin: 0 auto;
    margin-top: 13px; }
.access2 {
    width: fit-content;
    font-size: 16px;
    font-weight: 400;
    font-family: AnitaSemiSquare;
    letter-spacing: 2px;
    padding: 10px 18px;
    color: $white;
    transition: color .2s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 17px;
    color: white;
    background-image: url('../../../images/Active.png');
    background-repeat: no-repeat;
    background-size: 101% 100%;
    box-shadow: #00d8ff -1px 1px 8px;
    white-space: nowrap;
    background-color: transparent;
    @include m {
        background-color: #04dbe04f;
        font-size: 12px;
        padding: 5px 14px; } }
.button {
    margin-top: 17px;
    display: flex;
    justify-content: center; }
.access {
    width: 20%;
    margin-top: 10px;
    @include m {
        width: 50%; }
    @include s {
        width: 55%; } }
.playnow {
    cursor: pointer; }
.play {
    display: flex;
    justify-content: center;
    width: 500px;
    margin: -100px auto;
    @include m {
        width: 250px;
        margin: -60px auto; } }

@import "../../../styles/helpers";

.head {
    text-align: center;
    font-family: 'ChakraSemiBold';
    font-size: 32px;
    color: $white;
    margin-bottom: 24px;
    font-weight: 600;
    @include t {
        font-size: 24px; }
    @include m {
        font-size: 18px; } }

.wager {
    color: $white;
    font-family: 'Poppins';
    font-size: 20px;
    text-align: center;
    img {
        height: 32px;
        width: 32px;
        margin: 0 3px;
        @include t {
            height: 25px;
            width: 25px; }
        @include m {
            height: 15px;
            width: 15px; } }
    @include t {
        font-size: 16px; }
    @include m {
        font-size: 12px; } }
.value {
    color: $white;
    font-family: 'Poppins';
    font-size: 16px; }
.shade {
    width: 80%;
    display: flex;
    margin: 24px auto; }

.yield {
    color: $dark-yellow;
    font-family: 'ChakraSemiBold';
    // font-weight: lighter
 }    // text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange

.loader {
    margin: 25px auto; }

.button {
    margin: 10px auto;
    width: fit-content; }
.challengeText {
    color: $white;
    font-family: 'Poppins';
    font-weight: lighter;
    font-size: 24px;
    text-align: center;
    // text-shadow: 1px 1px 3px $secondary, 0 0 1em $secondary, 0 0 0.2em $secondary
    @include t {
        font-size: 20px; } }
.input {
    width: 200px;
    @include m {
        width: 80%;
        margin: 0 auto; } }
.previewImg {
    height: 150px;
    display: flex;
    margin: 0 auto;
    // border: 2px solid #20668b
    border-radius: 3px;
    padding: 5px;
    // border-image: linear-gradient(to right, $primary-blue, $secondary) 1;
    border: solid 1px $dark-yellow;
    border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; }
.imageDiv {
    width: 100%;
    align-content: center;
    margin-bottom: 10px; }
.error {
    color: red;
    font-size: 14px;
    text-align: center;
    font-family: Heebo; }
.coundown {
    font-size: 12px;
    font-family: 'Poppins';
    text-align: center; }


.panel>.ant-collapse-content
{   
    font-family: Heebo;
    background-color: #141416;
    color: white;   
    
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    font-family: Heebo;
    background-color: #2B5466;
    display: flex;
    font-size: 23px;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 12px 16px;
    color: #FFC300 !important;
    line-height: 1.5715;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
}
.ant-collapse-content > .ant-collapse-content-box {
    padding-left: 60px !important;
    padding-right: 60px !important;
    background-color: #2B5466;
    font-family: Heebo;
    font-size: 20px;
    color: #D8BFD8;
    
    justify-content: center;
    align-items: center;
    font-family: FF5733;
}
@import "../../styles/helpers";

.title {
    margin-bottom: 64px;

    color: $white;
    font-size: 32px;
    font-family: ChakraSemiBold;
    @include m {
        margin-bottom: 18px; } }
.store {
    color: $white;
    font-weight: 500;
    font-family: Poppins !important;
    // padding-left: 5%
    font-size: 24px;
    margin-bottom: 15px;
    margin-top: 50px;
    &:nth-child(1) {
        margin-top: 0; }
    @include m {
        font-size: 16px;
        font-family: Poppins !important; } }
.grop {
    font-family: Poppins !important;
    color: #9698A1;
    font-size: 16px !important;
    text-align: justify;
    @include m {
        font-size: 12px !important;
        font-family: Poppins !important; } }
.privacyBorder {
    padding: 50px;
    margin: 0 3%;
    border: 1px solid #2F2F2F;
    @include m {
        padding: 20px; } }

.card4 {
    background-size: 100% 100%;
    padding-top: 5px;
    padding-right: 45px;
    padding-bottom: 45px;
    margin: 50px 0;
    h2 {
        font-family: AnitaSemiSquare;
        color: #EE9200; } }


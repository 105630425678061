@import "../../../styles/helpers";

.item {
    // background: $neutrals2
    display: flex;
    flex-direction: row;
    @include m {
       flex-direction: Column; } }
.loader {
    margin: 100px auto; }
.head {
    color: #FFCD58;
    font-family: HeeboBold;
    text-shadow: 2px 2px 25px #FF0000; }
.register {
    margin-top : 32px;
    margin-bottom : 16px; }

.body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;
    border-radius: 15px;
    padding-bottom: 0; }

.counter {
    color: grey;
    display: flex;
    font-family: Heebo;
    margin-top: 5px;
 }    // padding: 0 10px;

.size {
    font-size: 14px;
    font-weight: 100;
    font-family: HeeboBold; }

.line {
    display: flex;
    align-items: flex-start;
    &:nth-child(2) {
        margin-top: auto; } }

.title {
    padding-top: 1px;
    color: $white;
    text-transform: uppercase;
    // +body-bold-2
    font-family: 'Poppins';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }


.image {
    width: 35%;
    border-radius: 16px;
    margin-right: 20px;
    svg {
        fill: $neutrals8; } }

.info {
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
    @include body-bold-2; }

.button {
    background-image: url('../../../images/login.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border:none {}
    width: 40%;
    font-family: polaris;
    &:hover {
        border: none;
        background-image: url('../../../images/cancel.png');
        background-repeat: no-repeat;
        background-size: 100% 100%; }
    &:not(:last-child) {
        background-image: url('../../../images/cancel.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 8px; } }

.field {
    margin: 25px 0;
    border-radius: 8px; }

.field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include m {
                margin-bottom: 20px; } } }

.note {
    margin-top: 4px;
    font-size: 14px;
    color: $neutrals4;
    margin-left: 25px; }

.alert {
  text-align: center;
  color: #f44336;
  margin-bottom: 16px;
  font-size: 14; }

.success {
  text-align: center;
  color: green;
  margin-bottom: 16px;
  font-size: 14; }

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s; }


.closebtn:hover {
  color: black; }

.avatar,
.icon {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    margin-right: 8px; }

.avatar {
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }
.text {
    font-weight: 500; }

.wallet {
    width: 280px;
    cursor: pointer;
    border: 2px solid $neutrals6;
    border-radius: 12px;
    height: 55px;
    @include dark {
        border-color: $neutrals3; }
    @include t {
        width: 260px; }
    @include m {
        width: 100%;
        margin-bottom: 12px; } }

.modal {
    padding: 0 42px; }

.metamask {
    height: 40px;
    width: 40px;
    margin: 5px 25px; }

.iconimg {
    float: right;
    margin-top: 14px; }

.delevery {
    display: flex;
    margin-right: 38px; }

.buttons {
    // display: flex
    background-image: url('../../../images/button_2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 40%;
    hight: 100%;
    margin-left: auto; }

.leftbtn {
    width: auto !important;
    margin-right: auto; }

.rightbtn {
    width: auto !important;
    margin-left: auto; }
.deleveryModal {
    max-width: 1000px !important;
 }    // background-color: blue


.creator {
    display: flex;
    padding: 16px; }

.category {
    font-size: 16px; }

.description {
    margin-left: 10%; }

.btns {
    text-align: center;
    width: 100%;
    margin: 25px 0; }
.price {
    margin-left: 25px; }
.stock {
    margin-left: 64px; }
.ownLand {
    display: flex;
    flex-direction: row;
    margin-top: 8px; }

.of {
    font-family: Heebo;
    position: absolute;
    right: 0;
    padding: 5px 10px 10px 10px;
    img {
        height: 50px; } }

.gender {
    height: 20px !important;
    display: flex;
    margin: 0 auto; }

.preview {
    position: relative;
    overflow: hidden;
    height: 326px;
    border: 1px solid #2F2F2F;
    padding: 10px;
    margin-bottom: 3px;
    @include m {
        height: auto; }
    & > img,video {
        display: flex;
        margin: 0 auto;
        height: 100%;
        width: 100%;
        @include m {
            height: auto;
            width: 100%; } } }

.preview:hover {
    // border: 3px solid #EE9200
    // border-radius: 15px
    // box-shadow: 0 0 10px #EE9200
    // animation: glow 1s ease-in-out infinite alternate
    // background-color: #757171
    & > img {
 }        // transform: scale(1.3)
    .title {
        color: #EE9200;
 } }        // transform: scale(1.3)

.card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #20668b;
    background-color: #082737;
    border-radius: 3px;
    margin-left: 8px;
    margin-top: 8px;
    max-width: 25%;
    @include m {
        max-width: 100%;
        margin-top: 8px; }
    &:hover {
        // box-shadow: #E9F3FE 0px 0px 8px 0px
        // transition: all 0.1s ease 0s
        // border-radius: 10px
        // background-image: url('../../images/cardbg.png')
        // background-repeat: no-repeat
        // background-size: 100% 100%
        .control {
            // visibility: visible
            opacity: 1; } } }

.asset {
    margin: 10px;
    a {}
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #20668b;
    background-color: $primary-blue;
    border-radius: 1px;
    @include m {
        max-width: 100%;
        margin-top: 8px; }
    @include a {
        margin-left: 0; }

    &:hover {
        // box-shadow: #E9F3FE 0px 0px 8px 0px
        // transition: all 0.1s ease 0s
        // border-radius: 10px
        // background-image: url('../../images/cardbg.png')
        // background-repeat: no-repeat
        // background-size: 100% 100%
        .control {
            // visibility: visible
            opacity: 1; } } }
.avatar {
    flex-shrink: 0;
    width: 150px;
    height: 150px;
    margin-right: 20px;
    @include m {
        width: 72px;
        height: 72px;
        margin-right: 16px; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px; } }
.value {
    color: #FFCD58;
    font-family: HeeboBold;
    text-shadow: 2px 2px 25px #FF0000;
    font-size: 20px;
    padding-top: 11px;
    padding-left: 10px;
    @include m {
        font-size: 16px;
        padding-left: 10px; } }
.noassets {
    color: #FFFFFF;
    font-family: HeeboBold;
    text-align: center;
    font-size: 20px;
    padding-top: 101px;
    padding-bottom: 101px;
    @include m {
        font-size: 16px;
        padding-top: 40px; } }

.stock {
    color: #EE9200;
    display: flex; }

.kibblez {
    display: flex;
    align-items: center;
    img {
        height: 22px;
        margin-top: -2px;
        margin-right: 2px; } }
.kibblezText {
        color: grey;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0px;
        white-space: nowrap; }
.buyNowDiv {
    margin: -1px 0 0 auto;
    padding-bottom: 0px; }

.buyNow {
    background-image: url('../../../images/buyBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: $white;
    padding: 0 10px;
    border-radius: 1px;
    height: 30px;
    font-size: 12px;
    font-family: AnitaSemiSquare;
    font-weight: bold;
    margin-left: auto;
    display: block;
    align-items: center;
    &:hover {
        background-image: url('../../../images/opnseaBtn.png');
        color: $dark-yellow; } }
.quantity {
    margin-left: auto; }
.cards, .cards-border {
    color: #9698a1;
    text-decoration: non;
    display: inline-block;
    font-size: 14px;
    font-family: "Poppins";
    position: relative;
    overflow: hidden;
    padding: 0 14px;
    width: 100%;
    cursor: pointer;
    margin-top: 3px; }
.cards {
    &::before {
        border-top: solid 1px #2F2F2F;
        top: -1px;
        right: 16px; }
    &::after {
        border-bottom: solid 1px #2F2F2F;
        bottom: -1px;
        left: 16px; } }

.cards {
    &::before,
    &::after, {
        content: "";
        width: 20px;
        height: calc(100% - 13px);
        display: block;
        position: absolute;
        z-index: 3;
        transform: rotate(45deg); } }
.cardsTxt {
    &::before,
    &::after {
        content: "";
        width: 20px;
        height: calc(103% - 13px);
        display: block;
        position: absolute;
        z-index: 3; } }
.cardsTxt {
    &::before {
        top: -1px;
        left: -14px;
        border-top: solid 1px #2F2F2F;
        border-left: solid 1px #2F2F2F; } }
.cardsTxt {
    &::after {
        bottom: -1px;
        right: -14px;
        border-bottom: solid 1px #2F2F2F;
        border-right: solid 1px #2F2F2F; } }
.cardsTxt {
    padding: 5px 10px;
    display: block;
    position: relative;
    border-top: solid 1px #2F2F2F;
    border-bottom: solid 1px #2F2F2F;
    z-index: 3; }
.Card {
    width: 100%;
    display: inline-block;
    cursor: pointer;
    max-width: 285px;
    margin-bottom: 10px;
    &:hover {
        box-shadow: 0px 29px 36px -34px rgba(250, 198, 55, 0.6);
        .cardsTxt {
            border-top: solid 1px $dark-yellow;
            border-bottom: solid 1px $dark-yellow;
            border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1;
            &::before {
                border-top: solid 1px $dark-yellow;
                border-left: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; }
            &::after {
                border-bottom: solid 1px $dark-yellow;
                border-right: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; } }
        .preview {
            border: 1px solid $dark-yellow;
            border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; }
        .cards {
            background: transparent;
            &::before {
                border-top: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; }
            &::after {
                border-bottom: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; } }
        .cards1 {
            background: transparent;
            &::before {
                border-top: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; }
            &::after {
                border-bottom: solid 1px $dark-yellow;
                border-image: linear-gradient(to right, $dark-yellow ,$light-yellow) 1; } } } }
.lineBorder {
    margin: 0 1px;
    border-top: 1px solid #2F2F2F;
    width: 93%; }
.cards1, .cards1-border {
    color: #9698a1;
    text-decoration: non;
    display: inline-block;
    font-size: 14px;
    font-family: "Poppins";
    position: relative;
    overflow: hidden;
    padding: 0 14px;
    width: 100%;
    cursor: pointer;
    margin-top: 3px; }
.cards1 {
    &::before {
        border-top: solid 1px #2F2F2F;
        top: -1px;
        right: 14px; }
    &::after {
        border-bottom: solid 1px #2F2F2F;
        bottom: -1px;
        left: 14px; } }

.cards1 {
    &::before,
    &::after, {
        content: "";
        width: 20px;
        height: calc(100% - 13px);
        display: block;
        position: absolute;
        z-index: 3;
        transform: rotate(45deg); } }

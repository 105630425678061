.thumbs-wrapper .thumb.selected{
    border: 2px solid white !important;
    border-image: linear-gradient(to right, #FDC22E ,#EDDC65) 1 !important;
}
.thumbs-wrapper .thumb{
    border: 2px solid #41404008;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background: #04DFE5 !important;
    margin-right: 0 !important;
    border: none
}
.ant-steps-small .ant-steps-item-title::after{
    top: 6px !important;
    height: 12px;
    margin-left: -19px;
    z-index: -1 !important;
    background: linear-gradient(to right,
    #04DFE5 50%,  #0D2236 50% );
    width: 212px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-left: -2px !important;
    padding-left: 0 !important;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
    color: #0D2236 !important;
}
@import "../../styles/helpers";
.claimbutton {
    display: flex;
    width: fit-content; }

.disableButton {
    display: flex;
    width: fit-content;
    cursor: no-drop;
    align-items: baseline; }

.label {
    // background: linear-gradient(90deg, #FDC22E 0%, #EDDC66 100%)
    background-image: url('../../images/transparentBtn.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    color: #9698A1;
    font-family: 'Poppins';
    width: auto;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    height: 48px;
    white-space: nowrap;
    min-width: 118px;
    justify-content: center;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    // clip-path: polygon(0% 24px, 24px 0%, 100% 0%, 100% 0, 100% 24px, 143px 100%, 0% 100%, 0 100%)
    &:hover {
        background-image: url('../../images/PrimaryBtnHover.png');
        color: #9698A1; }
    @include t {
        font-size: 12px; }
    @include m {
        min-width: 96px;
        height: 36px; }
    &:hover {
 } }        // background-image: url('../../images/secondaryBg.png')

.img {
    background-image: url('../../images/buttonicon.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 48px;
    width: 48px; }

.spinner {
    margin-left: 18px;
    margin-top: 3px;
    border: 1px solid $white;
    display: inline-block;
    border-right-color: transparent;
    font-size: 10px;
    height: 22px;
    padding: 0;
    width: 22px; }

.disableLabel {
    background-image: url('../../images/transparentBtn.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    color: #9698A1;
    font-family: 'Poppins';
    width: fit-content;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    height: 48px;
    white-space: nowrap;
    min-width: 118px;
    justify-content: center;
    cursor: no-drop;
    font-weight: 500;
    @include t {
        font-size: 12px; } }
.disableImg {
    background-image: url('../../images/secondarybaseicon.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 48px;
    width: 48px; }
.bottom1 {
    height: 5px;
    width: 30%;
    background: transparent;
    border: 2px solid black;
    border-bottom: none;
    border-left: none;
    position: absolute;
    bottom: 0;
    left: 0; }
.top1 {
    height: 5px;
    width: 30%;
    background: transparent;
    border: 2px solid black;
    border-top: none;
    position: absolute;
    top: 0;
    left: 50%;
    clip-path: polygon(0% 0%, 24% 0%, 100% 0%, 101% 0, 94% 56%, 88% 100%, 0% 100%, 0 100%);
    background-image: linear-gradient( transparent 89%, black 10%); }
.label1 {
    position: relative;
    width: 50%;
    margin: 0 auto;
    overflow: hidden;
    padding: 20px;
    text-align: center; }

.label1 {
    &::after {
        content: '';
        position: absolute;
        width: 1100%;
        height: 1100%;
        top: 20px;
        right: -500%;
        background: rgba(255,255,255,.8);
        transform-origin: 54% 0;
        transform: rotate(45deg);
        z-index: -1; } }

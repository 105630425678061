@import "../../../styles/helpers";

.rent {
    // padding: 20px 30px
    // min-height: 200px
    position: relative;
    margin: 20px 30px;
    @include m {
        // margin: 10px 0 20px
 } }        // min-height: 168px
.rentImg {
    background-image: url('../../../images/rent.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
 }    // padding: 20px
.rentMaskImg {
    background-image: url('../../../images/rentMask.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px 50px; }
.heading {
    color: $white;
    font-family: ChakraSemiBold;
    font-size: 32px;
    margin-bottom: 10px;
    @include m {
        font-size: 18px; } }
.text {
    font-weight: 500;
    font-family: 'Poppins';
    font-style: normal;
    color: $white;
    margin-bottom: 30px;
    @include m {
        margin-bottom: 20px;
        font-size: 12px; } }
.readMore {
    width: 12%; }
.webRent {
    position: absolute; }
.webMaskRent {
    width: 100%;
    height: 250px;
    border-radius: 30px;
    margin-bottom: 40px;
    object-fit: cover;
    @include m {
        margin-bottom: 20px; } }
.mobRent {
    position: absolute; }
.mobMaskRent {
    width: 100%; }
.content {
    position: absolute;
    top: 14%;
    left: 5%;
    width: 100%;
    @include m {
        width: 90%;
        margin: 0 20px;
        top: 20%;
        left: 0; } }

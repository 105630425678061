@import "../../../styles/helpers";

.user {
    padding: 32px 28px;
    border-radius: 16px;
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
    text-align: center;
    border: 1px solid #20668b;
    background-color: $primary-blue;
    border-radius: 3px;
    margin-bottom: 24px; }

.avatar {
    width: 160px;
    height: 160px;
    margin: 0 auto 24px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }

.name {
    color: $white;
    margin-bottom: 4px;
    @include body-bold-1; }

.code {
    display: inline-flex;
    align-items: center; }

.number {
    font-weight: 500;
    color: $white;
    font-family: AnitaSemiSquare; }

.copy {
    margin-left: 8px;
    svg {
        fill: $secondary;
        transition: fill .2s; } }

.info {

    @include caption-2;
    text-align: center;
    color: $neutrals4; }

.site {
    display: inline-flex;
    align-items: center;
    margin-bottom: 48px;
    @include button-2;
    color: $neutrals2;
    svg {
        margin-right: 8px;
        fill: $neutrals4; } }

.control {
    display: inline-block;
    position: relative; }
.edition {
    font-weight: 600;
    font-size: 16px;
    color: #000;
    @include dark {
        color: $neutrals6; } }
.box {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 8px);
    z-index: 2;
    width: 220px;
    transform: translateX(-50%);
    padding: 32px 16px;
    background: $neutrals8;
    border: 1px solid $neutrals6;
    box-shadow: 0px 32px 32px -8px rgba(31, 47, 70, 0.12);
    border-radius: 16px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    &.active {
        visibility: visible;
        opacity: 1; } }

.stage {
    margin-bottom: 24px;
    font-weight: 500; }

.share {
    display: flex;
    justify-content: center; }

.direction {
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: $neutrals6;
        transition: all .2s;
        svg {
            transition: fill .2s; }
        &:hover {
            background: $blue;
            svg {
                fill: $neutrals8; }
            svg {
                fill: $neutrals8; }
            &:hover {
                background: $blue;
                svg {
                    fill: $neutrals8; } } } }
    &:not(:last-child) {
        margin-right: 24px; } }

.btns {
    display: flex;
    justify-content: center;
    align-items: center; }

.button {
    span {
        &:nth-child(2) {
            display: none; } }
    &.active {
        background: $blue;
        box-shadow: inset 0 0 0 2px $blue;
        svg {
            fill: $neutrals8; }
        span {
            &:first-child {
                display: none; }
            &:nth-child(2) {
                display: inline; } } }
    &:not(:last-child) {
        margin-right: 8px; } }

.socials {
    display: flex;
    justify-content: center; }

.social {
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $blue; } }
    &:not(:last-child) {
        margin-right: 24px; } }

.note {
    padding-top: 10px;
    border-top: 1px solid $neutrals6;
    @include caption-2;
    color: $neutrals4; }
.wrap {
    margin-bottom: 7px;
    padding: 8px;
    border-radius: 16px; }

.line {
    display: flex;
    align-items: center; }

.preview {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%; } }

.price {
    @include body-bold-1;
    color: $white; }

.edit {
    background: $secondary;
    padding: 8px 16px;
    border-radius: 18px;
    // +button-2
    color: $white;
    font-weight: 400;
    transition: all .2s;
    font-family: AnitaSemiSquare;
    width: fit-content;
    text-align: center;
    margin: 10px auto;
    cursor: pointer; }
.editIcon {
    color: #41dcff;
    font-size: 24px;
    position: absolute;
    right: 30px;
    cursor: pointer; }
.codeModal {
    width: 35%;
    @include t {
        width: 50%; }
    @include m {
        width: 90%; } }
.codeModalBody {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    align-items: center; }
.copycode {
    margin-left: 8px;
    font-size: 25px;
    svg {
        fill: $white;
        transition: fill .2s;
        margin-top: 6px;
        &:hover,
        &:active {
            fill: $secondary; } } }
.content {
    display: flex;
    justify-content: center;
    margin: 16px 0; }
.note {
    color: $white;
    font-family: AnitaSemiSquare;
    text-align: center; }
.noteHead {
    color: $primary-orange;
    font-family: AnitaSemiSquare; }
.modalTitle {
    color: $white;
    font-family: AnitaSemiSquare;
    font-weight: lighter;
    font-size: 24px;
    text-shadow: 1px 1px 3px $primary-orange, 0 0 1em $primary-orange, 0 0 0.2em $primary-orange;
    @include m {
        font-size: 20px; } }
.copyIcon {
    color: $white;
    cursor: pointer;
    &:active {
        color: $secondary; } }
.loginButton {
    margin: 15px 0;
    display: flex;
    justify-content: center; }

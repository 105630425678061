#unity-canvas
{
    height: 100% !important; 
    width: 100% !important;
}
canvas{
    height: 100% !important; 
    width: 100% !important;
}


@import "../../../styles/helpers";

.item {
    display: inline-block;
    padding-bottom: 32px;
    border-bottom: 1px solid $neutrals6;
    @include m {
        position: relative;
        display: block;
        padding: 0 100px 0 0;
        border: none; }
    @include dark {
        border-color: $neutrals3; }
    &:not(:last-child) {
        margin-bottom: 48px;
        @include m {
            margin-bottom: 32px; } } }
.edition {
    font-weight: 600;
    font-size: 16px;
    color: #000;
    @include dark {
        color: $neutrals6; } }
.follower {
    padding-right: 24px;
    @include d {
        flex: 0 0 244px;
        width: 244px; }
    @include m {
        width: 100%;
        padding-right: 0; } }

.wrap {
    width: calc(100% - 326px);
    flex: 0 0 calc(100% - 326px);
    @include d {
        width: calc(100% - 244px);
        flex: 0 0 calc(100% - 244px); }
    @include m {
        display: none; } }

.avatar {
    flex-shrink: 0;
    width: 150px;
    height: 150px;
    margin-right: 20px;
    @include m {
        width: 72px;
        height: 72px;
        margin-right: 16px; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px; } }
.card {
    margin: 20px 0; }
.title {
    color: #EE9200;
    font-size: 20px;
    font-weight: 400;
    font-family: polaris;
    @include m {
        padding-left: 0px; } }

.counter {
    @include caption-2;
    color: $neutrals4; }

.button {
    height: 32px;
    margin-top: 12px;
    padding: 0 12px;
    @include m {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin-top: 0; } }

.gallery {
    display: flex;
    margin: 0 -4px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none; } }

.preview {
    flex-shrink: 0;
    width: 112px;
    margin: 0 4px;
    img {
        width: 100%;
        border-radius: 12px; } }

.loader {
    margin: 48px auto 10px; }
.status {
    color: #228B22;
    font-size: 20px;
    font-weight: 400;
    font-family: HeeboBold;
    @include m {
        font-size: 16px;
        padding-left: 110px; } }
.value {
    color: #FFCD58;
    font-family: HeeboBold;
    text-shadow: 2px 2px 25px #FF0000;
    font-size: 20px;
    padding-top: 0px;
    @include m {
        font-size: 16px; } }

.purchase {
    background: #0A3248; }
.status {
    color: #FF0000;
    font-family: HeeboBold;

    font-size: 20px;
    padding-top: 0px;
    @include m {
        font-size: 16px; } }
.status1 {
    color: #008000;
    font-family: HeeboBold;

    font-size: 20px;
    padding-top: 0px;
    @include m {
        font-size: 16px; } }
.of {
    padding-top: 0px;
    color: $white;
    font-size: 17px;
    font-family: Heebo !important;
    @include m {
        font-size: 16px; } }

.bidding {
    margin-left: auto;
    font-size: 12px;
    margin-bottom: 3px; }
.game {
    background-image: url('../../../images/button_1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 138%;
    height: 156%;
    color: #ffffff;
    font-size: 19px;
    font-family: HeeboBold;
    @include d {
        font-size: 13px;
        width: 118%;
        height: 116%;
        margin-bottom: 10px; }
    @include m {
        font-size: 13px;
        width: 40%;
        height: 116%;
        margin-bottom: 10px; } }
.noassets {
    color: #FFFFFF;
    font-family: HeeboBold;
    text-align: center;
    font-size: 20px;
    padding-top: 101px;
    padding-left: 101px;
    @include m {
        font-size: 16px;
        padding-left: 40px; } }

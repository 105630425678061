@import "../../styles/helpers";

.label {
    font-family: Heebo;
    color: $white;
    font-size: 16px;
    display: flex;
    img {
        height: 20px;
        margin-right: 5px; } }
.activelabel {
    font-family: Heebo;
    color: $secondary;
    font-size: 16px;
    img {
        height: 20px;
        margin-right: 5px; } }
.input,.iconIput {
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border-radius: 1px;
    border: 1px solid $secondary;
    background: $primary;
    font-family: HeeboBold;
    font-weight: bold;
    margin-top: -5px;
    @include caption-bold-1;
    color: $white;
    @include placeholder {
        color: $white;
        font-weight: bold;
        font-family: Heebo; }
    &:focus {
        border-color: $neutrals4;
        color: $primary;
        background: $secondary;
        border: 1px solid $secondary;
        font-weight: bold;
        font-family: HeeboBold; } }
.iconIput {
    border-left: none !important; }
.line {
    width: 100%;
    margin-top: -15px;
    height: 3px; }

.description {
    font-family: HeeboThin;
    font-size: 12px;
    margin-top: 3px;
    color: $white; }
.wrap {
    display: flex; }
.kibblez,.kibblezFocus {
    border-radius: 1px;
    border: 1px solid $secondary;
    background: $primary;
    height: 40px;
    padding: 6px;
    padding-right: 0px;
    border-right: none;
    margin-top: -5px; }
.kibblezFocus {
    background-color: $secondary !important; }
.iconIput::placeholder, .input::placeholder {
    color: #04dfe569; }
.disabled {
    cursor: not-allowed; }
